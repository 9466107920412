import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ServerData } from '../models/paging/server-data';
import { CurrentLocation } from '../models/current-location';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class CurrentLocationService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<CurrentLocation[]> {
    return new Promise((resolve, reject) => {
      this.http.get<CurrentLocation[]>('/api/currentlocation/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: number): Promise<CurrentLocation> {
      return new Promise((resolve, reject) => {
        this.http.get<CurrentLocation>('/api/currentlocation/get?id=' + id).subscribe(data => {
          resolve(data);
        }, error => {
          console.log(error);
          reject();
        });
      });
  }

  public getSingle(id): Promise<CurrentLocation> {
    return new Promise((resolve, reject) => {
      this.http.get<CurrentLocation>('/api/currentlocation/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(Currentlocation: CurrentLocation): Promise<CurrentLocation> {
    try {
      return new Promise((resolve, reject) => {
        if (Currentlocation.isEdit) {
          this.http.put<CurrentLocation>("/api/currentlocation/put", Currentlocation).subscribe(data => {
            resolve(data);
            this.snotifyService.success("Successfully updated Location");
          }, error => {
            console.log(error);
            reject();
          });
        }
        else {
          Currentlocation.id = UUID.UUID();

          console.log(Currentlocation);
          this.http.post<CurrentLocation>("/api/currentlocation/post", Currentlocation).subscribe(data => {
            resolve(data);
            this.snotifyService.success("Successfully added Location");
          }, error => {
            console.log(error);
            reject();
          });
        }
      });
     
    } catch (ex) {
      console.log(ex)
    }
  }



  public getResults(page: Page, name: string): Promise<PagedData<CurrentLocation>> {
    const pagedData = new PagedData<CurrentLocation>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&name=' + name;


      this.http.get<ServerData>('/api/currentlocation/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });




  }
}
