import { SubCategory } from "./sub-category";

export class Category {
  id = '';
  name = '';
  expired = false;
  expiredFormatted: string;

  is_edit = false;
  isSelected = false;

  subCategories: SubCategory[];

  init(id: string, name: string, expired: boolean) {
    this.id = id;
    this.name = name;
    this.expired = expired;

    this.expiredFormatted = expired ? 'Yes' : 'No';
  }
}
