import { Component, OnInit } from '@angular/core';
import { AssetService } from '../../../../services/asset.service';
import { ActivatedRoute } from '@angular/router';
import { Asset } from '../../../../models/asset';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.css']
})
export class AssetDetailsComponent implements OnInit {

  asset: Asset;
  rows = Array<any>();

  constructor(private assetService: AssetService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.assetService.get(params.id).then((asset) => {
        this.asset = asset;
        this.assetService.getHistory(params.id).then((history) => {
          this.rows = history;
          console.log(history);
        });
      });
    });
  }

}
