/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {

  transform(value: Array<any>, field: string): Array<any> {
    if (value) {
      const groupedObj = value.reduce((prev, cur) => {
        if (!prev[cur[field]]) {
          prev[cur[field]] = [cur]
        } else {
          prev[cur[field]].push(cur);
        }

        return prev;
      }, {});

      var group = Object.keys(groupedObj).map(key => ({
        key,
        value: groupedObj[key]
      }));

      console.log(group);
      
      return group;
    } else {
      return null;
    }
  }
}
