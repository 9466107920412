import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.css']
})
export class CarriersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
