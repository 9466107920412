import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { CategoryService } from '../../../services/category.service';
import { Category } from '../../../models/category';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  filter: any = {
    name: ''
  };

  page = new Page();
  rows = new Array<Category>();

  columns = [
    { name: 'Name', prop: 'name' },
    { name: 'Expired', prop: 'expiredFormatted' },
    { name: 'Option', prop: '' },
  ];

  constructor(public categoryService: CategoryService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/categories/manage'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.categoryService.getResults(this.page, this.filter.name).then((pagedData) => {
      console.log(pagedData);
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

  goToSubCategory(row) {
    this.router.navigate(['administration/subcategories'], { queryParams: { categoryId: row.id } });
  }

}
