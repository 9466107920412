import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TestTypeService } from 'src/app/services/test-type.service';
import { TestTypeQuestion } from 'src/app/models/test-type-question';
import { UUID } from 'angular2-uuid';
import { QuestionResponse } from 'src/app/models/question-response';
import { QuestionResponseService } from 'src/app/services/question-response.service';
import { SnotifyService } from 'ng-snotify';
import { Page } from 'src/app/models/paging/page';
import { TestTypeQuestionDetailComponent } from './test-type-question-detail/test-type-question-detail.component';
import { TestType } from '../../../../models/test-type';

@Component({
  selector: 'app-test-type-questions',
  templateUrl: './test-type-questions.component.html',
  styleUrls: ['./test-type-questions.component.css']
})
export class TestTypeQuestionsComponent implements OnInit {
  @ViewChild(TestTypeQuestionDetailComponent) child: TestTypeQuestionDetailComponent;

  questions: TestTypeQuestion[] = [];
  testTypeId: UUID;
  testType: TestType;

  page = new Page();
  rows = Array<TestTypeQuestion>();

  selectedQuestion: TestTypeQuestion;

  constructor(public testTypeService: TestTypeService, public questionResponseService: QuestionResponseService, private route: ActivatedRoute, private router: Router,
    private snotifyService: SnotifyService, private location: Location) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const testTypeId = params['testtypeid'];
      if (testTypeId != undefined) {
        this.testTypeId = testTypeId;

        this.testTypeService.get(testTypeId).then((testType) => {
          console.log(testType);
          this.testType = testType;
        });

        this.loadTable();
      }
    });
  }

  add(question) {
    question.position = this.questions.length + 1;

    this.testTypeService.saveQuestion(question).then((data) => {
      this.loadTable();
    });
  }

  edit(question) {
    console.log(question);
    console.log("EDITING");

    //this.testTypeService.saveQuestion(question).then((data) => {
    //  this.loadTable();
    //});
  }

  loadTable() {
    this.testTypeService.getQuestions(this.testTypeId).then((data) => {
      this.questions = data;

      var questionsToShow = this.questions;
      this.rows = [...questionsToShow];

      this.child.deselectResponses();

      this.child.questionText = "";
      this.child.questionType = '0';
      this.child.showResponses = true;
    });
  }

  addResponse($event) {
    let data = $event.split("|");
    let questionText = data[0];
    let negativeResponse = data[1] == "true";

    let questionResponse = new QuestionResponse();
    questionResponse.responseText = questionText;
    questionResponse.negativeResponse = negativeResponse;


    this.questionResponseService.checkIfExists(questionResponse).then((data) => {
      this.addQuestionResponse(questionResponse);
      this.child.responseText = "";
    }, error => {
      this.snotifyService.error(error.error);
      this.child.responseText = "";
    });
  }

  addQuestionResponse(questionResponse: QuestionResponse) {
    this.questionResponseService.save(questionResponse).then((data) => {
      this.child.bindQuestionResponses();
    });
  }

  editQuestion(row) {
    this.router.navigate(['administration/testtypes/questions/manage'], { queryParams: { id: row.id } });
  }

  moveUp(row) {
    var currentPos = row.position;
    if (currentPos > 1) {
      row.position -= 1;
      row.isEdit = true;
      this.testTypeService.saveQuestion(row).then((data) => {
        this.loadTable();
      });
    }
  }

  moveDown(row) {
    var currentPos = row.position;
    if (currentPos != this.rows.length) {
      row.position += 1;
      row.isEdit = true;
      this.testTypeService.saveQuestion(row).then((data) => {
        this.loadTable();
      });
    }
  }

  deleteQuestion(row) {
    row.expired = true;
    row.isEdit = true;
    console.log(row);

    for (var i = row.position; i < this.rows.length; i++) {
      this.rows[i].position -= 1;
      //console.log("question: " + i + "/n" + "position: " + this.rows[i].position);
    }
    //console.log(this.rows);

    row.position = -1;
    this.testTypeService.saveQuestions(this.testTypeId, this.rows).then((data) => {
      this.loadTable();
    });
  }

  goBack() {
    this.location.back();
  }
}
