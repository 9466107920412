import { ItemTestType } from "./item-test-type";
import { SubCategory } from "./sub-category";
import { LinkedItemType } from "./linked-item-type";
import { Asset } from "./asset";

export class ItemType {
  id: string;
  name: '';
  categoryId: string;
  subCategoryId: string = "";
  subCategory: SubCategory;
  expired = false;
  isEdit = false;
  additionalName: string;
  warehouseLocation: string;
  standardCost: number;
  numbered: boolean;
  assetRegister: boolean;
  ce: boolean;
  internalHire: boolean;
  internalHireRate: number;
  testTypes: ItemTestType[] = [];
  quantity: number = 0;
  notes: string = "";
  mandatoryRote: boolean;

  linkedItemTypes: LinkedItemType[];

  selectedAsset: Asset;
  selectedQuantity: number = 0;

  replacedByItemType: ItemType;
}
