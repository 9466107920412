import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { Picklist } from '../../../models/picklist';
import { Project } from '../../../models/project';
import { PicklistService } from '../../../services/picklist.service';
import { ProjectService } from '../../../services/project.service';
import { CurrentLocation } from 'src/app/models/current-location';
import { CurrentLocationService } from 'src/app/services/current-location.service';

@Component({
  selector: 'app-book-in-non-numbered',
  templateUrl: './book-in-non-numbered.component.html',
  styleUrls: ['./book-in-non-numbered.component.css']
})
export class BookInNonNumberedComponent implements OnInit {

  filter: any = {
    projectId: undefined,
    picklist: undefined,
  };

  projects: Project[];
  nonNumberedAssets = [];
  filteredAssets = [];
  locations = [];
  groupedAssetsLoc = [];

  picklists: any[];

  currentLocations: CurrentLocation[];
  selectedLocationId: string;

  selectedPicklist;

  constructor(private projectService: ProjectService, private picklistService: PicklistService, private snotifyService: SnotifyService, private currentLocationService: CurrentLocationService) {
    this.projectService.getAll().then((data) => {
      this.projects = data;
      this.currentLocationService.getAll().then((locations) => {
        this.currentLocations = locations;

      });
    });
  }

  ngOnInit() {
  }

  loadPicklists() {
    this.selectedPicklist = null;
    this.picklistService.getOutstandingNonNumberedGroups(this.filter.projectId).then((assets) => {
      console.log(assets);
      assets = assets.sort((a, b) => a.location.name.localeCompare(b.location.name));
      for (let asset of assets) {
        asset.assets = asset.assets.sort((a, b) => a.itemType.name.localeCompare(b.itemType.name));
      }
      this.nonNumberedAssets = assets;
    });
  }

  loadItems() {
    this.selectedPicklist = this.filter.picklist;
    console.log(this.selectedPicklist);
  }

  save() {

    if (this.selectedLocationId == '' || this.selectedLocationId == 'undefined' || this.selectedLocationId == null) {
      this.snotifyService.error("Please select the location these items are being booked in to.");
      return;
    }

    var dtos = [];

    for (let asset of this.nonNumberedAssets) {

      for (let assets of asset.assets) {
        console.log(assets);

        if (assets.selectedQuantity > assets.inboundQuantity) {
          this.snotifyService.error("Error - selected quantity exceeds allocated quantity");
          return;
        }
        console.log(assets);

        dtos.push({
          picklistPickedAssetId: assets.id,
          nonNumberedAssetId: assets.nonNumberedAssetId,
          itemTypeId: assets.itemTypeId,
          replacedByItemTypeId: assets.replacedByItemTypeId,
          projectId: assets.picklist.projectId,
          requestedQuantity: assets.selectedQuantity,
          currentLocationId: assets.currentLocationId,
          chosenLocationId: this.selectedLocationId
        });
      }

      console.log(dtos);

      this.picklistService.savePicklistPickedAssets(dtos).then((success) => {
        if (success) {
          this.snotifyService.success("Successfully updated non numbered items");
          this.projectService.getAll().then((data) => {
            this.projects = data;
          });
          this.filter.projectId = this.filter.projectId;
          this.filter.picklist = undefined;
          this.selectedPicklist = null;
          this.loadPicklists();
        } else {
          this.snotifyService.error("Error updating non numbered items");
        }
      })
    }
  }

}
