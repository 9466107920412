import { Component, OnInit } from '@angular/core';
import { TabletUser } from '../../../../models/tablet-user';
import { TabletUserService } from '../../../../services/tablet-user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TestTypeService } from '../../../../services/test-type.service';
import { TestType } from '../../../../models/test-type';
import { RegimeGroupService } from '../../../../services/regime-group.service';
import { RegimeGroup } from '../../../../models/regione-group';

@Component({
  selector: 'app-tablet-user-regimes',
  templateUrl: './tablet-user-regimes.component.html',
  styleUrls: ['./tablet-user-regimes.component.css']
})
export class TabletUserRegimesComponent implements OnInit {
  user: TabletUser;

  groups: RegimeGroup[];
  selectedRegimeGroupId: string;
  rows = [];

  constructor(public tabletUserService: TabletUserService, public router: Router, private route: ActivatedRoute, private regimeGroupService: RegimeGroupService) {
    this.user = new TabletUser();

    this.regimeGroupService.getAll().then((groups) => {
      this.groups = groups;
    })
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.tabletUserService.get(id).then((data) => {
        this.user = data;
        this.rows = this.user.regimes.filter(a => !a.expired);
      });
    });
  }

  addTestType() {
    if (this.selectedRegimeGroupId == null)
      return;

    if (this.user.regimes.filter(a => a.testTypeId == this.selectedRegimeGroupId && !a.expired).length > 0) {
      alert("Already added");
      return;
    }

    var regimeGroup = this.groups.find(a => a.id == this.selectedRegimeGroupId);
    var newReg = {
      tabletUserId: this.user.id,
      regimeGroupId: regimeGroup.id
    }

    this.tabletUserService.addRegime(newReg).then((regime) => {
      this.loadTable();
    });
  }

  removeTestType(regime) {
    this.tabletUserService.deleteRegime(regime.id).then(() => {
      this.loadTable();
    });
  }

  loadTable() {
    this.tabletUserService.get(this.user.id).then((data) => {
      this.user = data;
      this.rows = this.user.regimes.filter(a => !a.expired);
    });
  }
}
