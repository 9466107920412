export class TestType {
  id: string = '';
  type = '';
  interval: number;
  expired = false;
  isEdit = false;
  isLolerOrPuwer: boolean;
  reportTypeId: 0;
  regimeGroupId: string;
}
