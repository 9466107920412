import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../../services/project.service';
import { Project } from '../../../../models/project';
import { Asset } from '../../../../models/asset';
import { AssetService } from '../../../../services/asset.service';
import { CurrentLocation } from 'src/app/models/current-location';
import { Category } from 'src/app/models/category';
import { SubCategory } from 'src/app/models/sub-category';
import { ItemType } from 'src/app/models/item-type';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { CategoryService } from 'src/app/services/category.service';
import { ItemTypesService } from 'src/app/services/item-types.service';
import { SubCategoryService } from 'src/app/services/sub-category.service';
import { AssetSelectorService } from 'src/app/services/asset-selector.service';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.css']
})
export class SelectProjectComponent implements OnInit {

  filter = {
    projectId: null,
    canNumber: "",
    categoryId: null,
    subCategoryId: null,
    itemTypeId: null
  }

  assets: Asset[] = [];

  projectsList: Project[];

  locationsList: CurrentLocation[];
  categoriesList: Category[];

  allSubCategoriesList: SubCategory[];
  subCategoriesList: SubCategory[];

  allItemTypesList: ItemType[];
  itemTypesList: ItemType[];

  testTypeGroups: any = [];

  constructor(private projectService: ProjectService, private assetSelectorService: AssetSelectorService, private assetService: AssetService, currentLocationService: CurrentLocationService, private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypeService: ItemTypesService) {
    this.projectsList = [];
    var projects = [];
    this.projectService.getAll().then((data) => {
      for (let project of data) {
        if (project.completed)
          continue;

        projects.push(project);
      }

      this.projectsList = projects;
    })

    categoryService.getAll().then((data) => {
      this.categoriesList = data;
    });

    subCategoryService.getAll().then((data) => {
      this.allSubCategoriesList = data;
      this.subCategoriesList = data;
    });

    itemTypeService.getNumbered().then((data) => {
      this.allItemTypesList = data;
      this.itemTypesList = data;
    });

    this.assetSelectorService.selectedAssets.subscribe((assets) => {
      if (assets == null || assets.length == 0)
        return;

      this.processAsset(assets[0]);
    });
   
  }

  ngOnInit() {

    this.clearAssets();

  }

  onCategoryChange(value) {
    this.filter.subCategoryId = null;
    if (value == '' || value == null) { value = null; }

    this.subCategoriesList = this.allSubCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.filter.itemTypeId = null;
    if (value == '' || value == null) { value = null; }

    this.itemTypesList = this.allItemTypesList.filter(c => {
      return value == null || c.subCategoryId == value;
    });
  }

  //search() {
  //  //console.log(this.filter);
  //  if (this.filter.projectId == '' || this.filter.projectId == null) {
  //    this.assets = [];
  //    return;
  //  }

  //  this.assets = [];
  //  this.assetService.search(this.filter.canNumber, null, this.filter.categoryId, this.filter.subCategoryId, this.filter.itemTypeId, this.filter.projectId).then((data) => {
  //    console.log(data);
  //    this.assets = data;

  //    console.log(this.assets);
  //  });
  //}

  //loadAssets(projectId) {
  //  if (projectId == '' || projectId == null) {
  //    this.assets = [];
  //    return;
  //  }

  //  this.assetService.search(this.filter.canNumber, null, this.filter.categoryId, this.filter.subCategoryId, this.filter.itemTypeId, projectId).then((data) => {
  //    console.log(data);
  //    this.assets = data;

  //    console.log(this.assets);
  //  });

  //}

  //Changed 11/03/22 Paul wants this exactly like Stock but with a Projects # search rather than a Location




  search() {
    //console.log(this.filter);
    if (this.filter.projectId == '' || this.filter.projectId == null) {
      this.assets = [];
      return;
    }

    this.assetService.searchNonExact(this.filter.canNumber, null, this.filter.categoryId, this.filter.subCategoryId, this.filter.itemTypeId, this.filter.projectId).then((data) => {
      console.log(data);

      if (data.length == 0)
        return;

      if (data.length > 1) {
        this.assetSelectorService.showAssets(data);
        return;
      }

      this.processAsset(data[0]);
    });

  }


  processAsset(asset: Asset) {
    if (this.assets.filter(a => a.canNumber == asset.canNumber && a.itemTypeId == asset.itemTypeId).length > 0)
      return;

    this.itemTypeService.getTests(asset.itemTypeId).then((tests: any) => {
      for (let test of tests) {

        var testType = this.testTypeGroups.find(a => a.id == test.id);
        if (testType == null) {
          test.count = 1;

          this.testTypeGroups.push(test);
        }
        else {

          testType.count++;
        }

        if (asset.testTypes == null)
          asset.testTypes = [];

        if (asset.testTypes.filter(a => a.id == test.id).length == 0)
          asset.testTypes.push(test);
      }

      console.log(asset.testTypes);
    });

    this.assets.unshift(asset);

  }

  clearAssets() {
    this.assets = [];
  }

}
