import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project';
import { Page } from 'src/app/models/paging/page';
import { DivisionService } from 'src/app/services/division.service';
import { ProjectService } from 'src/app/services/project.service';
import { Division } from 'src/app/models/division';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { ReportableService } from '../../../services/reportable.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  filter: any = {
    jobNo: '',
    jobName: '',
    divisionId: undefined,
    status: 0
  };

  divisions: Division[];

  page = new Page();
  rows = Array<Project>();

  constructor(private divisionService: DivisionService, private projectService: ProjectService, public router: Router, private reportableService: ReportableService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.divisionService.getAll().then((data) => {
      this.divisions = data;
    });
  }

  edit(project) {
    this.router.navigate(['administration/projects/manage'], { queryParams: { id: project.id } });
  }

  amendPicklist(project) {
    this.router.navigate(['administration/projects/picklist'], { queryParams: { projectid: project.id } });
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  downloadReport(id, type) {
    this.reportableService.getProjectRegister(id).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.projectService.getResults(this.page, this.filter.jobNo, this.filter.jobName, this.filter.divisionId, this.filter.status).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

}
