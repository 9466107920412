import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { Picklist } from '../models/picklist';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ServerData } from '../models/paging/server-data';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PicklistService {

  showSelectedOnly: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  selectedPicklist: Picklist = new Picklist();

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public nullifySelection() {
    this.showSelectedOnly = new BehaviorSubject<any>(null);;
  }


  public get(id: string): Promise<Picklist> {
    return new Promise((resolve, reject) => {
      this.http.get<Picklist>('/api/picklist/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAllFromProject(projectId: string): Promise<Picklist[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Picklist[]>('/api/picklist/getallfromproject?projectId=' + projectId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getOutstandingNonNumberedAssets(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<Picklist[]>('/api/picklist/getOutstandingNonNumberedPicklists?projectId=' + projectId).subscribe(data => {
        console.log(data);

        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getOutstandingNonNumberedGroups(projectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<Picklist[]>('/api/picklist/getOutstandingNonNumberedGroups?projectId=' + projectId).subscribe(data => {
        console.log(data);

        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public savePicklistPickedAssets(dto): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/postPicklistPickedAssets', dto).subscribe(data => {
        resolve(true);
      }, error => {
        resolve(true);
      });
    })
  }

  public reinstatePicklist(dto): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put<Picklist>('/api/picklist/reinstatePicklist', dto).subscribe(data => {
        resolve(true);
      }, error => {
        resolve(true);
      });
    })
  }

  public getForExistingTable(page: Page): Promise<PagedData<Picklist>> {
    const pagedData = new PagedData<Picklist>();
    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Id'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      this.http.get<ServerData>('/api/picklist/getforexistingtable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

        console.log(pagedData);

      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAssetsToAdd(page: Page, typeId: string, categoryId: string, descriptionId: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'DescriptionId'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&typeId=' + typeId;
      params += '&categoryId=' + categoryId;
      params += '&descriptionId=' + descriptionId;

      this.http.get<ServerData>('/api/picklist/getitemsforadd?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getResults(page: Page, projectId: string, jobNo: string, jobName: string, status: number): Promise<PagedData<Picklist>> {
    const pagedData = new PagedData<Picklist>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'CreatedAt'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&projectId=' + projectId;
      params += '&jobNo=' + jobNo;
      params += '&jobName=' + jobName;
      params += '&status=' + status;

      this.http.get<ServerData>('/api/picklist/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getOutgoingPicklists(page: Page, projectId: string, divisionId: string, status: number): Promise<PagedData<Picklist>> {
    const pagedData = new PagedData<Picklist>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'CreatedAt'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&projectId=' + projectId;
      params += '&divisionId=' + divisionId;
      params += '&status=' + status;

      this.http.get<ServerData>('/api/picklist/getoutgoingpicklists?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public GetprojectReportsTable(page: Page, projectId: string, jobNo: string, jobName: string, status: number): Promise<PagedData<Picklist>> {
    const pagedData = new PagedData<Picklist>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'CreatedAt'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&projectId=' + projectId;
      params += '&jobNo=' + jobNo;
      params += '&jobName=' + jobName;
      params += '&status=' + status;

      this.http.get<ServerData>('/api/picklist/GetprojectReportsTable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public save(picklist: Picklist): Promise<Picklist> {
    console.log("SAVING");
    return new Promise((resolve, reject) => {
      picklist.collectionDate = moment(picklist.collectionDate).format('YYYY/MM/DD');
      //picklist.jobDurationDate = moment(picklist.jobDurationDate).format('YYYY/MM/DD');

      if (picklist.jobDurationInterval == '1') {
        picklist.jobDurationInterval = 'day(s)';
      } else if (picklist.jobDurationInterval == '2') {
        picklist.jobDurationInterval = 'week(s)';
      } else if (picklist.jobDurationInterval == '3') {
        picklist.jobDurationInterval = 'month(s)';
      }

      console.log(picklist);

      if (picklist.id == undefined || picklist.id == '') {
        picklist.id = UUID.UUID();

        this.http.post<Picklist>('/api/picklist/post', picklist).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added picklist');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        this.http.put<Picklist>('/api/picklist/put', picklist).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated picklist');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public approve(picklist: Picklist): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put<Picklist>('/api/picklist/putapproval', picklist).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully approved picklist');
      }, error => {
        console.log(error);
        reject(false);
      });
    });
  }

  public cancel(picklist: Picklist): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put<Picklist>('/api/picklist/putcancel', picklist).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully cancelled picklist');
      }, error => {
        console.log(error);
        reject(false);
      });
    });
  }

  public savePicklistAssets(picklist): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/save', picklist).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully saved picklist');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public savePicklistAssetsV2(picklist): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/savev2', picklist).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully saved picklist');
      }, error => {
        reject(error);
      });
    });
  }

  public completePicklistAssets(picklist): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/complete', picklist).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully completed picklist');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public completePicklistAssetsV2(picklist): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/completeV2', picklist).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully completed picklist');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public postBookIn(dto): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/postbookin', dto).subscribe(data => {
        resolve(true);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public postBookInQuarantine(dto): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<Picklist>('/api/picklist/postbookinquarantine', dto).subscribe(data => {
        resolve(true);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
