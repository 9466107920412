import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { Page } from 'src/app/models/paging/page';
import { Category } from '../../../models/category';
import { AssetService } from 'src/app/services/asset.service';
import { SubCategory } from '../../../models/sub-category';
import { ItemType } from '../../../models/item-type';
import { SubCategoryService } from '../../../services/sub-category.service';
import { ItemTypesService } from '../../../services/item-types.service';
import { FilterService } from '../../../services/filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { Project } from '../../../models/project';
import { CurrentLocation } from '../../../models/current-location';
import { CurrentLocationService } from '../../../services/current-location.service';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {
  filter: any = {
    categoryId: undefined,
    subCategoryId: undefined,
    itemTypeId: undefined,
    canNumber: "",
    projectId: null,
    locationId: null,
    stockStatus: "-1",
  };

  default_filters = JSON.parse(JSON.stringify(this.filter));

  categories: Category[];
  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];
  itemTypes: ItemType[];
  itemTypesList: ItemType[];

  projects: Project[];
  currentLocations: CurrentLocation[];

  page = new Page();
  rows = Array<any>();

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService,
    private itemTypeService: ItemTypesService, private assetService: AssetService,
    public router: Router, public route: ActivatedRoute, private filterService: FilterService,
    private authService: AuthService, private currentLocationService: CurrentLocationService, private projectService: ProjectService) {

    this.page.pageNumber = 0;
    this.page.size = 10;


  }

  ngOnInit() {

    this.categoryService.getAll().then((data) => {
      this.categories = data;

      this.subCategoryService.getAll().then((data) => {
        this.subCategoriesList = data;

        this.itemTypeService.getAll().then((data) => {
          this.itemTypesList = data;

          this.route.queryParams.subscribe(params => {

            console.log("Query Params");
            console.log(params);
         
            //Check if we've come from itemTypes
            const itemTypeId = params['itemTypeId'];
            if (itemTypeId != undefined) {

              console.log("Getting parameter filters");

              const categoryId = params['categoryid'];
              if (categoryId != undefined) {
                this.filter.categoryId = categoryId;
                this.subCategories = this.subCategoriesList.filter(c => {
                  return c.categoryId == categoryId
                });
              }

              const subCategoryId = params['subcategoryid'];
              if (subCategoryId != undefined) {
                this.filter.subCategoryId = subCategoryId;
                this.itemTypes = this.itemTypesList.filter(i => {
                  return i.subCategoryId == subCategoryId
                });
              }

              const itemTypeId = params['itemTypeId'];
              if (itemTypeId != undefined) {
                this.filter.itemTypeId = itemTypeId;
              }

              const assetId = params['id'];
              if (assetId != null) {
                this.assetService.get(assetId).then((asset) => {
                  this.filter.canNumber = asset.canNumber;
                });
              }

              this.loadTable(this.page);
            }

            //Otherwise check if we came from a duplicate
            else if (params['id'] != null) {
              
                const assetId = params['id'];
                if (assetId != null) {
                  this.assetService.get(assetId).then((asset) => {
                    this.filter.canNumber = asset.canNumber;
                    this.loadTable(this.page);
                  });
                }
            }

            //Otherwise use defaults/cached
            else {

              console.log("Getting cached filters");
              var filters = this.filterService.getFilters(this.authService.currentUser.value.id, this.router.url);
              if (filters != null) {
                this.filter = filters;

                if (this.filter.categoryId != undefined) {
                  this.subCategories = this.subCategoriesList.filter(c => {
                    return c.categoryId == this.filter.categoryId
                  });
                }

                if (this.filter.subCategoryId != undefined) {
                  this.itemTypes = this.itemTypesList.filter(i => {
                    return i.subCategoryId == this.filter.subCategoryId
                  });
                }

                if (this.filter.assetId != null) {
                  this.assetService.get(this.filter.assetId).then((asset) => {
                    this.filter.canNumber = asset.canNumber;
                  });
                }
              }

              this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
              this.loadTable(this.page);
            }
          });
        })
      });
    });

    this.currentLocationService.getAll().then((data) => {
      //console.log(data);
      this.currentLocations = data;
    });

    this.projectService.getAll().then((data) => {
      this.projects = data;
    });
  }

  edit(row) {
    this.router.navigate(['administration/assets/manage'], { queryParams: { id: row.id, numberedAsset: row.numberedAsset } });
  }

  duplicate(row) {
    this.router.navigate(['administration/assets/manage'], { queryParams: { id: row.id, numberedAsset: row.numberedAsset, duplicate: true } });
  }

  onCategoryChange(value) {
    this.filter.subCategoryId = undefined;
    if (value == '' || value == null) { value = null; }

    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.filter.itemTypeId = undefined;
    if (value == '' || value == null) { value = null; }

    this.itemTypes = this.itemTypesList.filter(c => {
      return (value == null || c.subCategoryId == value) && c.numbered;
    });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    if (this.filter.projectId != null) {
      this.filter.stockStatus = -1;
    }

    this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
    this.assetService.getResults(this.page, this.filter.categoryId, this.filter.subCategoryId, this.filter.itemTypeId, this.filter.canNumber,
      this.filter.projectId, this.filter.locationId, this.filter.stockStatus).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

}
