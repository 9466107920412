import { Component, OnInit } from '@angular/core';
import { ItemTypeSelectorService } from '../../services/item-type-selector.service';
import { ItemType } from '../../models/item-type';

@Component({
  selector: 'app-item-type-selector',
  templateUrl: './item-type-selector.component.html',
  styleUrls: ['./item-type-selector.component.css']
})
export class ItemTypeSelectorComponent implements OnInit {

  showModal: boolean = false;

  allItemTypes: ItemType[];
  itemTypes: ItemType[];

  searchQuery: string = "";

  

  constructor(private itemTypeSelectorService: ItemTypeSelectorService) {
    this.itemTypeSelectorService.getItemTypes().subscribe((itemTypes) => {
      this.allItemTypes = itemTypes;
      this.itemTypes = itemTypes;
      if (itemTypes != null && itemTypes.length > 0)
        this.showModal = true;
      else
        this.showModal = false;
    });
  }

  ngOnInit() {
  }

  search(query: string) {
    if (query == "") {
      this.itemTypes = this.allItemTypes;
      return;
    }

    this.itemTypes = this.allItemTypes.filter(a => a.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  selectItemType(itemType: ItemType) {
    this.itemTypeSelectorService.selectItemType(itemType);
    this.showModal = false;
  }

  cancel() {
    this.itemTypeSelectorService.cancel();
    this.showModal = false;
  }
}
