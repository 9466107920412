import { Component, OnInit } from '@angular/core';
import { Project } from '../../../models/project';
import { Page } from '../../../models/paging/page';
import { Picklist } from '../../../models/picklist';
import { ProjectService } from '../../../services/project.service';
import { PicklistService } from '../../../services/picklist.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Division } from '../../../models/division';
import { DivisionService } from '../../../services/division.service';

@Component({
  selector: 'app-active-picklist-report',
  templateUrl: './active-picklist-report.component.html',
  styleUrls: ['./active-picklist-report.component.css']
})
export class ActivePicklistReportComponent implements OnInit {


  filter: any = {
    projectId: undefined,
    divisionId: undefined,
    status: 0
  };
  projects: Project[];
  divisions: Division[];

  page = new Page();
  rows = Array<Project>();

  constructor(private divisionService: DivisionService, private projectService: ProjectService, private reportableService: ReportableService, private picklistService: PicklistService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.projectService.getAll().then((data) => {
      this.projects = data;
    });

    this.divisionService.getAll().then((data) => {
      this.divisions = data;
    });
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  downloadReport(Id, type) {

    /*TYPE:
     1 = MO
     2 = MOM
     3 = TE
     4 = PUWER
     5 = PORJECT
     6 = PORJECT
     7 = PROJECT REGISTER
     */

    if (type == 1) {
      this.reportableService.getPicklistManifestOutbound(Id).then((data) => {
        importedSaveAs(data, 'Manifest Outbound.pdf');
      });
    }

    else if (type == 2) {
      this.reportableService.getPicklistManifestOutboundMissing(Id).then((data) => {
        importedSaveAs(data, 'Manifest Outbound Missing.pdf');
      });
    }

    else if (type == 3) {
      this.reportableService.getPicklistTECertsWrapper(Id).then((data) => {
        importedSaveAs(data, 'Numbered Items TE Certs.pdf');
      });
    }

    else if (type == 4) {
      this.reportableService.getPicklistPUWERCertswrapper(Id).then((data) => {
        importedSaveAs(data, 'Numbered Items PUWER Certs.pdf');
      });
    }

    else if (type == 5) {
      this.reportableService.getPicklistPATCertsWrapper(Id).then((data) => {
        importedSaveAs(data, 'Numbered Items PAT Certs.pdf');
      });
    }
    else if (type == 6) {
      this.reportableService.getJobExpiry(Id).then((data) => {
        importedSaveAs(data, 'Job Expiry Report.pdf');
      });
    }
    else if (type == 7) {
      this.reportableService.getProjectRegister(Id).then((data) => {
        importedSaveAs(data, 'Project Register.pdf');
      });
    }

  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  //loadTable(page: Page) {
  //  this.picklistService.GetprojectReportsTable(this.page, this.filter.projectId, this.filter.jobNo, this.filter.jobName, this.filter.status).then((pagedData) => {
  //    this.page = pagedData.page;
  //    this.rows = pagedData.data;
  //    console.log(this.rows);
  //  });
  //}
  refreshTable() {
    this.page.pageNumber = 0;
    this.loadTable(this.page);
  }
  loadTable(page: Page) {
    console.log(this.filter);
    this.projectService.getProjectResults(this.page, this.filter.projectId, this.filter.divisionId, this.filter.status).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

  select(picklist: Picklist) {
    console.log(picklist);
  }

}
