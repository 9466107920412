import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentLocation } from 'src/app/models/current-location';

@Component({
  selector: 'app-location-manage-detail',
  templateUrl: './location-manage-detail.component.html',
  styleUrls: ['./location-manage-detail.component.css']
})
export class CurrentLocationManageDetailComponent implements OnInit {
  @Output() added: EventEmitter<CurrentLocation> = new EventEmitter<CurrentLocation>();
  @Input() hideExpired: boolean = false;
  Location: CurrentLocation;

  Name = "";
  Quarantine: boolean;
  Expired: boolean;

  constructor(public locationService: CurrentLocationService, public router: Router, private route: ActivatedRoute) {
    this.Location = new CurrentLocation();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.locationService.get(id).then((data) => {
          this.Location = data;
          this.Name = data.name;
          this.Location.isEdit = true;
        })
      } else {
        this.hideExpired = true;
      }
    });
  }

  submit() {
    this.Location.name = this.Name;
    this.Location.expired = this.Expired;
    this.Location.isQuarantine = this.Quarantine;

    //console.log(this.Location);
    this.added.emit(this.Location);
  }

}
