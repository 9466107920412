import { Component, OnInit } from '@angular/core';
import { TestType } from 'src/app/models/test-type';
import { Router, ActivatedRoute } from '@angular/router';
import { TestTypeService } from 'src/app/services/test-type.service';
import { RegimeGroupService } from '../../../../services/regime-group.service';
import { RegimeGroup } from '../../../../models/regione-group';

@Component({
  selector: 'app-test-type-manage',
  templateUrl: './test-type-manage.component.html',
  styleUrls: ['./test-type-manage.component.css']
})
export class TestTypeManageComponent implements OnInit {
  testType: TestType;

  regimeGroups: RegimeGroup[];

  constructor(public router: Router, private testTypeService: TestTypeService, private route: ActivatedRoute, private regimeGroupService: RegimeGroupService) {
    this.testType = new TestType();
  }

  async ngOnInit() {
    this.regimeGroups = (await this.regimeGroupService.getAll()).sort((a, b) => a.name.localeCompare(b.name));

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.testTypeService.get(id).then((data) => {
          this.testType = data;
          this.testType.isEdit = true;
        });
      }
    });
  }

  submit() {
    this.testTypeService.save(this.testType).then((data) => {
      this.router.navigate(['administration/testtypes']);
    });
  }

}
