import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginModel: LoginModel = new LoginModel();
  loginMessage = '';

  showForgottenPassword = false;
  showReset = false;
  code;
  userId;

  constructor(private http: HttpClient, private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.code = params['code'];
      if (this.userId != undefined) {
        this.showReset = true;
      }
    });
  }

  login() {
    this.http.post('/api/account/login', this.loginModel).subscribe(data => {
      this.auth.setUser(data);
      this.router.navigate(['/dashboard']);
    }, error => {
      this.loginMessage = 'Invalid email or password';
    });
  }

  toggleForgottenPassword() {
    this.showForgottenPassword = !this.showForgottenPassword;
  }

  resetPassword() {
    this.http.post('/api/account/resetpassword', this.loginModel).subscribe(data => {
      console.log('RESET');
    }, error => {
      this.loginMessage = 'Error resetting';
    });
  }

  confirmResetPassword() {
    if (this.loginModel.Password == '' || this.loginModel.Password == null) {
      this.loginMessage = 'Please enter password';
      return;
    }

    if (this.loginModel.Password != this.loginModel.Password2) {
      this.loginMessage = 'Passwords do not match';
      return;
    }

    let model = {
      Id: this.userId,
      Password: this.loginModel.Password,
      Code: this.code
    };

    this.http.post('/api/account/changepassword', model).subscribe(data => {
      this.showLogin();
    }, error => {
      this.loginMessage = 'Error resetting';
    });
  }

  showLogin() {
    this.showForgottenPassword = false;
    this.showReset = false;
  }
}

export class LoginModel {
  Id: string;
  Email: string;
  Password: string;
  Password2: string;
  Code: string;
}
