import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Page } from '../../../models/paging/page';
import { PagedData } from '../../../models/paging/paged-data';

import { SubCategory } from '../../../models/sub-category';
import { Category } from '../../../models/category';
import { CategoryService } from 'src/app/services/category.service';
import { SubCategoryService } from '../../../services/sub-category.service';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.css']
})
export class SubCategoriesComponent implements OnInit {
  filter: any = {
    categoryId: undefined,
    name: ''
  };

  page = new Page();
  rows = Array<SubCategory>();

  categories: Category[];

  constructor(public subCategoryService: SubCategoryService, public categoryService: CategoryService, public router: Router, public route: ActivatedRoute) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    categoryService.getAll().then((data) => {
      this.categories = data;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const categoryId = params['categoryId'];
      if (categoryId != undefined) {
        this.filter.categoryId = categoryId;
      }
    });

    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/subcategories/manage'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.subCategoryService.getResults(this.page, this.filter.categoryId, this.filter.name).then((pagedData) => {
      console.log(pagedData.data);
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

  goToItemType(row) {
    console.log(row);
    this.router.navigate(['administration/itemtypes'], { queryParams: { categoryId: row.categoryId, subCategoryId: row.id } });
  }

}
