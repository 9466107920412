import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../../services/category.service';
import { SnotifyService } from 'ng-snotify';
import { Category } from '../../../../models/category';
import { Accessory } from '../../../../models/accessory';
import { UUID } from 'angular2-uuid';
import { SubCategory } from '../../../../models/sub-category';
import { SubCategoryService } from '../../../../services/sub-category.service';

@Component({
  selector: 'app-sub-category-manage',
  templateUrl: './sub-category-manage.component.html',
  styleUrls: ['./sub-category-manage.component.css']
})
export class SubCategoryManageComponent implements OnInit {
  subCategory: SubCategory;
  categories: Category[];

  constructor(private subCategoryService: SubCategoryService, private categoryService: CategoryService, private snotifyService: SnotifyService, public router: Router, public route: ActivatedRoute) {
    this.subCategory = new SubCategory();
    this.subCategory.isEdit = false;

    this.categoryService.getAll().then((data) => {
      this.categories = data;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.subCategoryService.get(id).then((data) => {
          this.subCategory = data;
          this.subCategory.isEdit = true;
        });
      }
    });
  }

  submit() {
    console.log(this.subCategory);

    //for (let accessory of this.category.accessories) {
    //  if (accessory.name == '' || accessory.name == undefined) {
    //    this.snotifyService.warning('Please enter a name for the accessory');
    //    return;
    //  }
    //}

    this.subCategoryService.save(this.subCategory).then((data) => {
      this.router.navigate(['administration/subcategories']);
    });
  }

  //addAccessory(accessory) {
  //  const a = new Accessory();
  //  a.id = UUID.UUID();
  //  a.name = accessory.name;

  //  if (this.category.isEdit) {
  //    a.categoryId = this.category.id;
  //  }

  //  this.category.accessories.push(a);
  //}

  //removeAccessory(accessory) {
  //  this.category.accessories.splice(this.category.accessories.indexOf(accessory, 1));
  //}

}
