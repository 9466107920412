import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProjectService } from '../../../../services/project.service';
import { Picklist } from '../../../../models/picklist';
import { ActivatedRoute, Router } from '@angular/router';
import { PicklistService } from '../../../../services/picklist.service';
import { ItemType } from '../../../../models/item-type';
import _ from "lodash";
import { NonNumberedAsset } from '../../../../models/non-numbered-asset';
import { AssetService } from '../../../../services/asset.service';
import { Asset } from '../../../../models/asset';
import { PickListAssetViewModel } from '../../../../models/picklist-asset-view-model';
import { AuthService } from '../../../../services/auth.service';
import { AssetSelectorService } from '../../../../services/asset-selector.service';
import * as moment from 'moment';
import { ItemTypeSelectorService } from '../../../../services/item-type-selector.service';
import { SubCategory } from '../../../../models/sub-category';
import { detectChanges } from '@angular/core/src/render3';
import { PickListAsset } from '../../../../models/picklist-asset';
import { UUID } from 'angular2-uuid';
import { Category } from '../../../../models/category';
import { SubCategoryService } from '../../../../services/sub-category.service';
import { NonNumberedAssetService } from '../../../../services/non-numbered-asset.service';
import { SnotifyService } from 'ng-snotify';
import { User } from '../../../../models/user';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { CurrentLocation } from 'src/app/models/current-location';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-select-picklist-report',
  templateUrl: './select-picklist-report.component.html',
  styleUrls: ['./select-picklist-report.component.css']
})
export class SelectPicklistReportComponent implements OnInit {
  @ViewChild("searchInput") searchInput: ElementRef;
  picklist: Picklist;

  allItemTypes = [];
  itemTypes = [];
  categories = [];
  allCategories = [];
  menuCategories = [];
  chosenLocationId = undefined;
  currentLocations: CurrentLocation[];

  locationLocked = false;
  searchCategories: "";
  selectedItemType: ItemType;

  //assetList: Asset[] = [];
  assets: Asset[] = [];
  
  searchQuery: string = "";

  constructor(private picklistService: PicklistService, public route: ActivatedRoute, private assetService: AssetService, private router: Router, private authService: AuthService,
    private assetSelectorService: AssetSelectorService, private itemTypeSelectorService: ItemTypeSelectorService, private nonNumberedAssetService: NonNumberedAssetService,
    private snotifyService: SnotifyService, private currentLocationService: CurrentLocationService, private subCategoryService: SubCategoryService, private categoryService: CategoryService) {
    //this.assetService.getAll().then((result) => {
    //  this.assetList = result;
    //});

    this.currentLocationService.getAll().then((data) => {
      //console.log(data);
      this.currentLocations = data;
    });
    //this.subscribeToAssets();

  }

  subscribeToAssets() {
    this.assetSelectorService.selectedAssets.subscribe((assets) => {
      if (assets == null || assets.length == 0)
        return;

      this.processAsset(assets[0]);
    });
  }

  async ngOnInit() {

;    this.route.paramMap.subscribe(params => {
      const id = params.get("id");
      this.picklistService.get(id).then((picklist) => {
        this.picklist = picklist;


        //add categories for NAs
        for (let asset of picklist.picklistAssets) {
          this.addCategoriesData(asset, true);
        }

        this.updateReplacedItemTypes(picklist.picklistAssets, true);

        //add categories for NNAs
        for (let asset of picklist.picklistNonNumberedAssets) {
          this.addCategoriesData(asset, false);
        }

        //If this picklist has a non-numbered location, we need to set that and lock it.
        if (picklist.nonNumberedLocation != null){
          this.locationLocked = true;
          this.chosenLocationId = picklist.nonNumberedLocation;
        }

        this.updateReplacedItemTypes(picklist.picklistNonNumberedAssets, false);

        var allCat = new Category();
        allCat.init("-1", "ALL ITEMS", false);
        //allCat.subCategories = subCategories;
        for (let category of this.categories) {
          category.subCategories = category.subCategories.sort((a, b) => a.name.localeCompare(b.name));
          for (let subCategory of category.subCategories) {
            subCategory.itemTypes = subCategory.itemTypes.sort((a, b) => a.name.localeCompare(b.name));
          }
        }

        this.menuCategories = this.categories.sort((a, b) => a.name.localeCompare(b.name));
        this.menuCategories.unshift(allCat);
        this.allCategories = this.categories.sort((a, b) => a.name.localeCompare(b.name));
        
        

        for (let asset of picklist.picklistPickedAssets) {
          if (asset.assetId == null) {
            // Update the item type
            for (let category of this.categories) {
              if (category.subCategories != undefined) {
                for (let subCategory of category.subCategories) {
                  for (let itemType of subCategory.itemTypes) {
                    if (itemType.id == asset.itemTypeId) {
                      itemType.selectedQuantity = asset.nonNumberedQuantity;
                    }
                  }
                }
              }
            }
          }

          else {
            for (let category of this.categories) {
              if (category.subCategories != undefined) {
                for (let subCategory of category.subCategories) {
                  for (let itemType of subCategory.itemTypes) {
                    if (itemType.id == asset.itemTypeId && itemType.selectedAsset == null) {
                      itemType.selectedAsset = asset.asset;
                      break;
                    }
                  }
                }
              }
            }
          }
        }

        this.updateCounts();
      });
    });
  }

  setCAN() {
    this.searchQuery = "CAN";
    this.searchInput.nativeElement.focus();
  }

  overBook(item) {
    if (item.selectedQuantity >= item.quantity) {
      item.selectedQuantity = item.quantity;
    }
  }

  updateReplacedItemTypes(assets, numbered) {
    if (numbered) {
      for (let category of this.categories) {
        for (let subCategory of category.subCategories) {
          for (let itemType of subCategory.itemTypes) {
            var asset = assets.find(a => a.id == itemType.picklistAssetId);
            itemType.replacedByItemType = asset.replacedByItemType;
          }
        }
      }
    }

    else {
      for (let category of this.categories) {
        for (let subCategory of category.subCategories) {
          for (let itemType of subCategory.itemTypes) {
            var asset = assets.find(a => a.itemTypeId == itemType.id);
            if (asset != null) {
              itemType.replacedByItemType = asset.replacedByItemType;
            }
          }
        }
      }
    }
  }

  addCategoriesData(asset, isNumbered) {

    //If somehow the subCategory hasn't been set, we need to set it so the category won't fail
    if (asset.itemType.subCategory == null) {
      this.subCategoryService.get(asset.itemType.subCategoryId).then(sub => {
        this.categoryService.get(sub.categoryId).then(cat => {
          asset.itemType.subCategory = sub;
          asset.itemType.subCategory.category = cat;

          this.loadCategories(asset, isNumbered);
        });
      });

      //asset.itemType.subCategory = this.subCategoryService.get(asset.itemType.subCategoryId);
      //asset.itemType.subCategory.category = this.categoryService.get(asset.itemType.subCategory.categoryId);

    }
    else {
      this.loadCategories(asset, isNumbered)
    }  
  }

  loadCategories(asset, isNumbered) {
    //console.log("----------------------------------------------------");

    //console.log("Loading Category")
    //console.log("Asset:")
    //console.log(asset.itemType.name);

    let category = this.categories.find(a => a.id == asset.itemType.subCategory.category.id);

    //console.log("Searched for category, found:" + category);

    if (category == null || category == "undefined") {
      //console.log("Therefore adding category");

      category = asset.itemType.subCategory.category;
      category.expanded = false;
      this.categories.push(category);
    }

    if (category.subCategories == null) {
      //console.log("Pre-defining the subcategories array");
      category.subCategories = [];
    }

    let subCategory = null;

    var exists = false;
    for (var i = 0; i < category.subCategories.length; i++) {
      var subcat = category.subCategories[i];

      if (subcat.id == asset.itemType.subCategory.id && isNumbered == subcat.isNumbered) {
        //console.log("Found a sub-category for this item");
        exists = true;
        subCategory = subcat
        //console.log(subcat);
      }
    }

    if (!exists) {
      //console.log("Add the sub category in");

      subCategory = asset.itemType.subCategory;

      //console.log("Setting category 'numbered' value to: " + isNumbered);
      subCategory.isNumbered = isNumbered;

      if (isNumbered) {
        //console.log("This is a numbered asset, so we'll count the NAs and add the sub category as being numbered");

        var assetcount = 0;

        var assets = this.picklist.picklistAssets.filter(a => a.itemType.subCategory.id == asset.itemType.subCategory.id);
        if (assets.length > 0) {

          var nonReplacedAssets = assets.filter(a => a.replacedByItemTypeId == null);
          if (nonReplacedAssets.length > 0) {
            assetcount += nonReplacedAssets.map(value => value.quantity).reduce((sum, x) => sum + x);
          }

          subCategory.type = "Numbered";
          subCategory.assetCount = assetcount;
          subCategory.currentAssetCount = 0;
          //console.log("Pushing sub category");
          category.subCategories.push(subCategory);
          //console.log(subCategory);

        }

      }
      else {
        //console.log("This is a non-numbered asset, so we'll count the NNAs and add the sub category as being non-numbered");

        var nnacount = 0;

        var nonNumberedAssets = this.picklist.picklistNonNumberedAssets.filter(a => a.itemType.subCategoryId == asset.itemType.subCategory.id);
        if (nonNumberedAssets.length > 0) {
          nnacount += nonNumberedAssets.map(value => value.quantity).reduce((sum, x) => sum + x);

          subCategory.type = "Non-Numbered";
          subCategory.isNumbered = isNumbered;
          subCategory.assetCount = nnacount;
          subCategory.currentAssetCount = 0;

          //console.log("Pushing sub category");
          category.subCategories.push(subCategory)
          //console.log(subCategory);
        }
      }

      if (isNumbered)
        subCategory.itemTypes = this.addItemTypes(subCategory, true);

      else
        subCategory.itemTypes = this.addItemTypes(subCategory, false);
    }

    else {

    }

    //console.log("----------------------------------------------------");
  }

  addItemTypes(subCategory, isNumbered) {

    //console.log("Now Adding The Item Type Itself");

    if (isNumbered) {
      //console.log("This is a numbered Item Type");
      var newAssets = [];
      //console.log("Searching for Numbered Assets for which the sub category id of that item type matches the sub category:");
      //console.log(subCategory);
      var assets = this.picklist.picklistAssets.filter(a => a.itemType.subCategoryId == subCategory.id);
      for (let asset of assets) {
        //console.log("Added asset");
        //console.log(asset);
        for (let i = 0; i < asset.quantity; i++) {
          var itemType = {
            numbered: true,

            picklistAssetId: asset.id,
            id: asset.itemType.id,
            subCategory: asset.itemType.subCategory,
            name: asset.itemType.name,
            additionalName: asset.itemType.additionalName,
            warehouseLocation: asset.itemType.warehouseLocation,
            selectedAsset: null,
          }


          this.allItemTypes.push(itemType);
          newAssets.push(itemType);
        }
      }
      return newAssets;

    }
    else {
      //console.log("This is a non-numbered Item Type");
      var newAssets = [];
      var nonNumberedAssets = this.picklist.picklistNonNumberedAssets.filter(a => a.itemType.subCategoryId == subCategory.id);
      //console.log("Searching for Non Numbered Assets for which the sub category id of that item type matches the sub category:");
      //console.log(subCategory);

      for (let asset of nonNumberedAssets) {
        //console.log("Added asset");
        //console.log(asset);

        var nonNumbereditemType = {
          numbered: false,

          id: asset.itemType.id,
          subCategory: asset.itemType.subCategory,
          name: asset.itemType.name,
          additionalName: asset.itemType.additionalName,
          warehouseLocation: asset.itemType.warehouseLocation,
          quantity: asset.quantity,
          selectedQuantity: 0, 
        }

        newAssets.push(nonNumbereditemType);
      }

      //console.log("----------------------------------------------------");
      return newAssets;

    }
  }

  searchAsset(query) {
    // Find any assets with this as RFID or CAN number
    this.assetService.search(query, null, null, null, null, null).then((assets) => {

      // We may want to do something differently if this returns more than 1 result.
      // We are assuming both RFID and CAN Number will be unique.
      if (assets.length > 1) {
        this.assetSelectorService.showAssets(assets);
        return;
      }

      var asset = assets[0];
      this.processAsset(asset);
    });
  }

  validateAsset(asset) {
    //if (!asset.nextInspectionDate) {
    //  this.snotifyService.error("This asset has not been inspected");
    //  return false;
    //}

    if (asset.currentLocation.isQuarantine) {
      this.snotifyService.error("This asset can not be booked out from a qurantine location.");
      return false;
    }

    var certDates = asset.certDates;

    for (let certDate of certDates) {
      if (certDate.inspectionExists == false) {
        this.snotifyService.error("This asset has no valid inspection for regime: " + certDate.testType);
        return false;
      }

    }

    if (certDates.length == 0) {
      this.snotifyService.error("This asset has no valid inspections under any regime");
      return false;
    }

    else {

      var collectionDate = moment(this.picklist.collectionDate);

      var projectEnd = moment(this.picklist.collectionDate);
      var projectDuration = parseInt(this.picklist.jobDurationNumber);
      var days = 0;

      if (this.picklist.jobDurationInterval.indexOf("day") > -1) {
        projectEnd = projectEnd.add(projectDuration, 'days');
        days = projectDuration;
      }
      else if (this.picklist.jobDurationInterval.indexOf("week") > -1) {
        projectEnd = projectEnd.add(projectDuration, 'weeks');
        days = projectDuration * 7;
      }
      else {
        projectEnd = projectEnd.add(projectDuration, 'months');
        days = projectDuration * 30;
      }

      for (let certDate of certDates) {

        if (certDate.nextDate != null) {

          var nextDate = moment(certDate.nextDate);
          var isBeforeCollection = nextDate.isBefore(collectionDate);

          console.log("Next Date: " + nextDate.toString());
          console.log("Collection Date: " + collectionDate.toString());

          if (isBeforeCollection) {

            console.log("next date before collection date")

            this.snotifyService.error("This asset's '" + certDate.testType + "' cert expires before the collection date.");
            return false;
          }

          
          else {

            var projEndDate = moment(projectEnd);

            if (nextDate.isBefore(projEndDate)) {
              if (!confirm("This asset's '" + certDate.testType + "' cert expires before the project end date.")) {
                return false;
              }
              //console.log("next date before project end")
              //this.snotifyService.warning("This asset's '" + certDate.testType + "' cert expires before the project end date.");
            }
          }
        }
        else {
          this.snotifyService.error("This asset has not been expected under regime '" + certDate.testType +"'" );
          return false;
        }

      }

    }

    if (asset.currentProjectId != null) {
      this.snotifyService.error("This asset is not in stock. It is currently being used in Project:  " + asset.projectName);
      return false;
    }

    if (asset.safeForUse != null && !asset.safeForUse) {
      this.snotifyService.error("This asset is not safe for use. It is currently in: " + asset.locationName);
      return false;
    }

    //var nextInspectionDate = moment(asset.nextInspectionDate);
    //var collectionDate = moment(this.picklist.collectionDate);

    //if (nextInspectionDate.isBefore(collectionDate)) {
    //  this.snotifyService.error("This asset expires before collection date.");
    //  return false;
    //}


    console.log("return true");
    return true;

  }

  processAsset(asset) {

    var x = this.validateAsset(asset)

    if (!x) {
      console.log("fail to validate");
      return;
    }

    // Check if this asset already exists
    if (this.assets.filter(a => a.id == asset.id).length > 0)
      return;

    // Check if we have an item type for this.
    var itemType = this.allItemTypes.find(a => a.id == asset.itemTypeId && a.selectedAsset == null && a.replacedByItemType == null);
    if (itemType != null) {
      for (let category of this.categories) {
        if (category.subCategories != null) {
          for (let subCategory of category.subCategories) {
            var type = subCategory.itemTypes.find(a => a.id == itemType.id);
            if (type != null && type.selectedAsset == null) {
              type.selectedAsset = asset;
            }
          }
        }
      }


      itemType.selectedAsset = asset;
      this.assets.push(asset);
      this.updateCounts();
      this.searchQuery = "";
    } else {
      this.snotifyService.error("No item type found");
    }
  }

  

  clearAsset(itemType, asset) {
    asset.selected = false;
    
    itemType.selectedAsset = null;

    this.updateCounts();
  }

  updateCounts() {
    for (let category of this.categories) {
      if (category.subCategories != null) {
        for (let subCategory of category.subCategories) {
          subCategory.currentAssetCount = this.allItemTypes.filter(a => a.subCategory.id == subCategory.id && a.selectedAsset != null && a.selectedAsset != undefined).length;
        }
      }
    }
  }

  exchange(subCategory: SubCategory, itemType: ItemType) {
    this.itemTypeSelectorService.getNewItemType(itemType).then((newItemType) => {
      if (newItemType == null)
        return;

      itemType.replacedByItemType = newItemType;
      newItemType.quantity = itemType.quantity;
      newItemType.selectedQuantity = 0;

      var idx = subCategory.itemTypes.indexOf(itemType);
      subCategory.itemTypes.splice(idx, 0, newItemType);

      var newAssetItemType = {
        numbered: itemType.numbered,
        id: newItemType.id,
        picklistAssetId: UUID.UUID().toString(),
        subCategory: subCategory,
        name: newItemType.name,
        additionalNAme: newItemType.additionalName,
        warehouseLocation: newItemType.warehouseLocation,
        selectedAsset: null,
      }
      this.allItemTypes.push(newAssetItemType);
      //TODO
    });
  }

  filter(query) {
    if (this.allCategories == null)
      this.allCategories = this.categories;

    this.menuCategories = this.allCategories.filter(a => a.id != "-1").filter(a =>
      a.name.toLowerCase().includes(query.toLowerCase()) ||
      a.subCategories.filter(b => b.name.toLowerCase().includes(query.toLowerCase())).length > 0);
  }


  selectCategory(category) {
    this.resetCategories();
    category.isSelected = true;
    
    this.categories = [];
    if (category.id == "-1")
      this.categories = this.allCategories;
    else
      this.categories.push(category);
  }

  selectSubCategory(subCategory) {
    this.resetCategories();
    subCategory.isSelected = true;

    var category = this.allCategories.find(a => a.id == subCategory.categoryId);
    this.categories = [];
    this.categories.push(category);
  }

  resetCategories() {
    for (let category of this.categories) {
      if (category.subCategories != null) {
        for (let subCategory of category.subCategories) {
          subCategory.isSelected = false;
        }
      }
      category.isSelected = false;
    }
  }

  picklistPickedAssets = [];
  async save(userCompletion: boolean) {
    if (this.chosenLocationId == undefined || this.chosenLocationId == 0) {
      if (this.picklist.picklistNonNumberedAssets.length != 0) {
        this.snotifyService.error("Please Select A Storage Location");
        return;
      }
    }

    this.picklistPickedAssets = [];

    //var picklistAssets = this.picklist.picklistAssets;

    // Generate our picklist
    for (let category of this.categories) {
      if (category.subCategories != null) {
        for (let subCategory of category.subCategories) {
          for (let itemType of subCategory.itemTypes) {
            var asset = this.generateAsset(itemType);
            if (asset == null)
              continue;
            // Check if there are any item types that have been replaced by this
            var replacedItemType = this.allItemTypes.find(a => a.replacedByItemType != null && a.replacedByItemType.id == itemType.id);
            if (replacedItemType != null) {
              asset.replacedByItemTypeId = replacedItemType.id;
            }

          }
        }
      }
    }

    var picklist = {
      id: this.picklist.id,
      userId: this.authService.currentUser.value.id,
      userCompletion: userCompletion,
      allPicklistAssets: this.picklistPickedAssets,
      chosenLocationId: this.chosenLocationId
    };

    //console.log("chosen loc");
    //console.log(picklist.chosenLocationId);

    if (picklist.allPicklistAssets.length == 0) {
      this.snotifyService.error("Nothing has been picked");
      return;

    }
    else {

      // we want to validate we have stock
      for (let asset of picklist.allPicklistAssets.filter(a => !a.numbered)) {


        var nonNumberedAsset = await this.nonNumberedAssetService.getByItemTypeV2(asset.itemTypeId, this.chosenLocationId);


        if (nonNumberedAsset == null) {
          this.snotifyService.warning("Quantity exceeds requested, or exceeds stock: " + asset.itemTypeName);
          return;
        }

        asset.id = nonNumberedAsset.id;

        if (asset.selectedQuantity > nonNumberedAsset.quantity || asset.selectedQuantity > asset.quantity) {
          this.snotifyService.warning("Quantity exceeds requested, or exceeds stock: " + asset.itemTypeName);

          return;
        }
      }

      this.picklistService.savePicklistAssetsV2(picklist).then((data) => {
        if (userCompletion) {
          this.picklistService.completePicklistAssets(picklist).then((data) => {
            this.router.navigate(['stores/active-picklists']);
          });
        } else {
          this.router.navigate(['stores/active-picklists']);
        }
      });

      //if (userCompletion) {
      //  this.picklistService.completePicklistAssets(picklist).then((data) => {
      //    this.router.navigate(['stores/active-picklists']);
      //  });
      //}
      //else {
      //  this.picklistService.savePicklistAssetsV2(picklist).then((data) => {
      //    this.router.navigate(['stores/active-picklists']);
      //  });
      //}
    }
  }

  generateAsset(item: ItemType) {
    if (item.numbered) {
      //console.log("item");
      //console.log(item);
      return this.generatePickListAsset(item);
    } else {
      //console.log("Nonnumbered");
      //console.log(item);
      return this.generateNonNumberedAsset(item);
    }
  }

  generatePickListAsset(item: ItemType) {
    //console.log("generatePickListAsset");
    //console.log(item.selectedAsset);
    if (item.selectedAsset == null)
      return;

    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = true;
    //if (item.selectedAsset != null)
    pickListAsset.assetId = item.selectedAsset.id;
    this.picklistPickedAssets.push(pickListAsset);
    //console.log(pickListAsset);
    return pickListAsset;
  }

  generateNonNumberedAsset(item: ItemType) {
    console.log(item);
    //console.log("generateNonNumberedAsset");
    //console.log(item.selectedQuantity);
    if (item.selectedQuantity == 0)
      return;
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = false;
    pickListAsset.selectedQuantity = item.selectedQuantity;
    pickListAsset.itemTypeName = item.name;
    
    if (item.replacedByItemType != null) {
      pickListAsset.replacedByItemTypeId = item.replacedByItemType.id;
    }
    this.picklistPickedAssets.push(pickListAsset);
    //console.log(this.picklistPickedAssets);
    //console.log(pickListAsset);
    return pickListAsset;
  }
}
