import { UUID } from "angular2-uuid";
import { TestTypeQuestionResponse } from 'src/app/models/test-type-question-response';

export class TestTypeQuestion {
  id = '';
  question = '';
  position: number;
  expired = false;
  testTypeId: UUID;
  questionType: number;
  responses: TestTypeQuestionResponse[] = [];
  isEdit = false;

  selectedResponse: TestTypeQuestionResponse;
  selectedComments: string;
}
