import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Category } from '../../../../models/category';

import { CategoryService } from '../../../../services/category.service';
import { TestType } from 'src/app/models/test-type';
import { TestTypeService } from 'src/app/services/test-type.service';
import { SnotifyService } from 'ng-snotify';
import { UUID } from 'angular2-uuid';
import { SubCategory } from '../../../../models/sub-category';
import { ItemType } from '../../../../models/item-type';
import { ItemTestType } from '../../../../models/item-test-type';
import { SubCategoryService } from '../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../services/item-types.service';

@Component({
  selector: 'app-itemtypes-manage',
  templateUrl: './itemtypes-manage.component.html',
  styleUrls: ['./itemtypes-manage.component.css']
})
export class ItemTypesManageComponent implements OnInit {
  categories: Category[];

  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];

  itemType: ItemType;

  testTypeList: TestType[];
  testTypes: TestType[];

  testTypeId: string;

  pattern = new RegExp(/^\d+(?:\.\d{1,2})?$/);

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService, private typeService: TestTypeService,
    private snotifyService: SnotifyService, public router: Router, public route: ActivatedRoute) {
    this.itemType = new ItemType();
    this.itemType.isEdit = false;

    this.typeService.getAll().then((data) => {
      this.testTypes = data;
      // Use a second list to avoid hitting the server when selecting a regime and getting it's values
      this.testTypeList = data;
    });
  }

  onCategoryChange(value) {
    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  ngOnInit() {
    this.categoryService.getAll().then((data) => {
      this.categories = data;
      this.subCategoryService.getAll().then((data) => {
        this.subCategoriesList = data;
        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          if (id != undefined) {
            this.itemTypesService.get(id).then((data) => {
              console.log(data);
              this.itemType = data;
              this.itemType.isEdit = true;

              this.subCategories = this.subCategoriesList.filter(c => {
                return c.id == this.itemType.subCategoryId;
              });

              this.itemType.categoryId = this.itemType.subCategory.categoryId;
            });
          }
        });
      });
    });
  }

  onNumberedChange(value) {
    if (value == false) {
      this.itemType.assetRegister = false;
      this.itemType.ce = false;
    }
  }

  addTestType(testTypeId) {
    if (testTypeId == undefined || testTypeId == null) {
      this.snotifyService.error("Please select a valid testing regime");
    } else {
      var testTypes = this.itemType.testTypes.filter(t => t.testTypeId == testTypeId);

      if (testTypes.length == 0) {
        let testType = this.testTypeList.find(t => t.id == testTypeId);

        let descriptionTestType = new ItemTestType();
        descriptionTestType.id = UUID.UUID();
        descriptionTestType.testTypeId = testTypeId;
        descriptionTestType.testTypeName = testType.type;

        this.itemType.testTypes.push(descriptionTestType);

        this.testTypeId = undefined;
      } else {
        this.snotifyService.error("This testing regime has already been added");
        this.testTypeId = undefined;
      }
    }
  }

  submit() {
    if (this.itemType.testTypes.length == 0) {
      this.snotifyService.error("Please select at least 1 testing regime");
      return;
    }

    if (!this.pattern.test(this.itemType.standardCost.toString())) {
      this.snotifyService.error("Please ensure the standard cost has no more than 2 decimal places");
      return;
    }

    if (this.itemType.numbered) {
      if (this.itemType.internalHire) {
        if (this.itemType.internalHireRate == null || this.itemType.internalHireRate == undefined) {
          this.snotifyService.error("Please enter the internal hire rate");
          return;
        }

        if (!this.pattern.test(this.itemType.internalHireRate.toString())) {
          this.snotifyService.error("Please ensure the internal hire rate has no more than 2 decimal places");
          return;
        }
      }
    }

    this.itemTypesService.save(this.itemType).then((data) => {
      this.router.navigate(['administration/itemtypes']);
    }); 
  }

  removeTestType(testType) {
    this.itemType.testTypes.splice(this.itemType.testTypes.indexOf(testType), 1);
  }

}
