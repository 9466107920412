import { TestType } from "./test-type";

export class RegimeGroup {
  id: string;
  name: string;
  expired: boolean = false;

  expiredFormatted: string;
  is_edit: boolean = false;

  init(id: string, name: string, expired: boolean) {
    this.id = id;
    this.name = name;
    this.expired = expired;

    this.expiredFormatted = expired ? 'Yes' : 'No';
  }
}
