export class Company {
  id = '';
  name = '';
  address1 = '';
  address2 = '';
  address3 = '';
  address4 = '';
  postcode = '';
  contact = '';
  expired = false;
  email: '';

  isEdit: Boolean = false;
}
