export class TabletUser {
  id: string = "";
  firstname = '';
  lastname = '';
  pin = '';
  pin2 = '';
  expired = false;
  expiredFormatted: string;
  createdDate: string;

  is_edit = false;
  regimes: any[];

  init(id: string, firstname: string, lastname: string, pin: string, expired: boolean, createdDate: string, regimes: any[]) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.pin = pin;
    this.expired = expired;
    this.createdDate = createdDate;

    this.expiredFormatted = expired ? 'Yes' : 'No';
    this.regimes = regimes;
  }
}
