import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../../../services/category.service';
import { SnotifyService } from 'ng-snotify';
import { Category } from '../../../../models/category';
import { Accessory } from '../../../../models/accessory';
import { UUID } from 'angular2-uuid';
import { SubCategory } from '../../../../models/sub-category';
import { SubCategoryService } from '../../../../services/sub-category.service';
import { Inspection } from 'src/app/models/inspection';
import { InspectionService } from 'src/app/services/inspection.service';
import * as moment from 'moment';
import { TestType } from 'src/app/models/test-type';

@Component({
  selector: 'app-inspections-edit',
  templateUrl: './inspections-edit.component.html',
  styleUrls: ['./inspections-edit.component.css']
})
export class InspectionEditComponent implements OnInit {
  inspection: Inspection;

  type;

  inspectedAt;
  nextInspectionDate;

  constructor(private inspectionService: InspectionService, private categoryService: CategoryService, private snotifyService: SnotifyService, public router: Router, public route: ActivatedRoute) {
    this.inspection = new Inspection();
    this.inspection.isEdit = false;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.inspectionService.get(id).then((data) => {
          this.inspection = data;
          this.inspection.isEdit = true;

          this.inspectedAt = moment(data.inspectedAt).toDate();
          this.nextInspectionDate = moment(data.nextInspectionDate).toDate();

          console.log(this.inspection);
        });
      }
    });
  }

  submit() {
    console.log(this.inspection);

    var inspectedDate = moment(this.inspectedAt);
    //inspectedDate = inspectedDate.add(moment().hour(), 'hours');
    this.inspection.inspectedAt = moment(inspectedDate).format("YYYY/MM/DD");
    this.inspection.nextInspectionDate = moment(this.nextInspectionDate).format("YYYY/MM/DD")


    this.inspectionService.putDates(this.inspection).then((data) => {
      this.router.navigate(['reports/inspections']);
    });
  }

}
