import { Component, OnInit, Input } from '@angular/core';
import { CurrentLocationService } from '../../../../services/current-location.service';
import { CurrentLocation } from '../../../../models/current-location';
import { Category } from '../../../../models/category';
import { SubCategory } from '../../../../models/sub-category';
import { ItemType } from '../../../../models/item-type';
import { SubCategoryService } from '../../../../services/sub-category.service';
import { CategoryService } from '../../../../services/category.service';
import { ItemTypesService } from '../../../../services/item-types.service';
import { AssetService } from '../../../../services/asset.service';
import { Asset } from '../../../../models/asset';
import { AssetSelectorService } from '../../../../services/asset-selector.service';
import { TestTypeService } from '../../../../services/test-type.service';
import { InspectionService } from '../../../../services/inspection.service';
import { WebUserService } from '../../../../services/web-user.service';
import { AuthService } from '../../../../services/auth.service';
import { WebUser } from '../../../../models/webUser';
import { RegimeGroup } from '../../../../models/regione-group';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-select-stock',
  templateUrl: './select-stock.component.html',
  styleUrls: ['./select-stock.component.css']
})
export class SelectStockComponent implements OnInit {
  @Input() projectId: string;
  @Input() projectAssets: Asset[] = [];

  webUser: WebUser;

  locationsList: CurrentLocation[];
  categoriesList: Category[];

  allSubCategoriesList: SubCategory[];
  subCategoriesList: SubCategory[];

  allItemTypesList: ItemType[];
  itemTypesList: ItemType[];

  filter: any = {
    canNumber: "",
    currentLocationId: null,
    categoryId: null,
    subCategoryId: null,
    itemTypeId: null
  };

  assets: Asset[] = [];
  testTypeGroups: any = [];


  constructor(private assetService: AssetService, currentLocationService: CurrentLocationService, private categoryService: CategoryService, private subCategoryService: SubCategoryService,
    private itemTypeService: ItemTypesService, private assetSelectorService: AssetSelectorService, private inspectionService: InspectionService, private webUserService: WebUserService, private authService: AuthService,
    private snotifyService: SnotifyService) {
    this.webUserService.get(authService.currentUser.value.id).then((user) => {
      this.webUser = user;
    });

    currentLocationService.getAll().then((data) => {
      this.locationsList = data;
    });

    categoryService.getAll().then((data) => {
      this.categoriesList = data;
    });

    subCategoryService.getAll().then((data) => {
      this.allSubCategoriesList = data;
      this.subCategoriesList = data;
    });

    itemTypeService.getNumbered().then((data) => {
      this.allItemTypesList = data;
      this.itemTypesList = data;
    });

    this.assetSelectorService.selectedAssets.subscribe((assets) => {
      if (assets == null || assets.length == 0)
        return;

      this.processAsset(assets[0]);
    });
  }

  ngOnInit() {

    this.clearAssets();

    //this.assetSelectorService.nullifySelectedAssets()

    if (this.projectAssets.length > 0) {
      for (let asset of this.projectAssets) {
        this.processAsset(asset);
      }
    }
  }


  onCategoryChange(value) {
    this.filter.subCategoryId = null;
    if (value == '' || value == null) { value = null; }

    this.subCategoriesList = this.allSubCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.filter.itemTypeId = null;
    if (value == '' || value == null) { value = null; }

    console.log(value)
    console.log(this.allItemTypesList);

    this.itemTypesList = this.allItemTypesList.filter(c => {
      return value == null || c.subCategoryId == value;
    });
  }

  search() {
    this.assetService.searchNonExact(this.filter.canNumber, this.filter.currentLocationId, this.filter.categoryId, this.filter.subCategoryId, this.filter.itemTypeId, null).then((data) => {
      console.log(data);
      this.filter.canNumber = "";
      if (data.length == 0)
        return;

      if (data.length > 1) {
        this.assetSelectorService.showAssets(data);
        return;
      }

      this.processAsset(data[0]);
    });
  }

  processAsset(asset: Asset) {
    if (this.assets.filter(a => a.canNumber == asset.canNumber && a.itemTypeId == asset.itemTypeId).length > 0)
      return;

    this.itemTypeService.getTests(asset.itemTypeId).then((tests: any) => {
      for (let test of tests) {

        var testType = this.testTypeGroups.find(a => a.id == test.id);
        if (testType == null) {
          test.count = 1;

          this.testTypeGroups.push(test);
        } else {

          testType.count++;
        }

        if (asset.testTypes == null)
          asset.testTypes = [];

        if (asset.testTypes.filter(a => a.id == test.id).length == 0)
          asset.testTypes.push(test);
      }

      console.log(asset.testTypes);
    });

    this.assets.unshift(asset);

  }

  selectAssets(testType) {
    //console.log(this.webUser.regimes);
    // Check if this test type is in our regimes
    var contains = false;

    for (let regime of this.webUser.regimes.filter(a => !a.expired)) {
      console.log(regime.regimeGroup);
      if (regime.regimeGroup.testTypes.filter(a => a.id == testType.id && !a.expired).length > 0) {
        contains = true;
      }
    }

    if (!contains) {
        this.snotifyService.error("You are unable to inspect this test type.");
        return;
    }

    var assetsToInspect = [];
    for (let asset of this.assets) {
      console.log(asset);
      if (asset.testTypes == null) {
        asset.testTypes = [];
      }

      if (asset.testTypes.filter(a => a.id == testType.id).length > 0)
        assetsToInspect.push(asset);
    }

    this.inspectionService.startInspection(assetsToInspect, testType, this.projectId, false);
  }


  clearAssets() {
    this.assets = [];
    //this.projectAssets = [];
  }

}
