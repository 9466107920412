import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Conformance } from '../models/conformance';

@Injectable({
  providedIn: 'root'
})
export class ConformanceService {

  constructor(private http: HttpClient) { }

  public getAll(): Promise<Conformance[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Conformance[]>('/api/conformance/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
