import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from '../models/company';
import { SnotifyService } from 'ng-snotify';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Company[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Company[]>('/api/company/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: String): Promise<Company> {
    return new Promise((resolve, reject) => {
      this.http.get<Company>('/api/company/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(company: Company): Promise<Company> {
    return new Promise((resolve, reject) => {
      if (company.isEdit) {
        this.http.put<Company>('/api/company/put', company).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated company');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        company.id = UUID.UUID();

        this.http.post<Company>('/api/company/post', company).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added company');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }
}
