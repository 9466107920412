export class PickListAssetViewModel {
  id: string;
  itemTypeId: string;
  quantity: number;
  notes: string;
  numbered: boolean;
  selectedQuantity: number;
  assetId: string;
  replacedByItemTypeId: string;

  itemTypeName: string;
}
