import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Carrier } from 'src/app/models/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyService } from 'ng-snotify';
import { Picklist } from 'src/app/models/picklist';
import * as moment from 'moment';
import { Project } from 'src/app/models/project';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit {
  carriers: Carrier[];
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() picklist: Picklist;
  projects: Project[];
  projectId = null;

  durationNumbers: any[] = [
    { id: 0, value: 'Please Select' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
    { id: 5, value: '5' },
    { id: 6, value: '6' },
    { id: 7, value: '7' },
    { id: 8, value: '8' },
    { id: 9, value: '9' },
    { id: 10, value: '10' },
    { id: 11, value: '11' },
    { id: 12, value: '12' },
    { id: 13, value: '13' },
    { id: 14, value: '14' },
    { id: 15, value: '15' },
    { id: 16, value: '16' },
    { id: 17, value: '17' },
    { id: 18, value: '18' },
    { id: 19, value: '19' },
    { id: 20, value: '20' },
    { id: 21, value: '21' },
    { id: 22, value: '22' },
    { id: 23, value: '23' },
    { id: 24, value: '24' },
    { id: 25, value: '25' },
    { id: 26, value: '26' },
    { id: 27, value: '27' },
    { id: 28, value: '28' },
    { id: 29, value: '29' },
    { id: 30, value: '30' },
    { id: 31, value: '31' },
  ]

  durationIntervals: any[] = [
    { id: 0, value: 'Please Select' },
    { id: 1, value: 'day(s)' },
    { id: 2, value: 'week(s)' },
    { id: 3, value: 'month(s)' }
  ]

  constructor(private carrierService: CarrierService, private projectService: ProjectService, private modalService: NgbModal, private snotifyService: SnotifyService) {
  }

  ngOnInit() {
    this.carrierService.getAll().then((data) => {
      this.carriers = data;
    });

    this.projectService.getOpen().then((data) => {
      console.log("data");
      this.projects = data;
    });
  }

  carrierModalRef;
  showCarrierModal(content) {
    this.carrierModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.carrierModalRef.result.then((result) => { }, (reason) => { });
  }

  carrierAdded(carrier) {
    // Add this carrier and then put it in our list
    this.carrierModalRef.dismiss();

    this.carrierService.save(carrier).then((newCarrier) => {
      this.carrierService.getAll().then((data) => {
        this.carriers = data;
        this.picklist.carrierId = newCarrier.id;
      });
    })
  }

  submit() {
    if (this.picklist.projectId == '' || this.picklist.projectId == undefined || this.picklist.projectId == null) {
      console.log('inside');
      this.snotifyService.error("Please select a project");
      return;
    }

    if (this.picklist.description == '' || this.picklist.description == undefined || this.picklist.description == null) {
      this.snotifyService.error("Please enter a description for the picklist");
      return;
    }

    if (this.picklist.jobDurationNumber == '0' || this.picklist.jobDurationInterval == '0') {
      this.snotifyService.error("Please select the job duration");
      return;
    }

    // Cast the job duration and set the date
    if (this.picklist.jobDurationInterval == '1') { // Days
      var collectionDate = moment(this.picklist.collectionDate).add(parseInt(this.picklist.jobDurationNumber), 'days').format('YYYY/MM/DD HH:mm');

      this.picklist.jobDurationDate = collectionDate;
    } else if (this.picklist.jobDurationInterval == '2') { // Weeks
      var collectionDate = moment(this.picklist.collectionDate).add(parseInt(this.picklist.jobDurationNumber), 'weeks').format('YYYY/MM/DD HH:mm');

      this.picklist.jobDurationDate = collectionDate;
    } else if (this.picklist.jobDurationInterval == '3') { // Months
      var collectionDate = moment(this.picklist.collectionDate).add(parseInt(this.picklist.jobDurationNumber), 'months').format('YYYY/MM/DD HH:mm');

      this.picklist.jobDurationDate = collectionDate;
    }

    this.completed.emit(this.picklist);
  }

}
