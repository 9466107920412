import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/project';
import { Picklist } from 'src/app/models/picklist';
import { Page } from 'src/app/models/paging/page';
import { PicklistService } from 'src/app/services/picklist.service';
import { ConfirmationService } from 'primeng/api';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-picklists',
  templateUrl: './picklists.component.html',
  styleUrls: ['./picklists.component.css']
})
export class PicklistsComponent implements OnInit {
  filter: any = {
    projectId: undefined,
    jobNo: '',
    jobName: '',
    status: 0,
  };

  projects: Project[];

  page = new Page();
  rows = Array<Picklist>();

  user = null;

  constructor(private authService: AuthService, private projectService: ProjectService, private picklistService: PicklistService, public router: Router, private confirmationService: ConfirmationService, private snotifyService: SnotifyService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.projectService.getAll().then((data) => {
      this.projects = data;
    });

    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      console.log("User");
      console.log(user);
    })
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.picklistService.getResults(this.page, this.filter.projectId, this.filter.jobNo, this.filter.jobName, this.filter.status).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

  approve(picklist: Picklist) {
    this.confirmationService.confirm({
      message: "Are you sure you want to approve this picklist?",
      accept: () => {
        this.picklistService.approve(picklist).then(() => {
          this.loadTable(this.page);
        });
      }
    })
  }

  cancel(picklist: Picklist) {
    this.confirmationService.confirm({
      message: "Are you sure you want to cancel this picklist?",
      accept: () => {
        this.picklistService.cancel(picklist).then(() => {
          this.loadTable(this.page);
        });
      }
    })
  }


  async reinstate(picklist: Picklist) {
    var response = await this.picklistService.reinstatePicklist(picklist)
    if (response) {
      this.snotifyService.success("Successfully reinstated picklist");
      this.loadTable(this.page);
    } else {
      this.snotifyService.error("Something went wrong. Please try again.");
    }
  }


}
