import { Component, OnInit } from '@angular/core';
import { Supplier } from '../../../models/supplier';
import { Page } from '../../../models/paging/page';
import { SupplierService } from '../../../services/supplier.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {
  filter: any = {
    name: ""
  }

  page = new Page();
  rows = Array<Supplier>();

  columns = [
    { name: 'Name', prop: 'name' },
    { name: 'Active', prop: 'expiredFormatted' },
    { name: 'Option', prop: '' },
  ]

  constructor(public supplierService: SupplierService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/suppliers/manage'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == "")
      return false;

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.supplierService.getResults(this.page, this.filter.name).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

}
