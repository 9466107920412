import { Component, OnInit } from '@angular/core';
import { AuthService } from '../app/services/auth.service';

import { Spinkit } from 'ng-http-loader';

import { trigger, animate, transition, style, query, animateChild } from '@angular/animations';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        query(':enter', [
          style({
            opacity: 0,
            transform: 'translateY(15%)'
          })],
          { optional: true }
        ),
        query(
          ':leave',
          // here we apply a style and use the animate function to apply the style over 0.3 seconds
          [style({ opacity: 1 }), animate('0.1s', style({ opacity: 0 }))],
          { optional: true }
        ),
        query(
          ':enter',
          [style({ opacity: 0 }), animate('0.1s', style({ opacity: 1, transform: 'translateY(0%)' }))],
          { optional: true }
        )
      ])
    ])
  ]
})
export class AppComponent {
  public spinkit = Spinkit;

  title = 'app';

  currentUser;

  constructor(private auth: AuthService, private snotifyService: SnotifyService) {
    this.auth.currentUser.subscribe(data => {
      this.currentUser = data;
    });

    this.auth.getUser();
  }

  ngOnInit() {

  }
}

