import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportableService {

  constructor(private http: HttpClient) { }

  public getROTE(id: number, type: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getrote?inspection_id=' + id + '&reportType=' + type).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getChecklist(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getchecklist?inspection_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getJobExpiry(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(environment.reportableUrl + 'getexpiry?project_id=' + id);
      this.http.get<any>(environment.reportableUrl + 'getexpiry?project_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getPicklistManifestOutbound(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getpicklistmanifestoutbound?picklist_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistManifestOutboundMissing(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getpicklistmanifestoutboundmissing?picklist_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistTECerts(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/picklist/getpicklisttecerts?picklist_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistTECertsWrapper(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/picklist/getpicklisttecertswrapper?project_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistPUWERCerts(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/picklist/getpicklistpuwercerts?picklist_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistPUWERCertswrapper(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/picklist/GetPicklistPUWERCertsProject?project_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistPATCerts(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/picklist/getpicklistpatcerts?picklist_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getPicklistPATCertsWrapper(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/picklist/getpicklistpatcertswrapper?project_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  
  public getProjectRegister(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getProjectRegister?picklist_id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }




  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
