import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category';
import { SnotifyService } from 'ng-snotify';
import { SubCategory } from '../../../../models/sub-category';
import { ItemType } from '../../../../models/item-type';
import { ItemTypesService } from '../../../../services/item-types.service';
import { SubCategoryService } from '../../../../services/sub-category.service';

@Component({
  selector: 'app-linked-items',
  templateUrl: './linked-items.component.html',
  styleUrls: ['./linked-items.component.css']
})
export class LinkedItemsComponent implements OnInit {
  categories: Category[];
  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];
  itemTypes: ItemType[];
  itemTypesList: ItemType[];

  linkedItems: ItemType[] = [];
  categoryId: string;
  subCategoryId: string;
  itemTypeId: string;
  id: string;

  itemType: ItemType;

  constructor(private itemTypesService: ItemTypesService, private subCategoryService: SubCategoryService, private categoryService: CategoryService,
    private snotifyService: SnotifyService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get("id");
      if (id != undefined) {
        this.id = id;
      }

      this.itemTypesService.get(this.id).then((itemType) => {
        this.itemType = itemType;
      });

      this.categoryService.getAll().then((data) => {
        this.categories = data;
        this.subCategoryService.getAll().then((data) => {
          this.subCategoriesList = data;

          this.itemTypesService.getAll().then((data) => {
            this.itemTypesList = data;

            this.itemTypesList.filter(d => {
              return d.id != this.id;
            });
          })
        });
      });

      this.itemTypesService.getLinkedItems(id).then((data) => {
        this.linkedItems = data;
      });
    });
  }

  onCategoryChange(value) {
    this.subCategoryId = undefined;
    this.itemTypeId = undefined;

    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });

    console.log(this.subCategories);
  }

  onSubCategoryChange(value) {
    this.itemTypeId = undefined;

    this.itemTypes = this.itemTypesList.filter(c => {
      return value == null || c.subCategoryId == value;
    });
  }

  removeLinkedItem(item) {
    var idx = this.linkedItems.indexOf(item);
    if (idx > -1) {
      this.itemTypesService.removeLinkedItem(this.id, item.id).then(() => {
        this.linkedItems.splice(idx, 1);
      });
    }
  }

  addLinkedItem() {
    if (this.itemTypeId == undefined) {
      this.snotifyService.error("Please select an item type to be associated");
      return;
    }

    // Check if this has already been added
    if (this.linkedItems.find(a => a.id == this.itemTypeId) != null) {
      return;
    }

    console.log(this.itemTypeId);

    var itemType = this.itemTypes.find(a => a.id == this.itemTypeId);
    

    this.itemTypesService.addLinkedItem(this.id, this.itemTypeId).then(() => {
      this.linkedItems.push(itemType);
    });
  }
}
