import { Component, OnInit } from '@angular/core';
import { RegimeGroup } from '../../../models/regione-group';
import { Page } from '../../../models/paging/page';
import { RegimeGroupService } from '../../../services/regime-group.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-regime-groups',
  templateUrl: './regime-groups.component.html',
  styleUrls: ['./regime-groups.component.css']
})
export class RegimeGroupsComponent implements OnInit {
  filter: any = {
    name: ''
  };

  page = new Page();
  rows = new Array<RegimeGroup>();

  constructor(private regimeGroupService: RegimeGroupService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/testgroups/manage'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.regimeGroupService.getResults(this.page, this.filter.name).then((pagedData) => {
      console.log(pagedData);
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }
}
