import { Component, OnInit } from '@angular/core';
import { Division } from 'src/app/models/division';
import { Page } from 'src/app/models/paging/page';
import { DivisionService } from 'src/app/services/division.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.css']
})
export class DivisionsComponent implements OnInit {
  filter: any = {
    name: ''
  };

  page = new Page();
  rows = Array<Division>();

  constructor(private divisionService: DivisionService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/divisions/manage'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.divisionService.getResults(this.page, this.filter.name).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }
}
