import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NonNumberedAssetService } from '../../../../services/non-numbered-asset.service';
import { Category } from '../../../../models/category';
import { SubCategory } from '../../../../models/sub-category';
import { ItemType } from '../../../../models/item-type';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NonNumberedAsset } from '../../../../models/non-numbered-asset';
import { CategoryService } from '../../../../services/category.service';
import { SubCategoryService } from '../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../services/item-types.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { CurrentLocation } from 'src/app/models/current-location';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-non-numbered-asset-manage',
  templateUrl: './non-numbered-asset-manage.component.html',
  styleUrls: ['./non-numbered-asset-manage.component.css']
})
export class NonNumberedAssetManageComponent implements OnInit {
  @Input() data: string;

  categories: Category[];
  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];
  itemTypes: ItemType[];
  itemTypesList: ItemType[];
  currentLocations: CurrentLocation[];

  assetForm: FormGroup;

  isEdit: boolean = false;
  asset: NonNumberedAsset;
  currentUser = null;

  constructor(private auth: AuthService, private nonNumberedAssetService: NonNumberedAssetService, private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService,
    private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private currentLocationService: CurrentLocationService, private modalService: NgbModal) {
    this.asset = new NonNumberedAsset();
  }

  ngOnInit() {

    this.auth.currentUser.subscribe(data => {
      this.currentUser = data;
      console.log(this.currentUser);

      this.initForm();
    });

    this.currentLocationService.getAll().then((data) => {
      this.currentLocations = data;
    });


    this.categoryService.getAll().then((data) => {
      this.categories = data;
      this.subCategoryService.getAll().then((data) => {
        this.subCategoriesList = data;
        this.itemTypesService.getNonNumbered().then((data) => {
          this.itemTypesList = data;

          this.route.queryParams.subscribe(params => {
            var id = params['id'];
            var itemTypeId = params['itemTypeId'];

            if (id != null) {
              if (id != "00000000-0000-0000-0000-000000000000") {
                this.nonNumberedAssetService.get(id).then((data) => {
                  this.asset = data;
                  this.isEdit = true;

                  this.itemTypes = this.itemTypesList.filter(d => {
                    return d.id == this.asset.itemTypeId;
                  });

                  var desc = this.itemTypes.find(d => {
                    return d.id == this.asset.itemTypeId;
                  });

                  this.subCategories = this.subCategoriesList.filter(c => {
                    return c.id == desc.subCategoryId;
                  });

                  var cat = this.subCategoriesList.find(c => {
                    return c.id == desc.subCategoryId;
                  });


                  this.assetForm.patchValue({ id: this.asset.id });
                  this.assetForm.patchValue({ categoryId: cat.categoryId });
                  this.assetForm.patchValue({ subCategoryId: cat.id });
                  this.assetForm.patchValue({ isEdit: true });

                  this.assetForm.patchValue(data);
                });
              } else {
                this.itemTypesService.get(itemTypeId).then((data) => {
                  let itemType = data;

                  this.itemTypes = this.itemTypesList.filter(d => {
                    return d.id == itemType.id;
                  });

                  var desc = this.itemTypes.find(d => {
                    return d.id == itemType.id;;
                  });

                  this.subCategories = this.subCategoriesList.filter(c => {
                    return c.id == desc.subCategoryId;
                  });

                  var cat = this.subCategoriesList.find(c => {
                    return c.id == desc.subCategoryId;
                  });


                  this.assetForm.patchValue({ itemTypeId: itemType.id });
                  this.assetForm.patchValue({ categoryId: cat.categoryId });
                  this.assetForm.patchValue({ subCategoryId: cat.id });

                  this.assetForm.patchValue(data);
                });
              }
            }
          });
        })
      });
    });
  }

  initForm() {
    this.assetForm = this.fb.group({
      id: [null],
      categoryId: ['undefined', Validators.required],
      subCategoryId: ['undefined', Validators.required],
      itemTypeId: ['undefined', Validators.required],
      quantity: ['0', [Validators.min(0), Validators.required]],
      poNumber: [''],
      currentLocationId: ['', Validators.required],
      isEdit: [false, Validators.required],
      user: this.currentUser.firstname + " " + this.currentUser.lastname,
    });
  }

  onCategoryChange(value) {
    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.itemTypes = this.itemTypesList.filter(c => {
      return value == null || c.subCategoryId == value;
    });
  }

  get currentLocationId() {
    return this.assetForm.get('currentLocationId');
  }

  CurrentlocationModalRef;

  showCurrentLocationModal(content) {
    this.CurrentlocationModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.CurrentlocationModalRef.result.then((result) => { }, (reason) => { });
  }

  CurrentlocationAdded(currentLocations) {
    // Add this Location and then put it in our list
    this.CurrentlocationModalRef.dismiss();

    this.currentLocationService.save(currentLocations).then((newLocation) => {
      this.currentLocationService.getAll().then((data) => {
        this.currentLocations = data;
        this.assetForm.patchValue({ currentLocationId: newLocation.id });
      });
    })
  }

  submit() {
    if (this.assetForm.valid) {

      this.nonNumberedAssetService.save(this.assetForm.value).then((data) => {
        if (this.router.url.includes("stores"))
          this.router.navigate(['stores/nonnumberedassets']);
        else
          this.router.navigate(['administration/nonnumberedassets']);
      });
    } else {
    }
  }
}
