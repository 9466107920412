import { Component, OnInit, EventEmitter, Output, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Category } from '../../../../../../../models/category';
import { CategoryService } from '../../../../../../../services/category.service';
import { SubCategory } from '../../../../../../../models/sub-category';
import { SubCategoryService } from '../../../../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../../../../services/item-types.service';
import { ItemType } from '../../../../../../../models/item-type';
import { PicklistService } from '../../../../../../../services/picklist.service';

@Component({
  selector: 'app-picklist-categories',
  templateUrl: './picklist-categories.component.html',
  styleUrls: ['./picklist-categories.component.css']
})
export class PicklistCategoriesComponent implements OnInit, OnChanges {
  @Output() itemsChanged: EventEmitter<any> = new EventEmitter();
  @Input() changedItems: ItemType[];
  @Input() categories: Category[];
  @Input() showAllItems: boolean;

  category: Category = new Category();
  subCategory: SubCategory = new SubCategory();

  categoriesList: Category[];
  items: ItemType[];

  searchQuery: string = "";
  showSelected: boolean = false;

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService, private picklistService: PicklistService) {

  }

  ngOnInit() {
    this.categoriesList = this.categories;
    //this.categoryService.getAll().then((categories) => {
    //  this.subCategoryService.getAll().then((subCategories) => {
    //    this.itemTypesService.getAll(true).then((itemTypes) => {
    //      //console.log(itemTypes);
    //      for (let category of categories) {
    //        //category.isSelected = true;
    //        category.subCategories = subCategories.filter(a => a.categoryId == category.id);
    //        for (let subCategory of subCategories) {
    //          subCategory.itemTypes = itemTypes.filter(b => b.subCategoryId == subCategory.id);
    //        }

    //      }
    //    });
    //  });

    //  this.categories = categories;
    //  this.categoriesList = categories;
    //});
  }

  toggleShowSelected() {

    this.picklistService.showSelectedOnly.next(this.showSelected);
    
    
    if (this.picklistService.showSelectedOnly.value == false) {

      if (this.subCategory.id != '') {
        //console.log("inside sub")
        this.selectSubCategory(this.subCategory);
      } else {
        if (this.category.id != '') {
          this.selectCategory(this.category);
        }
      }

      

     
      //if (this.category.id != '') {
      //  //console.log(this.category);
      //  this.selectCategory(this.category);
      //} else if (this.subCategory.id != '') {
      //  //console.log(this.subCategory);
      //  this.selectSubCategory(this.subCategory);
      //}
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    ////console.log(changes);

    //if (changes.showAllItems && changes.showAllItems.currentValue && changes.showAllItems.firstChange && changes.categories != null) {
    //  this.showSelectedOnly = true;
    //}

    //if (changes.categories && changes.categories.currentValue != null && this.showSelectedOnly) {
    //  this.selectCategory(changes.categories.currentValue[0]);
    //}
  }

  filter(query) {
    if (this.categoriesList == null)
      this.categoriesList = this.categories;
    
    this.categories = this.categoriesList.filter(a =>
      a.name.toLowerCase().includes(query.toLowerCase()) ||
      a.subCategories.filter(b => b.name.toLowerCase().includes(query.toLowerCase())).length > 0);

    //this.categories = this.categoriesList.filter(a => a.name.toLowerCase().includes(query.toLowerCase()));
  }

  selectCategory(category) {
    this.resetCategories();
        
    category.isSelected = true;

    this.category = category;
    this.subCategory = new SubCategory();

    
 
    var items = [];
    for (let subCategory of category.subCategories) {
      items = items.concat(subCategory.itemTypes);
    }
    //console.log(items);
    //itemTypes.sort((a, b) => a.name - b.name);
    items = items.sort((a, b) => a.name - b.name);
    //console.log(items);
    this.itemsChanged.emit(items);
  }

  selectSubCategory(subCategory) {
    this.resetCategories();

    subCategory.isSelected = true;

    this.category = this.categories.find(a => a.id == subCategory.categoryId);

    this.subCategory = subCategory;
    //console.log(subCategory.itemTypes);
    this.itemsChanged.emit(subCategory.itemTypes);
  }

  resetCategories() {
    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        subCategory.isSelected = false;
      }
      category.isSelected = false;
    }
  }

  getCategoryQty(category) {
    ////console.log("GETTING COUNT");
    try {
      var qty = 0;
      for (let subCategory of category.subCategories) {
        ////console.log(subCategory);
        qty += this.getSubCategoryQty(subCategory);
      }

      return qty;
    } catch {
      return 0;
    }
  }

  getSubCategoryQty(subCategory) {
    var qty = 0;
    for (let itemType of subCategory.itemTypes) {
      qty += itemType.quantity ? itemType.quantity : 0;
    }

    return qty;
  }
}
