import { TestTypeQuestion } from "./test-type-question";
import { TestType } from "./test-type";
import { ItemType } from "./item-type";

export class Asset {
  id = '';
  itemTypeId: string;
  ownerId: string;
  rfid: string;
  expired = false;
  companyId: string;
  canNumber: string;
  manufacturerId: string;
  model: string;
  serialNum: string;
  supplier: string;
  purchaseDate: any;
  conformance: string;
  swl: string;
  currentColourId: string;
  currentLocationId: string;
  currentProjectId: string;
  assetStatusId: string;
  isEdit = false;

  selected: boolean = false;
  safeForUse: boolean;
  locationName: string;
  itemTypeName: string;

  itemType: ItemType;
  nextInspectionDate: string;

  questions: TestTypeQuestion[];
  testTypes: TestType[];
  isSelected: boolean;

  // These values are used during the book-in process
  requiresInspection: boolean = false;
  inspected: boolean = false;
}
