import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AssetService } from '../../../services/asset.service';
import { Asset } from '../../../models/asset';
import { CurrentLocation } from '../../../models/current-location';
import { CurrentLocationService } from '../../../services/current-location.service';
import { AuthService } from '../../../services/auth.service';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { PicklistService } from '../../../services/picklist.service';
import { WebUserService } from '../../../services/web-user.service';
import { WebUser } from '../../../models/webUser';
import { InspectionService } from '../../../services/inspection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationService } from 'primeng/api';
import { ItemTypesService } from '../../../services/item-types.service';
import { TestTypeService } from '../../../services/test-type.service';
import { TestType } from '../../../models/test-type';

@Component({
  selector: 'app-book-in',
  templateUrl: './book-in.component.html',
  styleUrls: ['./book-in.component.css']
})
export class BookInComponent implements OnInit {

  filter: any = {
    canNumber: "",
  }

  webUser: WebUser;

  assets: Asset[] = [];
  currentLocations: CurrentLocation[];
  selectedLocationId: string;

  assetTestTypeId: string;

  selectedAsset: Asset;
  testTypes: any = [];

  quarantineLocations: CurrentLocation[];

  quarantineLocationId: string;

  @ViewChild("inspectionModal") private inspectionModalContentRef: TemplateRef<Object>;
  @ViewChild("testTypeModal") private testTypeModalContentRef: TemplateRef<Object>;
  @ViewChild("quarantineModal") private quarantineModalContentRef: TemplateRef<Object>;

  constructor(private assetService: AssetService, private currentLocationService: CurrentLocationService, private authService: AuthService, private snotifyService: SnotifyService,
    private picklistService: PicklistService, private webUserService: WebUserService, private inspectionService: InspectionService, private modalService: NgbModal,
    private confirmationService: ConfirmationService, private itemTypeService: ItemTypesService, private testTypeService: TestTypeService) {
    this.currentLocationService.getAll().then((locations) => {
      this.currentLocations = locations;
    });

    this.webUserService.get(authService.currentUser.value.id).then((user) => {
      this.webUser = user;
    });
  }

  ngOnInit() {
  }

  search() {
    if (this.filter.canNumber == "") {
      this.snotifyService.error("Please enter some search criteria");
      return;
    }

    this.assetService.search(this.filter.canNumber, null, null, null, null, null).then((assets) => {
      console.log(assets);

      //var projectAssets = assets.filter(a => a.currentProjectId != null);
      var projectAssets = assets;
      var assetsToAdd = [];

      if (projectAssets.length > 0) {
        this.filter.canNumber = "";
      }

      for (let asset of projectAssets) {
        console.log(asset);
        if (this.assets.filter(a => a.id == asset.id).length == 0) {
          if (asset.itemType.mandatoryRote) {
            asset.requiresInspection = true;
          } else {
            if (!asset.nextInspectionDate) {
              asset.requiresInspection = true;
            } else {
              // Check the dates on this asset
              var nextInspectionDate = moment(asset.nextInspectionDate);
              var todaysDate = moment();

              if (nextInspectionDate.isBefore(todaysDate)) {
                asset.requiresInspection = true;
              } else {
                asset.inspected = true;
              }
            }
          }

          assetsToAdd.push(asset);
        }
      }

      this.assets = this.assets.concat(assetsToAdd);
    });
  }

  inspect(asset: Asset) {

    if (asset.requiresInspection) {
      this.confirmationService.confirm({
        message: 'Are you able to carry out an inspection?',
        key: 'confirmation',
        accept: () => {
          this.getTestTypes(asset);
        }, reject: () => {
          this.loadQuarantinePopup(asset);
        }
      });
    } else {
      this.snotifyService.error("This asset does not need to be inspected");
    }
  }

  getTestTypes(asset: Asset) {
    this.itemTypeService.getTests(asset.itemTypeId).then((tests: any) => {
      for (let test of tests) {
        var testType = this.testTypes.find(a => a.id == test.id);
        if (testType == null) {
          this.testTypes.push(test);
        }

        if (asset.testTypes == null)
          asset.testTypes = [];

        if (asset.testTypes.filter(a => a.id == test.id && a.isLolerOrPuwer == true).length == 0)
          asset.testTypes.push(test);
      }

      this.assetTestTypeId = "";

      this.selectedAsset = asset;

      this.showTestTypesModal(this.testTypeModalContentRef);
    });
  }

  testTypeModalRef;
  showTestTypesModal(content) {
    this.testTypeModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.testTypeModalRef.result.then((result) => { }, (reason) => { });
  }

  loadQuarantinePopup(asset: Asset) {
    this.confirmationService.confirm({
      message: 'This item needs to be placed into quarantine',
      key: 'quarantine',
      rejectVisible: false,
      acceptLabel: 'OK',
      accept: () => {
        this.currentLocationService.getAll().then((locations) => {
          this.quarantineLocations = locations.filter(a => a.isQuarantine);

          this.quarantineLocationId = "";

          this.selectedAsset = asset;

          this.showQuarantineModal(this.quarantineModalContentRef);
        });
      }
    });
  }

  quarantineModalRef;
  showQuarantineModal(content) {
    this.quarantineModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.quarantineModalRef.result.then((result) => { }, (reason) => { });
  }

  async moveToQuarantine() {
    if (this.quarantineLocationId == null || this.quarantineLocationId == 'undefined' || this.quarantineLocationId == undefined || this.quarantineLocationId == '') {
      this.snotifyService.error("Please select the quarantine location");
      return;
    } else {
      var bookInAsset = {
        assetId: this.selectedAsset.id,
        locationId: this.quarantineLocationId,
        bookedInAt: moment().format("YYYY/MM/DD HH:mm"),
        webUserId: this.authService.currentUser.value.id
      }

      await this.picklistService.postBookInQuarantine(bookInAsset);

      let index = this.assets.findIndex(a => a.id == this.selectedAsset.id);

      this.assets.splice(index, 1);

      this.quarantineModalRef.close();

      this.snotifyService.success("Successfully moved item to quarantine");
    }
  }

  selectAssetTestType() {
    if (this.assetTestTypeId == null || this.assetTestTypeId == 'undefined' || this.assetTestTypeId == undefined || this.assetTestTypeId == '') {
      this.snotifyService.error("Please select the inspection regime");
      return;
    }

    this.testTypeModalRef.close();

    this.beginInspection(this.selectedAsset, this.assetTestTypeId);
  }

  beginInspection(asset: Asset, testTypeId: string) {
    let testType: TestType;

    this.testTypeService.get(testTypeId).then((data) => {
      testType = data;

      var contains = false;
      for (let regime of this.webUser.regimes.filter(a => !a.expired)) {
        console.log(regime.regimeGroup);
        if (regime.regimeGroup.testTypes.filter(a => a.id == testType.id && !a.expired).length > 0) {
          contains = true;
        }
      }

      if (!contains) {
        this.snotifyService.error("You are unable to inspect this test type.");
        return;
      }

      console.log(asset.testTypes);

      var assetsToInspect = [];
      if (asset.testTypes.filter(a => a.id == testType.id).length > 0)
        assetsToInspect.push(asset);

      console.log(assetsToInspect);

      if (assetsToInspect.length > 0) {
        this.inspectionService.startInspection(assetsToInspect, testType, asset.currentProjectId, true);

        this.showInspectionModal(this.inspectionModalContentRef);
      }
    });
  }

  inspectionFinished(inspections) {
    this.inspectionModalRef.close();

    var passedInspections = inspections.passedInspections;
    var failedInspections = inspections.failedInspections;

    // We are presuming that the item has passed
    var inspection = passedInspections[0];

    let asset = this.assets.find(a => a.id == inspection.assetId);
    asset.inspected = true;
    asset.requiresInspection = false;
  }

  inspectionModalRef;
  showInspectionModal(content) {
    this.inspectionModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
    this.inspectionModalRef.result.then((result) => { }, (reason) => { });
  }

  async save() {
    if (this.selectedLocationId == '' || this.selectedLocationId == 'undefined' || this.selectedLocationId == null) {
      this.snotifyService.error("Please select the location these items are being booked in to.");
      return;
    }

    var requiresInspectionCount = this.assets.filter(a => a.requiresInspection == true && a.currentProjectId != null).length;

    if (requiresInspectionCount > 0) {
      this.snotifyService.error("There are items which require inspection");
      return;
    } else {
      for (let asset of this.assets.filter(a => a.currentProjectId != null)) {
        var bookInAsset = {
          assetId: asset.id,
          locationId: this.selectedLocationId,
          bookedInAt: moment().format("YYYY/MM/DD HH:mm"),
          webUserId: this.authService.currentUser.value.id
        }

        await this.picklistService.postBookIn(bookInAsset);
      }

      this.assets = [];
      this.selectedLocationId = null;
      this.snotifyService.success("Successfully booked in");
      this.filter.canNumber = "";
    }
  }
}
