import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { TabletUser } from '../../../models/tablet-user';
import { TabletUserService } from '../../../services/tablet-user.service';

@Component({
  selector: 'app-tablet-users',
  templateUrl: './tablet-users.component.html',
  styleUrls: ['./tablet-users.component.css']
})
export class TabletUsersComponent implements OnInit {

  page = new Page();
  rows = Array<TabletUser>();

  columns = [
    { name: 'First Name', prop: 'firstname' },
    { name: 'Last Name', prop: 'lastname' },
    { name: 'Expired', prop: 'expiredFormatted' },
    { name: 'Created Date', prop: 'createdDate' },
    { name: 'Option', prop: '' },
  ];

  constructor(public tabletUserService: TabletUserService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['users/tablet/manage'], { queryParams: { id: row.id } });
  }


  editRegimes(row) {
    this.router.navigate(['users/tablet/regimes'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.tabletUserService.getResults(this.page).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

}
