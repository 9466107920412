import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { QuestionResponse } from '../models/question-response';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class QuestionResponseService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<QuestionResponse[]> {
    return new Promise((resolve, reject) => {
      this.http.get<QuestionResponse[]>("/api/questionresponse/getall").subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public checkIfExists(response: QuestionResponse): Promise<QuestionResponse> {
    console.log(response);

    return new Promise((resolve, reject) => {
      response.id = UUID.UUID();

      this.http.get<QuestionResponse>('/api/questionresponse/checkifexists?responseText=' + response.responseText).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject(error);
      });
    });
  }

  public save(response: QuestionResponse): Promise<QuestionResponse> {
    return new Promise((resolve, reject) => {
      response.id = UUID.UUID();

      this.http.post<QuestionResponse>("/api/questionresponse/post", response).subscribe(data => {
        resolve(data);
        this.snotifyService.success("Successfully added question response");
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
