import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AssetStatus } from '../models/asset-status';

@Injectable({
  providedIn: 'root'
})
export class AssetStatusService {

  constructor(private http: HttpClient) { }

  public getAll(): Promise<AssetStatus[]> {
    return new Promise((resolve, reject) => {
      this.http.get<AssetStatus[]>('/api/assetstatus/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
