import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../../../../../models/category';
import { ItemType } from '../../../../../../models/item-type';
import { PickListAssetViewModel } from '../../../../../../models/picklist-asset-view-model';
import { CategoryService } from '../../../../../../services/category.service';
import { ItemTypesService } from '../../../../../../services/item-types.service';
import { SubCategoryService } from '../../../../../../services/sub-category.service';

@Component({
  selector: 'app-picklist-new',
  templateUrl: './picklist-new.component.html',
  styleUrls: ['./picklist-new.component.css']
})
export class PicklistNewComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() searchFilterName: string;
  @Input() searchFilterDesc: string;

  categories: Category[];
  itemTypes: ItemType[];

  picklistAssets: PickListAssetViewModel[] = [];

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService) {
   
  }

  async ngOnInit() {
    //var subCategories = [];

    var categories = await this.categoryService.getAll();
    var subCategories = await this.subCategoryService.getAll();
    var itemTypes = await this.itemTypesService.getAll(true);
    for (let category of categories) {
      category.subCategories = subCategories.filter(a => a.categoryId == category.id);
      for (let subCategory of subCategories) {
        subCategory.itemTypes = itemTypes.filter(b => b.subCategoryId == subCategory.id);
      }
    }

    var allCat = new Category();
    allCat.init("-1", "ALL ITEMS", false);
    allCat.subCategories = subCategories;
    categories.unshift(allCat);
    this.categories = categories;
  }


  selectItems(items: ItemType[]) {
    //console.log(items);

    this.itemTypes = items;
  }

  save() {
    this.picklistAssets = [];
    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes) {
          if (itemType.quantity > 0) {
            this.generateAsset(itemType);
            for (let linkedItemType of itemType.linkedItemTypes) {
              if (linkedItemType.itemType.quantity > 0)
                this.generateAsset(linkedItemType.itemType);
            }
          }
        }
      }
    }
    
    this.completed.emit(this.picklistAssets);
  }

  generateAsset(item: ItemType) {
    if (item.numbered) {
      this.generatePickListAsset(item);
    } else {
      this.generateNonNumberedAsset(item);
    }
  }

  generatePickListAsset(item: ItemType) {
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = true;
    this.picklistAssets.push(pickListAsset);
  }

  generateNonNumberedAsset(item: ItemType) {
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = false;
    this.picklistAssets.push(pickListAsset);
  }
}
