import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../models/project';
import { Page } from '../../../models/paging/page';
import { InspectionService } from '../../../services/inspection.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetService } from 'src/app/services/asset.service';
import { Asset } from 'src/app/models/asset';
import { ConfirmationService } from 'primeng/api';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-job-expiry',
  templateUrl: './job-expiry.component.html',
  styleUrls: ['./job-expiry.component.css']
})
export class JobExpiryComponent implements OnInit {

  projects: Project[];

  filter: any = {
    projectId: null,
  }

  page = new Page();
  rows = Array<any>();

  asset;


  constructor(private projectService: ProjectService, private reportableService: ReportableService, private inspectionService: InspectionService, public route: ActivatedRoute, private assetService: AssetService, private confirmationService: ConfirmationService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "JobNo";
    this.asset = new Asset();
  }

  ngOnInit() {
    this.projectService.getAll().then((projects) => {
      this.projects = projects;
      this.loadTable(this.page);
       
    });
  }



  downloadReport(id) {
    this.reportableService.getJobExpiry(id).then((data) => {
      importedSaveAs(data, 'Job Expiry Report.pdf');
    });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.projectService.getExpiryResults(this.page, this.filter.projectId).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;

      console.log(this.rows);
    });
  }

}
