import { Component, OnInit } from '@angular/core';
import { Project } from '../../../models/project';
import { Page } from '../../../models/paging/page';
import { Picklist } from '../../../models/picklist';
import { ProjectService } from '../../../services/project.service';
import { PicklistService } from '../../../services/picklist.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Division } from '../../../models/division';
import { DivisionService } from '../../../services/division.service';

@Component({
  selector: 'app-outgoing-picklist',
  templateUrl: './outgoing-picklist.component.html',
  styleUrls: ['./outgoing-picklist.component.css']
})
export class OutgoingPicklistComponent implements OnInit {

  filter: any = {
    projectId: undefined,
    divisionId: undefined,
    status: 0
  };

  projects: Project[];
  divisions: Division[];

  page = new Page();
  rows = Array<Picklist>();

  constructor(private projectService: ProjectService, private divisionService: DivisionService, private reportableService: ReportableService, private picklistService: PicklistService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.projectService.getAll().then((data) => {
      this.projects = data;
    });

    this.divisionService.getAll().then((data) => {
      this.divisions = data;
    });
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  downloadReport(picklistId, type) {

    /*TYPE:
     1 = MO
     2 = MOM
     3 = TE
     4 = PUWER
     */

    if (type == 1) {
      this.reportableService.getPicklistManifestOutbound(picklistId).then((data) => {
        importedSaveAs(data, 'Manifest Outbound.pdf');
      });
    }

    else if (type == 2) {
      this.reportableService.getPicklistManifestOutboundMissing(picklistId).then((data) => {
        importedSaveAs(data, 'Manifest Outbound Missing.pdf');
      });
    }

    else if (type == 3) {
      this.reportableService.getPicklistTECerts(picklistId).then((data) => {
        importedSaveAs(data, 'Numbered Items TE Certs.pdf');
      });
    }
  
    else if (type == 4) {
      this.reportableService.getPicklistPUWERCerts(picklistId).then((data) => {
        importedSaveAs(data, 'Numbered Items PUWER Certs.pdf');
      });
    }

    else if (type == 5) {
      this.reportableService.getPicklistPATCerts(picklistId).then((data) => {
        importedSaveAs(data, 'Numbered Items PAT Certs.pdf');
      });
    }

  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.picklistService.getOutgoingPicklists(this.page, this.filter.projectId, this.filter.divisionId, this.filter.status).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

}
