import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TestTypeService } from 'src/app/services/test-type.service';
import { QuestionResponseService } from 'src/app/services/question-response.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TestTypeQuestion } from 'src/app/models/test-type-question';
import { QuestionResponse } from 'src/app/models/question-response';
import { UUID } from 'angular2-uuid';
import { TestTypeQuestionResponse } from 'src/app/models/test-type-question-response';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-test-type-question-detail',
  templateUrl: './test-type-question-detail.component.html',
  styleUrls: ['./test-type-question-detail.component.css']
})
export class TestTypeQuestionDetailComponent implements OnInit {
  @Output() added: EventEmitter<TestTypeQuestion> = new EventEmitter<TestTypeQuestion>();
  @Output() addedResponse: EventEmitter<string> = new EventEmitter<string>();
  @Output() edited: EventEmitter<TestTypeQuestion> = new EventEmitter<TestTypeQuestion>();
  @Input() isEdit: boolean = false;
  @Input() selectedQuestion: TestTypeQuestion;

  questionText = '';
  questionType = '0';
  showResponses = true;

  responseText = '';
  negativeResponse: boolean = false;

  question: TestTypeQuestion;
  questionResponses: QuestionResponse[];

  testTypeId: UUID;

  constructor(public testTypeService: TestTypeService, public questionResponseService: QuestionResponseService, private route: ActivatedRoute, private router: Router,
    private snotifyService: SnotifyService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const testTypeId = params['testtypeid'];
      if (testTypeId != undefined) {
        this.testTypeId = testTypeId;
      }
    });

    this.question = new TestTypeQuestion();

    this.bindQuestionResponses();

    
  }

  bindQuestionResponses() {
    this.questionResponseService.getAll().then((data) => {
      this.questionResponses = data;
    });
  }

  onQuestionTypeChange(value) {
    if (value == "0") {
      this.showResponses = true;
    } else {
      this.showResponses = false;
    }
  }

  add() {
    this.question = new TestTypeQuestion();
    this.question.id = UUID.UUID();
    this.question.question = this.questionText;
    this.question.questionType = parseInt(this.questionType);
    this.question.testTypeId = this.testTypeId;

    if (this.question.question == '' || this.question.question == null) {
      this.snotifyService.error("Please enter the question text");
      return;
    }

    if (this.question.questionType == 0) {
      var selectedResponses = this.questionResponses.filter(function (a) {
        return a.required;
      });

      if (selectedResponses.length == 0) {
        this.snotifyService.error("Please select a response");
        return;
      } else if (selectedResponses.length == 1) {
        this.snotifyService.error("Please select at least 2 responses");
        return;
      }

      for (let response of selectedResponses) {
        let testResponse = new TestTypeQuestionResponse();
        testResponse.id = UUID.UUID();
        testResponse.responseText = response.responseText;
        testResponse.testTypeQuestionId = this.question.id;

        this.question.responses.push(testResponse);
      }
    }

    this.added.emit(this.question);
  }

  deselectResponses() {
    if (this.questionResponses != null) {
      for (let response of this.questionResponses) {
        response.required = false;
      }
    }
  }

  addResponse() {
    this.addedResponse.emit(this.responseText + "|" + this.negativeResponse);
  }

}
