import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Page } from '../../../models/paging/page';
import { Category } from '../../../models/category';
import { CategoryService } from '../../../services/category.service';
import { SubCategory } from '../../../models/sub-category';
import { ItemType } from '../../../models/item-type';
import { SubCategoryService } from '../../../services/sub-category.service';
import { ItemTypesService } from '../../../services/item-types.service';
import { FilterService } from '../../../services/filter.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-itemtypes',
  templateUrl: './itemtypes.component.html',
  styleUrls: ['./itemtypes.component.css']
})
export class ItemTypesComponent implements OnInit {
  filter: any = {
    categoryId: undefined,
    subCategoryId: undefined,
    name: '',
    description: ''
  };

  default_filters = JSON.parse(JSON.stringify(this.filter));

  categories: Category[];

  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];

  page = new Page();
  rows = Array<ItemType>();

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypeService: ItemTypesService, public router: Router, public route: ActivatedRoute, private filterService: FilterService, private authService: AuthService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.categoryService.getAll().then((data) => {
      this.categories = data;
    });

    this.subCategoryService.getAll().then((data) => {
      this.subCategories = data;
      this.subCategoriesList = data;
    });

    console.log("Getting Filters")
    var filters = this.filterService.getFilters(this.authService.currentUser.value.id, this.router.url);
    if (filters != null)
      this.filter = filters;
  }

  onCategoryChange(value) {
    console.log("INSIDE");
    this.filter.subCategoryId = undefined;
    if (value == '' || value == null) { value = null; }

    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const categoryId = params['categoryid'];
      if (categoryId != undefined) {
        this.filter.categoryId = categoryId;
      }
      const subCategoryId = params['subcategoryid'];
      if (subCategoryId != undefined) {
        this.filter.subCategoryId = subCategoryId;
      }
    });

    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/itemtypes/manage'], { queryParams: { id: row.id } });
  }

  editLinkedItems(row) {
    console.log(row.id);
    this.router.navigate(['administration/itemtypes/linkeditems'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    console.log("Saving Filters");
    this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
    this.itemTypeService.getResults(this.page, this.filter.categoryId, this.filter.subCategoryId, this.filter.name, this.filter.description).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

  goToAssets(row) {
    console.log(row);
    if (row.numbered) {
      this.router.navigate(['administration/assets'], { queryParams: { categoryid: row.categoryId, subcategoryid: row.subCategoryId, itemTypeId: row.id } });
    }
    else {
      this.router.navigate(['administration/nonnumberedassets'], { queryParams: { categoryid: row.categoryId, subcategoryid: row.subCategoryId, itemTypeId: row.id } });
    }
  }

}
