import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Division } from '../models/division';
import { ServerData } from '../models/paging/server-data';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { UUID } from 'angular2-uuid';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public get(id: string): Promise<Division> {
    return new Promise((resolve, reject) => {
      this.http.get<Division>('/api/division/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAll(): Promise<Division[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Division[]>('/api/division/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(division: Division): Promise<Division> {
    return new Promise((resolve, reject) => {
      if (division.isEdit) {
        this.http.put<Division>('/api/division/put', division).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated division');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        division.id = UUID.UUID();

        this.http.post<Division>('/api/division/post', division).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added division');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public getResults(page: Page, name: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&name=' + name;

      this.http.get<ServerData>('/api/division/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });


  }
}
