import { RegimeGroup } from "./regione-group";

export class WebUser {
  id = '';
  firstname = '';
  lastname = '';
  email = '';
  email2 = '';
  password = '';
  password2 = '';
  userType = 1;
  is_edit = false;

  regimes: any;
}
