import { ItemType } from "./item-type";

export class SubCategory {
  id: string = "";
  name: string = "";
  expired = false;
  categoryId: string = "";

  isEdit = false;
  isSelected = false;

  itemTypes: ItemType[];
}
