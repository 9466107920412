import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Manufacturer } from 'src/app/models/manufacturer';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manufacturer-manage-detail',
  templateUrl: './manufacturer-manage-detail.component.html',
  styleUrls: ['./manufacturer-manage-detail.component.css']
})
export class ManufacturerManageDetailComponent implements OnInit {
  @Output() added: EventEmitter<Manufacturer> = new EventEmitter<Manufacturer>();
  @Input() hideExpired: boolean = false;
  manufacturer: Manufacturer;

  constructor(public manufacturerService: ManufacturerService, public router: Router, private route: ActivatedRoute) {
    this.manufacturer = new Manufacturer();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.manufacturerService.get(id).then((data) => {
          this.manufacturer = data;
          this.manufacturer.isEdit = true;
        })
      } else {
        this.hideExpired = true;
      }
    });
  }

  submit() {
    this.added.emit(this.manufacturer);
  }

}
