import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-manage-detail',
  templateUrl: './company-manage-detail.component.html',
  styleUrls: ['./company-manage-detail.component.css']
})
export class CompanyManageDetailComponent implements OnInit {
  @Output() added: EventEmitter<Company> = new EventEmitter<Company>();
  @Input() hideExpired: boolean = false;
  company: Company
  constructor(public companyService: CompanyService, public router: Router, private route: ActivatedRoute) {
   
  }

  ngOnInit() {
    this.company = new Company();

    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.companyService.get(id).then((data) => {
          if (data != null) {
            this.company = data;
            this.company.isEdit = true;
          }
        })
      }
    });
  }

  submit() {
    this.added.emit(this.company);
  }

}
