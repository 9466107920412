import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { TestType } from '../../../../models/test-type';
import { TestTypeService } from '../../../../services/test-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WebUserService } from '../../../../services/web-user.service';
import { RegimeGroupService } from '../../../../services/regime-group.service';
import { RegimeGroup } from '../../../../models/regione-group';

@Component({
  selector: 'app-web-user-regimes',
  templateUrl: './web-user-regimes.component.html',
  styleUrls: ['./web-user-regimes.component.css']
})
export class WebUserRegimesComponent implements OnInit {
  user;
  groups: RegimeGroup[];

  selectedRegimeGroupId: string;
  rows = [];

  constructor(private webUserService: WebUserService, public router: Router, private route: ActivatedRoute, private regimeGroupService: RegimeGroupService) {
    this.regimeGroupService.getAll().then((groups) => {
      this.groups = groups.sort((a, b) => a.name.localeCompare(b.name));
    })
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.webUserService.get(id).then((data) => {
        this.user = data;
        this.rows = this.user.regimes.filter(a => !a.expired);
      });
    });
  }

  addTestType() {

    if (this.selectedRegimeGroupId == null)
      return;

    if (this.user.regimes.filter(a => a.testTypeId == this.selectedRegimeGroupId && !a.expired).length > 0) {
      alert("Already added");
      return;
    }

    var regimeGroup = this.groups.find(a => a.id == this.selectedRegimeGroupId);
    var newReg = {
      webUserId: this.user.id,
      regimeGroupId: regimeGroup.id
    }

    this.webUserService.addRegime(newReg).then((regime) => {
      this.loadTable();
    });
  }

  removeTestType(regime) {
    this.webUserService.deleteRegime(regime.id).then(() => {
      this.loadTable();
    });
  }

  loadTable() {
    this.webUserService.get(this.user.id).then((data) => {
      this.user = data;
      this.rows = this.user.regimes.filter(a => !a.expired);
    });
  }

}
