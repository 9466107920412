import { Component, OnInit } from '@angular/core';
import { Supplier } from '../../../../models/supplier';
import { SupplierService } from '../../../../services/supplier.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-supplier-manage',
  templateUrl: './supplier-manage.component.html',
  styleUrls: ['./supplier-manage.component.css']
})
export class SupplierManageComponent implements OnInit {
  supplier: Supplier;

  constructor(public supplierService: SupplierService, public router: Router, private route: ActivatedRoute) {
    this.supplier = new Supplier();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.supplierService.get(id).then((data) => {
          this.supplier = data;
          this.supplier.isEdit = true;
        })
      }
    });
  }

  submit() {
    this.supplierService.save(this.supplier).then((data) => {
      this.router.navigate(['administration/suppliers']);
    });
  }
}
