import { Component, OnInit } from '@angular/core';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { Router } from '@angular/router';
import { CurrentLocation } from 'src/app/models/current-location';

@Component({
  selector: 'app-location-manage',
  templateUrl: './location-manage.component.html',
  styleUrls: ['./location-manage.component.css']
})
export class LocationManageComponent implements OnInit {

  constructor(public currentLocationService: CurrentLocationService, public router: Router) { }

  ngOnInit() {
  }

  added(currentLocation) {
    this.currentLocationService.save(currentLocation).then((data) => {
      this.router.navigate(['administration/locations']);
    });
  }
}
