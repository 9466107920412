import { Component, OnInit } from '@angular/core';
import { WebUser } from '../../../../models/webUser';
import { WebUserService } from '../../../../services/web-user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';


@Component({
  selector: 'app-web-manage',
  templateUrl: './web-manage.component.html',
  styleUrls: ['./web-manage.component.css']
})
export class WebManageComponent implements OnInit {
  user: WebUser;
  error: "";

  constructor(public webUserService: WebUserService, public router: Router, public route: ActivatedRoute, private snotifyService: SnotifyService) { }

  ngOnInit() {
    this.user = new WebUser();
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.webUserService.get(id).then((data) => {
          this.user = data;
          this.user.email2 = data.email;
          this.user.is_edit = true;
        })
      }
    });
  }

  submit() {
    if (this.user.password != this.user.password2) {
      this.snotifyService.error('PASSWORDS DO NOT MATCH');
      return;
    }

    if (this.user.email != this.user.email2) {
      this.snotifyService.error('EMAILS DO NOT MATCH');
      return;
    }

    this.webUserService.save(this.user).then((data) => {
      this.router.navigate(['users/web']);
    }).catch((err) => {
      this.error = err.error;
    });
  }

}
