export class Supplier {
  id: string;
  name: string;
  expired: boolean = false;
  expiredFormatted: string;
  isEdit: boolean;

  init(id: string, name: string, expired: boolean) {
    this.id = id;
    this.name = name;
    this.expired = expired;
    this.expiredFormatted = this.expired ? 'No' : 'Yes';
  }
}
