import { Component, OnInit } from '@angular/core';
import { AssetSelectorService } from '../../services/asset-selector.service';
import { Asset } from '../../models/asset';

@Component({
  selector: 'app-asset-selector',
  templateUrl: './asset-selector.component.html',
  styleUrls: ['./asset-selector.component.css']
})
export class AssetSelectorComponent implements OnInit {

  showModal: boolean = false;
  multiSelect: boolean = false;

  assets: Asset[] = [];

  constructor(private service: AssetSelectorService) {
    this.subscribe();
  }

  ngOnInit() {
    this.subscribe()
  }
  subscribe() {
    //console.log("AssetSelectorComponent: subscribe");
    this.service.assets.subscribe((assets) => {
      this.assets = assets;
      if (assets != null && assets.length > 0)
        this.showModal = true;
      else
        this.showModal = false;
    });

    this.service.multiSelect.subscribe((multiSelect) => {
      this.multiSelect = multiSelect;
    });
  }
  public selectAsset(asset: Asset) {
    if (this.multiSelect) {
      if (asset.isSelected == null)
        asset.isSelected = false;

      asset.isSelected = !asset.isSelected;
      return;
    }

    var assets = [];
    assets.push(asset);
    //console.log("selectAsset comp");
    this.service.selectAssets(assets);
  }

  cancel() {
    this.showModal = false;
  }

  submit() {
   // console.log("selectAsset submit");
    this.service.selectAssets(this.assets.filter(a => a.isSelected));
    this.showModal = false;
  }

}
