export class Project {
  id: string;
  jobNo: string;
  jobName: string;
  divisionId: string;
  createdAt: string;
  isEdit: boolean = false;
  completed: boolean = false;
  name: string = "";
}
