import { PickListNonNumberedAsset } from "./picklist-non-numbered-asset";
import { PickListAsset } from "./picklist-asset";
import { PickListAssetViewModel } from "./picklist-asset-view-model";

export class Picklist {
  id: string;
  projectId: string;
  description: string;
  createdAt: string;
  collectionDate: any;
  collectionDateFormatted: string;
  carrierId: string;
  destination: string;
  notes: string;
  jobDurationNumber: string;
  jobDurationInterval: string;
  jobDurationDate: string;
  approved: boolean;
  nonNumberedLocation: string;
  picklistAssets: PickListAsset[];
  picklistNonNumberedAssets: PickListNonNumberedAsset[];

  allPicklistAssets: PickListAssetViewModel[];
  finishedPicklistAsset = [];
  picklistPickedAssets = [];
}
