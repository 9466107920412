import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrentColour } from '../models/current-colour';

@Injectable({
  providedIn: 'root'
})
export class CurrentColourService {

  constructor(private http: HttpClient) { }

  public getAll(): Promise<CurrentColour[]> {
    return new Promise((resolve, reject) => {
      this.http.get<CurrentColour[]>('/api/currentcolour/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
