import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Category } from '../models/category';

import { UUID } from 'angular2-uuid';

import { SnotifyService } from 'ng-snotify';
import { ItemType } from '../models/item-type';

@Injectable({
  providedIn: 'root'
})
export class ItemTypesService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getTests(itemTypeId: string) {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType>('/api/itemtype/getTestTypes?id=' + itemTypeId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: String): Promise<ItemType> {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType>('/api/itemtype/get?id=' + id).subscribe(data => {
        data.standardCost = data.standardCost == null ? 0 : Number((data.standardCost).toFixed(2));
        data.internalHireRate = data.internalHireRate == null ? 0 : Number((data.internalHireRate).toFixed(2));

        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAll(includeLinkedItems: boolean = false): Promise<ItemType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType[]>('/api/itemtype/getall?includeLinkedItems=' + includeLinkedItems).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getBySubCategory(subCategoryId: string): Promise<ItemType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType[]>('/api/itemtype/getbysubcategory?subCategoryId=' + subCategoryId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public addLinkedItem(parentId: string, childId: string) {
    var dto = {
      parentItemTypeId: parentId,
      newItemTypeId: childId,
    }
    return new Promise((resolve, reject) => {
      this.http.post<ItemType>('/api/itemtype/postlinkeditem', dto).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully added linked item');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public removeLinkedItem(parentId: string, childId: string) {
    return new Promise((resolve, reject) => {
      this.http.delete<ItemType>('/api/itemtype/deletelinkeditem?parentId=' + parentId + '&childId=' + childId).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully deleted linked item');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getLinkedItems(id: String): Promise<ItemType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType[]>('/api/itemtype/getlinkeditems?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getNumbered(): Promise<ItemType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType[]>('/api/itemtype/getnumbered').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getNonNumbered(): Promise<ItemType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<ItemType[]>('/api/itemtype/getnonnumbered').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(description: ItemType): Promise<ItemType> {
    return new Promise((resolve, reject) => {
      if (description.isEdit) {
        this.http.put<ItemType>('/api/itemtype/put', description).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated item type');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        description.id = UUID.UUID();

        this.http.post<ItemType>('/api/itemtype/post', description).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added item type');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public getResults(page: Page, categoryId: string, subCategoryId: string, name: string, description: string): Promise<PagedData<ItemType>> {
    const pagedData = new PagedData<ItemType>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&categoryId=' + categoryId;
      params += '&subCategoryId=' + subCategoryId;
      params += '&name=' + name;
      params += '&description=' + description;

      this.http.get<ServerData>('/api/itemtype/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
