import { Component, OnInit } from '@angular/core';
import { Page } from '..//../../models/paging/page';
import { WebUserService } from '../../../services/web-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-web-users',
  templateUrl: './web-users.component.html',
  styleUrls: ['./web-users.component.css']
})
export class WebUsersComponent implements OnInit {
  filter: any = {
    email: ''
  };

  page = new Page();
  rows = Array<any>();

  constructor(public webUserService: WebUserService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.loadTable(this.page);
  }

  edit(row) {
    this.router.navigate(['users/web/manage'], { queryParams: { id: row.id } });
  }

  editRegimes(row) {
    this.router.navigate(['users/web/regimes'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.webUserService.getResults(this.page, this.filter.email).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }

}
