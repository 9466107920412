import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Project } from '../models/project';
import { UUID } from 'angular2-uuid';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Project[]>('/api/project/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getOpen(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Project[]>('/api/project/GetOpen').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: string): Promise<Project> {
    return new Promise((resolve, reject) => {
      this.http.get<Project>('/api/project/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(project: Project): Promise<Project> {
    return new Promise((resolve, reject) => {
      if (project.isEdit) {
        this.http.put<Project>('/api/project/put', project).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated project');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        project.id = UUID.UUID();
        this.http.post<Project>('/api/project/post', project).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added project');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public getProjectResults(page: Page, projectId: string, divisionId: string, status: number): Promise<PagedData<Project>> {
    const pagedData = new PagedData<Project>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'JobNo'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&projectId=' + projectId;
      params += '&divisionId=' + divisionId;
      params += '&status=' + status;

      this.http.get<ServerData>('/api/project/getprojectreporttable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getResults(page: Page, jobNo: string, jobName: string, divisionId: string, status: number): Promise<PagedData<Project>> {
    const pagedData = new PagedData<Project>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'JobNo'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&jobNo=' + jobNo;
      params += '&jobName=' + jobName;
      params += '&divisionId=' + divisionId;
      params += '&status=' + status;

      this.http.get<ServerData>('/api/project/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

    public getExpiryResults(page: Page, projectId: string): Promise<PagedData<Project>> {
    const pagedData = new PagedData<Project>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'JobNo'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&project_Id=' + projectId;

      this.http.get<ServerData>('/api/project/getexpirytable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
