import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ProjectService } from '../../../../../../services/project.service';
import { Project } from '../../../../../../models/project';
import { CategoryService } from '../../../../../../services/category.service';
import { SubCategoryService } from '../../../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../../../services/item-types.service';
import { Category } from '../../../../../../models/category';
import { ItemType } from '../../../../../../models/item-type';
import { PickListAssetViewModel } from '../../../../../../models/picklist-asset-view-model';
import { PicklistService } from '../../../../../../services/picklist.service';
import { Picklist } from '../../../../../../models/picklist';

@Component({
  selector: 'app-picklist-existing',
  templateUrl: './picklist-existing.component.html',
  styleUrls: ['./picklist-existing.component.css']
})
export class PicklistExistingComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() picklist: Picklist;
  @Input() searchFilterName: string;
  @Input() searchFilterDesc: string;

  projects: Project[];
  selectedProjectId: string;
  picklists: Picklist[]
  selectedPicklistId: string;

  categories: Category[];
  itemTypes: ItemType[];

  picklistAssets: PickListAssetViewModel[] = [];

  constructor(private projectService: ProjectService, private picklistService: PicklistService, private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService) {
    projectService.getAll().then((projects) => {
      for (let project of projects) {
        project.name = project.jobNo + " - " + project.jobName;
      }
      this.projects = projects;
    });
  }

  async ngOnInit() {
    var categories = await this.categoryService.getAll();
    var subCategories = await this.subCategoryService.getAll();
    var itemTypes = await this.itemTypesService.getAll(true);
    for (let category of categories) {
      category.subCategories = subCategories.filter(a => a.categoryId == category.id);
      for (let subCategory of subCategories) {
        subCategory.itemTypes = itemTypes.filter(b => b.subCategoryId == subCategory.id);
      }
    }

    var allCat = new Category();
    allCat.init("-1", "ALL ITEMS", false);
    allCat.subCategories = subCategories;
    categories.unshift(allCat);
    this.categories = categories;

    if (this.picklist != null && this.picklist.id != null && this.picklist.id != '') {
      this.loadPicklist();
    } else {
      this.picklist = new Picklist();
      this.picklist.approved = false;
    }


  }

  loadPicklist() {
    console.log(this.picklist);
    for (let category of this.categories) {
      if (category.subCategories != null) {
        for (let subCategory of category.subCategories) {
          for (let itemType of subCategory.itemTypes) {
            var currentAssetItemTypes = this.picklist.picklistAssets.filter(a => a.itemTypeId == itemType.id);
            if (currentAssetItemTypes != null && currentAssetItemTypes.length > 0) {
              const qty = currentAssetItemTypes.map(a => a.quantity).reduce((a, b) => a + b);
              itemType.quantity = qty;
              itemType.notes = currentAssetItemTypes[0].notes;

              for (let linkedItemType of itemType.linkedItemTypes) {
                var assetLinkedItemType = this.picklist.picklistAssets.find(a => a.itemTypeId == linkedItemType.itemTypeId);

                if (assetLinkedItemType != null) {
                  linkedItemType.itemType.quantity = assetLinkedItemType.quantity;
                  linkedItemType.itemType.notes = assetLinkedItemType.notes;
                }

                var nonAssetLinkedItemType = this.picklist.picklistNonNumberedAssets.find(a => a.itemTypeId == linkedItemType.itemTypeId);

                if (nonAssetLinkedItemType != null) {
                  linkedItemType.itemType.quantity = nonAssetLinkedItemType.quantity;
                  linkedItemType.itemType.notes = nonAssetLinkedItemType.notes;
                }
              }
            }

            var currentNonAssetItemType = this.picklist.picklistNonNumberedAssets.find(a => a.itemTypeId == itemType.id);
            if (currentNonAssetItemType != null) {
              itemType.quantity = currentNonAssetItemType.quantity;
              itemType.notes = currentNonAssetItemType.notes;

              for (let linkedItemType of itemType.linkedItemTypes) {
                var assetLinkedItemType = this.picklist.picklistAssets.find(a => a.itemTypeId == linkedItemType.itemTypeId);

                if (assetLinkedItemType != null) {
                  linkedItemType.itemType.quantity = assetLinkedItemType.quantity;
                  linkedItemType.itemType.notes = assetLinkedItemType.notes;
                }

                var nonAssetLinkedItemType = this.picklist.picklistNonNumberedAssets.find(a => a.itemTypeId == linkedItemType.itemTypeId);

                if (nonAssetLinkedItemType != null) {
                  linkedItemType.itemType.quantity = nonAssetLinkedItemType.quantity;
                  linkedItemType.itemType.notes = nonAssetLinkedItemType.notes;
                }
              }

            }
          }
        }
      }
    }
  }

  projectChanged() {
    this.picklistService.getAllFromProject(this.selectedProjectId).then((picklists) => {
      this.picklists = picklists.map((i) => { i.description = i.description + ' - ' + i.collectionDateFormatted; return i; });
    });
  }

  picklistChanged() {
    var selectedPicklist = this.picklists.find(a => a.id == this.selectedPicklistId);
    console.log(selectedPicklist);
    this.picklistService.selectedPicklist = selectedPicklist;

    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes) {
          // Get any picklist assets of this item type where orginal is true.
          // The length of this array will be the qty
          const selectedNumberedAssets = selectedPicklist.picklistAssets.filter(a => a.itemType == itemType.id && a.original == true);
          itemType.quantity = selectedNumberedAssets.length;
          
          const assetsWithNotes = selectedNumberedAssets.filter(a => a.notes != null && a.notes != "");
          if (assetsWithNotes.length > 0) {
            console.log(selectedNumberedAssets);
            itemType.notes = assetsWithNotes[0].notes;
          }
        

          const selectedNonNumberedAsset = selectedPicklist.picklistNonNumberedAssets.find(a => a.itemTypeId == itemType.id);
          if (selectedNonNumberedAsset != null) {
            itemType.quantity += selectedNonNumberedAsset.quantity;
            itemType.notes = selectedNonNumberedAsset.notes;
          }
        }
      }
    }

    //for (let category of this.categories) {
    //  for (let subCategory of category.subCategories) {
    //    for (let itemType of subCategory.itemTypes) {
    //      itemType.quantity = 0;
    //      var currentAssetItemTypes = selectedPicklist.picklistAssets.filter(a => a.itemTypeId == itemType.id && a.original == true);
    //      for (let assetItemType of currentAssetItemTypes) {
    //        if (itemType.quantity == null) { itemType.quantity = 0; }
    //        itemType.quantity++;
    //      }

    //      var currentNonAssetItemType = selectedPicklist.picklistNonNumberedAssets.find(a => a.itemTypeId == itemType.id);
    //      if (currentNonAssetItemType != null) {
    //        itemType.quantity = currentNonAssetItemType.quantity;
    //        itemType.notes = currentNonAssetItemType.notes;
    //      }
    //    }
    //  }
    //}
  }

  selectItems(items: ItemType[]) {
    this.itemTypes = items;
  }

  save() {
    this.picklistAssets = [];

    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes) {
          if (itemType.quantity > 0) {
            this.generateAsset(itemType);
            for (let linkedItemType of itemType.linkedItemTypes) {
              if (linkedItemType.itemType.quantity > 0)
                this.generateAsset(linkedItemType.itemType);
            }
          }
        }
      }
    }

    this.completed.emit(this.picklistAssets);
  }

  generateAsset(item: ItemType) {
    if (item.numbered) {
      this.generatePickListAsset(item);
    } else {
      this.generateNonNumberedAsset(item);
    }
  }

  generatePickListAsset(item: ItemType) {
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = true;
    this.picklistAssets.push(pickListAsset);
  }

  generateNonNumberedAsset(item: ItemType) {
    var pickListAsset = new PickListAssetViewModel();
    pickListAsset.itemTypeId = item.id;
    pickListAsset.quantity = item.quantity;
    pickListAsset.notes = item.notes;
    pickListAsset.numbered = false;
    this.picklistAssets.push(pickListAsset);
  }
}
