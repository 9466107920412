import { Component, OnInit } from '@angular/core';
import { ManufacturerService } from 'src/app/services/manufacturer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manufacturer-manage',
  templateUrl: './manufacturer-manage.component.html',
  styleUrls: ['./manufacturer-manage.component.css']
})
export class ManufacturerManageComponent implements OnInit {

  constructor(public manufacturerService: ManufacturerService, public router: Router) { }

  ngOnInit() {
  }

  added(manufacturer) {
    this.manufacturerService.save(manufacturer).then((data) => {
      this.router.navigate(['administration/manufacturers']);
    });
  }

}
