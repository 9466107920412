import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DivisionService } from 'src/app/services/division.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Division } from 'src/app/models/division';

@Component({
  selector: 'app-division-manage-detail',
  templateUrl: './division-manage-detail.component.html',
  styleUrls: ['./division-manage-detail.component.css']
})
export class DivisionManageDetailComponent implements OnInit {
  @Output() added: EventEmitter<Division> = new EventEmitter<Division>();
  @Input() hideExpired: boolean = false;
  division: Division;

  constructor(public divisionService: DivisionService, public router: Router, public route: ActivatedRoute) {
    this.division = new Division();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.divisionService.get(id).then((data) => {
          this.division = data;
          this.division.isEdit = true;
        })
      } else {
        this.hideExpired = true;
      }
    });
  }

  submit() {
    this.added.emit(this.division);
  }

}
