import { Injectable } from '@angular/core';
import { Asset } from '../models/asset';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssetSelectorService {

  public assets: BehaviorSubject<Asset[]>;
  public selectedAssets: BehaviorSubject<Asset[]>;
  public multiSelect: BehaviorSubject<boolean>;

  constructor() {
    this.assets = new BehaviorSubject<Asset[]>(null);
    this.selectedAssets = new BehaviorSubject<Asset[]>(null);
    this.multiSelect = new BehaviorSubject<boolean>(null);
  }

  public showAssets(assetList: Asset[], multiSelect: boolean = false) {
    this.multiSelect.next(multiSelect);
    this.assets.next(assetList);
  }

  public nullifySelectedAssets() {
    this.selectedAssets = new BehaviorSubject<Asset[]>(null);
  }

  public nullifyAssets() {
    this.assets = new BehaviorSubject<Asset[]>(null);
  }

  public selectAssets(assets: Asset[]) {
    this.selectedAssets.next(assets);
    this.assets.next(null);
  }
  
}
