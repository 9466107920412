import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NonNumberedAssetService } from 'src/app/services/non-numbered-asset.service';
import { NonNumberedAsset } from 'src/app/models/non-numbered-asset';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category';
import { SubCategory } from '../../../../../models/sub-category';
import { ItemType } from '../../../../../models/item-type';
import { SubCategoryService } from '../../../../../services/sub-category.service';
import { ItemTypesService } from '../../../../../services/item-types.service';

@Component({
  selector: 'app-asset-non-numbered-manage',
  templateUrl: './asset-non-numbered-manage.component.html',
  styleUrls: ['./asset-non-numbered-manage.component.css']
})
export class AssetNonNumberedManageComponent implements OnInit {
  @Input() data: string;
  @Output() saved: EventEmitter<any> = new EventEmitter();

  categories: Category[];
  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];
  itemTypes: ItemType[];
  itemTypesList: ItemType[];

  assetForm: FormGroup;

  isEdit: boolean = false;

  asset: NonNumberedAsset;

  constructor(private nonNumberedAssetService: NonNumberedAssetService, private categoryService: CategoryService, private subCategoryService: SubCategoryService, private itemTypesService: ItemTypesService,
    private fb: FormBuilder) {
    this.asset = new NonNumberedAsset();
  }

  ngOnInit() {
    this.categoryService.getAll().then((data) => {
      this.categories = data;
      this.subCategoryService.getAll().then((data) => {
        this.subCategoriesList = data;
        this.itemTypesService.getNonNumbered().then((data) => {
          this.itemTypesList = data;

          if (this.data != undefined) {
            this.nonNumberedAssetService.get(this.data).then((data) => {
              this.asset = data;

              this.isEdit = true;

              this.itemTypes = this.itemTypesList.filter(d => {
                return d.id == this.asset.itemTypeId;
              });

              var desc = this.itemTypes.find(d => {
                return d.id == this.asset.itemTypeId;
              });

              this.subCategories = this.subCategoriesList.filter(c => {
                return c.id == desc.subCategoryId;
              });

              var cat = this.subCategoriesList.find(c => {
                return c.id == desc.subCategoryId;
              });

              this.assetForm.patchValue({ id: this.asset.id });
              this.assetForm.patchValue({ categoryId: cat.categoryId });
              this.assetForm.patchValue({ subCategoryId: cat.id });

              this.assetForm.patchValue(data);
            });
          }
        })
      });
    });

    this.initForm();
  }

  initForm() {
    this.assetForm = this.fb.group({
      id: [null],
      categoryId: ['undefined', Validators.required],
      subCategoryId: ['undefined', Validators.required],
      itemTypeId: ['undefined', Validators.required],
      quantity: ['0', [Validators.min(1), Validators.required]],
    });
  }

  onCategoryChange(value) {
    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.itemTypes = this.itemTypesList.filter(c => {
      return value == null || c.subCategoryId == value;
    });
  }

  submit() {
    console.log(this.assetForm.value);

    //if (this.assetForm.valid) {
    //  this.saved.emit(this.assetForm.value);
    //} else {
    //  console.log("NOT VALID");
    //}
  }

}
