import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Supplier } from 'src/app/models/supplier';
import { SupplierService } from 'src/app/services/supplier.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-supplier-manage-detail',
  templateUrl: './supplier-manage-detail.component.html',
  styleUrls: ['./supplier-manage-detail.component.css']
})
export class SupplierManageDetailComponent implements OnInit {
  @Output() added: EventEmitter<Supplier> = new EventEmitter<Supplier>();
  @Input() hideExpired: boolean = false;
  supplier: Supplier;

  constructor(public supplierService: SupplierService, public router: Router, private route: ActivatedRoute) {
    this.supplier = new Supplier();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.supplierService.get(id).then((data) => {
          this.supplier = data;
          this.supplier.isEdit = true;
        })
      } else {
        this.hideExpired = true;
      }
    });
  }

  submit() {
    this.added.emit(this.supplier);
  }

}
