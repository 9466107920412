import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TabletUser } from '../../../../models/tablet-user';
import { TabletUserService } from '../../../../services/tablet-user.service';

@Component({
  selector: 'app-tablet-user-manage',
  templateUrl: './tablet-user-manage.component.html',
  styleUrls: ['./tablet-user-manage.component.css']
})
export class TabletUserManageComponent implements OnInit {
  user: TabletUser;

  constructor(public tabletUserService: TabletUserService, public router: Router, private route: ActivatedRoute) {
    this.user = new TabletUser();
  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.tabletUserService.get(id).then((data) => {
          this.user = data;
          this.user.is_edit = true;
        });
      }
    });
  }

  submit() {
    if (this.user.pin != this.user.pin2) {
      alert('PINS DO NOT MATCH');
      return;
    }

    this.tabletUserService.save(this.user).then((data) => {
      this.router.navigate(['users/tablet']);
    });
  }

}
