import { Component, OnInit } from '@angular/core';
import { trigger, transition, query, animate, keyframes, style } from '@angular/animations';
import { Picklist } from 'src/app/models/picklist';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { PicklistService } from 'src/app/services/picklist.service';
import { PickListAssetViewModel } from '../../../../models/picklist-asset-view-model';
import { ProjectService } from '../../../../services/project.service';

@Component({
  selector: 'app-picklist-manage',
  templateUrl: './picklist-manage.component.html',
  styleUrls: ['./picklist-manage.component.css'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query('*', animate('0.3s ease-in', keyframes([style({ transform: 'translateY(100%)', opacity: '0' }), style({ transform: 'translateY(0%)', opacity: 1 })])), { optional: true })
      ]),
      transition('* => void', [
        query('*', animate('0.3s ease-in', keyframes([style({ transform: 'translateY(0%)', opacity: '1' }), style({ transform: 'translateY(-100%)', opacity: 0 })])), { optional: true })
      ])
    ])
  ]
})
export class PicklistManageComponent implements OnInit {
  currentStep: number = 1;
  maxStep: number = 0;

  picklist: Picklist;

  constructor(private picklistService: PicklistService, public router: Router, public route: ActivatedRoute) {
    this.picklist = new Picklist();
    this.picklist.collectionDate = moment().toDate();
    this.picklist.jobDurationNumber = '0';
    this.picklist.jobDurationInterval = '0';

    this.picklistService.nullifySelection();
  }

  ngOnInit() {
    // Check to see if we are editing
    this.route.paramMap.subscribe(params => {
      const id = params.get("id");
      console.log(id);
      if (id != null) {
        this.picklistService.get(id).then((picklist) => {
          console.log(picklist);
          var jobDurationIntervalIdx = 0;
          switch (picklist.jobDurationInterval) {
            case "day(s)":
              jobDurationIntervalIdx = 1;
              break;
            case "week(s)":
              jobDurationIntervalIdx = 2;
              break;
            case "month(s)":
              jobDurationIntervalIdx = 3;
              break;
          }
          picklist.jobDurationInterval = jobDurationIntervalIdx.toString();

          picklist.collectionDate = moment(picklist.collectionDate).toDate();
          this.picklist = picklist;
        });
      }
    });
  }

  goToStep(step) {
    this.currentStep = step;
  }

  step1Completed(picklist: Picklist) {
    this.picklist = picklist;
    this.currentStep++;

    this.maxStep = 1;
  }

  step2Completed(assets: PickListAssetViewModel[]) {
    var distinctAssets = [];
    for (let asset of assets) {
      if (distinctAssets.filter(a => a.itemTypeId == asset.itemTypeId).length > 0)
        continue;

      distinctAssets.push(asset);
    }

    this.picklist.allPicklistAssets = distinctAssets;
    //this.picklist.picklistNonNumberedAssets = vm.nonNumberedAssets;

    this.picklistService.save(this.picklist).then((data) => {
      this.router.navigate(['administration/picklists']);
    });
  }

}
