import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UUID } from 'angular2-uuid';
import { TestTypeQuestionDetailComponent } from '../test-type-question-detail/test-type-question-detail.component';
import { TestTypeQuestion } from 'src/app/models/test-type-question';
import { TestTypeService } from 'src/app/services/test-type.service';
import { QuestionResponse } from 'src/app/models/question-response';
import { QuestionResponseService } from 'src/app/services/question-response.service';
import { SnotifyService } from 'ng-snotify';
import { TestTypeQuestionResponse } from 'src/app/models/test-type-question-response';

@Component({
  selector: 'app-test-type-question-manage',
  templateUrl: './test-type-question-manage.component.html',
  styleUrls: ['./test-type-question-manage.component.css']
})
export class TestTypeQuestionManageComponent implements OnInit {
  @ViewChild(TestTypeQuestionDetailComponent) child: TestTypeQuestionDetailComponent;

  questionText = '';
  questionType = '0';
  showResponses = true;

  responseText = '';
  negativeResponse: boolean = false;

  id: UUID;

  question: TestTypeQuestion;
  questionResponses: QuestionResponse[];

  testTypeId: UUID;

  constructor(private route: ActivatedRoute, private router: Router, private testTypeService: TestTypeService, private questionResponseService: QuestionResponseService,
    private snotifyService: SnotifyService, private location: Location) {
  }

  ngOnInit() {
    this.bindQuestionResponses();

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.id = id;

        this.testTypeService.getQuestion(this.id).then((data) => {
          this.question = data;

          this.questionText = this.question.question;
          this.questionType = this.question.questionType.toString();
          this.testTypeId = this.question.testTypeId;

          if (this.questionType == "0") {
            this.bindSelectedResponses();
          } else {
            this.showResponses = false;
          }

        });
      }
    });
  }

  bindQuestionResponses() {
    this.questionResponseService.getAll().then((data) => {
      this.questionResponses = data;
    });
  }

  bindSelectedResponses() {
    for (let r of this.questionResponses) {
      for (let response of this.question.responses) {
        if (r.responseText == response.responseText) {
          r.required = true;
        }
      }
    }
  }

  onQuestionTypeChange(value) {
    if (value == "0") {
      this.showResponses = true;
    } else {
      this.showResponses = false;
    }
  }

  edit() {
    this.question.question = this.questionText;
    this.question.questionType = parseInt(this.questionType);
    this.question.testTypeId = this.testTypeId;

    if (this.question.question == '' || this.question.question == null) {
      this.snotifyService.error("Please enter the question text");
      return;
    }

    if (this.question.questionType == 0) {
      var selectedResponses = this.questionResponses.filter(function (a) {
        return a.required;
      });

      if (selectedResponses.length == 0) {
        this.snotifyService.error("Please select a response");
        return;
      } else if (selectedResponses.length == 1) {
        this.snotifyService.error("Please select at least 2 responses");
        return;
      }

      for (let response of selectedResponses) {
        let testResponse = new TestTypeQuestionResponse();
        testResponse.responseText = response.responseText;

        var currentResponses = this.question.responses.filter(function (a) {
          return a.responseText == testResponse.responseText;
        });

        if (currentResponses.length == 0) {
          testResponse.id = UUID.UUID();
          testResponse.responseText = response.responseText;
          testResponse.testTypeQuestionId = this.question.id;

          this.question.responses.push(testResponse);
        }
      }
    }

    this.question.isEdit = true;
    this.testTypeService.saveQuestion(this.question).then((data) => {
      this.router.navigate(['administration/testtypes/questions'], { queryParams: { testtypeid: this.question.testTypeId } });
    })
  }

  addResponse() {
    let questionResponse = new QuestionResponse();
    questionResponse.responseText = this.questionText;

    this.questionResponseService.checkIfExists(questionResponse).then((data) => {
      this.addQuestionResponse(questionResponse);
      this.bindQuestionResponses();
      this.bindSelectedResponses();
    }, error => {
      this.snotifyService.error(error.error);
      this.responseText = "";
    });
  }

  addQuestionResponse(questionResponse: QuestionResponse) {
    this.questionResponseService.save(questionResponse).then((data) => {
      this.child.bindQuestionResponses();
    });
  }

  goBack() {
    this.location.back();
  }

}
