import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../models/user';

@Injectable()
export class AuthService {
  currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient) { }

  public userLoggedIn() {
    var user = localStorage.getItem("User");
    if (user == null) {
      return false;
    }

    return true;
  }

  public setUser(user) {
    //this.cookieService.set('User', JSON.stringify(user), 1);
    localStorage.setItem("User", JSON.stringify(user));
    this.currentUser.next(user);
  }

  public getUser(): BehaviorSubject<User> {
    if (this.currentUser.value != null) {
      this.currentUser.next(this.currentUser.value);
    }

    var user = localStorage.getItem("User");
    if (user == null) {
      return null;
    }

    //let cookieExists = this.cookieService.check('User');
    //if (!cookieExists) {
    //  return null;
    //}

    this.currentUser.next(<User>JSON.parse(user));
  }

  public logout() {
    this.currentUser.next(null);
    //this.cookieService.delete('User');
    localStorage.removeItem("User");

    this.router.navigate(['/login']);
  }
}
