import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { InspectionService } from '../../../../../services/inspection.service';
import { TestTypeService } from '../../../../../services/test-type.service';
import { TestTypeQuestion } from '../../../../../models/test-type-question';
import { TestType } from '../../../../../models/test-type';
import { Asset } from '../../../../../models/asset';
import { AssetSelectorService } from '../../../../../services/asset-selector.service';
import { L } from '@angular/core/src/render3';

@Component({
  selector: 'app-inspection-step2',
  templateUrl: './inspection-step2.component.html',
  styleUrls: ['./inspection-step2.component.css']
})
export class InspectionStep2Component implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();

  questions: TestTypeQuestion[];
  assets: Asset[];

  cachedQuestion: TestTypeQuestion;
  cachedFailedResponse;
  cachedPassedResponse;

  constructor(private inspectionService: InspectionService, private testTypeService: TestTypeService, private assetSelectorService: AssetSelectorService) {
    var testTypeId = inspectionService.testType.id;
    this.assets = inspectionService.assetsToInspect;
    console.log(this.assets);
    // Load our questions
    this.testTypeService.getQuestions(testTypeId).then((questions) => {
      this.questions = questions;

      // Need to set each assets questions
      for (let asset of this.assets) {
        asset.questions = JSON.parse(JSON.stringify(this.questions));
      }
    });

    this.assetSelectorService.selectedAssets.subscribe((assets) => {
      if (assets == null) return;

      // Set any selected to false, and other any assets to pass

      for (let asset of this.assets) {
        var failedAsset = assets.find(a => a.id == asset.id);
        // If failed asset != null it means it has been selected to fail
        if (failedAsset != null) {
          var assetQuestion = failedAsset.questions.find(a => a.id == this.cachedQuestion.id);
          assetQuestion.selectedResponse = this.cachedFailedResponse;
        } else {
          var assetQuestion = asset.questions.find(a => a.id == this.cachedQuestion.id);
          assetQuestion.selectedResponse = this.cachedPassedResponse;
        }
      }
    });
  }

  ngOnInit() {
  }

  showNext() {
    if (this.questions == null)
      return false;

    for (let question of this.questions) {
      // Need to make sure we have a response
      if (question.selectedResponse == null && question.questionType == 0) {
        return false;
      }
    }

    return true;
  }

  setResponse(question, response) {
    question.selectedResponse = response;
    if (response.responseText.indexOf("Fail") > -1) {
      this.cachedQuestion = question;
      this.cachedFailedResponse = response;
      this.cachedPassedResponse = question.responses.find(a => a.responseText == "Pass");
      this.failQuestion(question, response);
    } else {
      this.passQuestion(question, response);
    }
  }

  passQuestion(question, response) {
    for (let asset of this.assets) {
      var assetQuestion = asset.questions.find(a => a.id == question.id);
      assetQuestion.selectedResponse = response;

      console.log(assetQuestion);
    }
  }

  failQuestion(question, response) {
    if (this.assets.length > 1) {
      this.assetSelectorService.showAssets(this.assets, true);
    } else {
      var asset = this.assets[0];
      var assetQuestion = asset.questions.find(a => a.id == question.id);

      assetQuestion.selectedResponse = response;
    }
  }

  setQuestionComments(question, comments) {
    for (let asset of this.assets) {
      var assetQuestion = asset.questions.find(a => a.id == question.id);
      assetQuestion.selectedComments = comments;
    }
  }

  next() {
    this.completed.emit(this.assets);
  }

  passAll() {
    for (let question of this.questions) {
      var passedResponse = question.responses.find(a => a.responseText == "Pass");
      question.selectedResponse = passedResponse;

      this.passQuestion(question, passedResponse);
    }

    for (let asset of this.assets) {
      for (let question of asset.questions) {
        var passedResponse = question.responses.find(a => a.responseText == "Pass");
        question.selectedResponse = passedResponse;

        console.log(question);

        this.passQuestion(question, passedResponse);
      }
    }
  }
}
