import { TestTypeQuestion } from "./test-type-question";

export class Inspection {
  firstExamination: boolean;
  installedCorrectly: boolean;
  immediateToPersons: boolean;
  becomeDangerToPersons: boolean;
  repairDate: string;
  repair: string;
  particulars: string;

  inspectedAt: string;
  nextInspectionDate: string;
  webUserId: string;
  testTypeId: string;
  currentColourId: string;
  assetId: string;
  newCurrentLocationId: string;

  questions: any[];

  projectId: string;

  isEdit: boolean;
}
