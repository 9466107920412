import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state, stagger } from '@angular/animations';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  animations: [
    trigger('navAnimation', [
      state('close', style({
        opacity: 0,
        transform: 'translateX(-100%)',
        display: 'none'
      })),
      state('open', style({
        opacity: 1,
        transform: 'translateX(0%)',
        display: 'block'
      })),
      transition('close <=> open', animate(120)),
    ])
  ]
})
export class LayoutComponent implements OnInit {
  userRoleId;

  openNavs = [];

  constructor(authService: AuthService) {
    this.userRoleId = authService.currentUser.value.userType;
    console.log(authService.currentUser.value);
  }

  ngOnInit() {
  }

  setNav(nav) {
    if (this.openNavs.includes(nav)) {
      let idx = this.openNavs.indexOf(nav);
      this.openNavs.splice(idx, 1);
      return;
    }

    this.openNavs.push(nav);
  }

  isNavOpen(nav) {
    return this.openNavs.includes(nav);
  }

}
