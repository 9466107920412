import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Carrier } from '../models/carrier';
import { SnotifyService } from 'ng-snotify';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Carrier[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Carrier[]>('/api/carrier/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: String): Promise<Carrier> {
    return new Promise((resolve, reject) => {
      this.http.get<Carrier>('/api/carrier/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(carrier: Carrier): Promise<Carrier> {
    return new Promise((resolve, reject) => {
      if (carrier.isEdit) {
        this.http.put<Carrier>('/api/carrier/put', carrier).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated carrier');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        carrier.id = UUID.UUID();

        this.http.post<Carrier>('/api/carrier/post', carrier).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added carrier');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }
}
