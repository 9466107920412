import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-inspection-step1',
  templateUrl: './inspection-step1.component.html',
  styleUrls: ['./inspection-step1.component.css']
})
export class InspectionStep1Component implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();

  rbExam = "0";
  rbInstalled;

  constructor() { }

  ngOnInit() {
  }

  showNext() {
    if (this.rbExam == "0")
      return true;

    if (this.rbInstalled != null)
      return true;

    return false;
  }

  next() {
    var dto = {
      firstExam: this.rbExam,
      installedCorrectly: this.rbInstalled
    }

    this.completed.emit(dto);
  }
}
