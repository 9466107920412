import { Injectable } from '@angular/core';
import { Asset } from '../models/asset';
import { BehaviorSubject } from 'rxjs';
import { ItemType } from '../models/item-type';
import { ItemTypesService } from './item-types.service';

@Injectable({
  providedIn: 'root'
})
export class ItemTypeSelectorService {

  private itemTypes: BehaviorSubject<ItemType[]>;
  private resolve;

  constructor(private itemTypesService: ItemTypesService) {
    this.itemTypes = new BehaviorSubject<ItemType[]>(null);
  }

  public getNewItemType(itemType: ItemType): Promise<ItemType> {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      var subCategoryId = itemType.subCategoryId;
      if (subCategoryId == null)
        subCategoryId = itemType.subCategory.id;

      this.itemTypesService.getBySubCategory(subCategoryId).then((itemTypes) => {
        this.itemTypes.next(itemTypes);
        //resolve(null);
      });
    });
  }


  getItemTypes() : BehaviorSubject<ItemType[]> {
    return this.itemTypes;
  }

  selectItemType(itemType: ItemType) {
    this.resolve(itemType);
  }

  cancel() {
    this.resolve(null);
  }
}
