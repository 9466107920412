import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { TabletUser } from '../models/tablet-user';
import { UUID } from 'angular2-uuid';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class TabletUserService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<TabletUser[]> {
    return new Promise((resolve, reject) => {
      this.http.get<TabletUser[]>('/api/tabletuser/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: string): Promise<TabletUser> {
    return new Promise((resolve, reject) => {
      this.http.get<TabletUser>('/api/tabletuser/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(tablet: TabletUser): Promise<TabletUser> {
    return new Promise((resolve, reject) => {
      if (tablet.is_edit) {
        this.http.put<TabletUser>('/api/tabletuser/put', tablet).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated tablet user');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        tablet.id = UUID.UUID();
        console.log(tablet);
        this.http.post<TabletUser>('/api/tabletuser/post', tablet).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added tablet user');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public addRegime(regime): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<TabletUser>('/api/tabletuser/postregime', regime).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully added tablet user regime');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public deleteRegime(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete('/api/tabletuser/deleteregime?id=' + id).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully removed tablet user regime');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public authorise(id: string, pass: string): Promise<TabletUser> {
    return new Promise((resolve, reject) => {
      this.http.get<TabletUser>('/api/tabletuser/authorise?id=' + id + '&pass=' + pass).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      });
    });
  }

  public getResults(page: Page): Promise<PagedData<TabletUser>> {
    const pagedData = new PagedData<TabletUser>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Firstname'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }
      this.http.get<ServerData>('/api/tabletuser/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          const tablet = new TabletUser();
          tablet.init(jsonObj.id, jsonObj.firstname, jsonObj.lastname, jsonObj.pin, jsonObj.expired, jsonObj.createdDateFormatted, jsonObj.regimes);
          pagedData.data.push(tablet);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });


  }
}
