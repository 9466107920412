import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { Router } from '@angular/router';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-company-manage',
  templateUrl: './company-manage.component.html',
  styleUrls: ['./company-manage.component.css']
})
export class CompanyManageComponent implements OnInit {

  constructor(public companyService: CompanyService, public router: Router) { }

  ngOnInit() {
  }

  companyAdded(company: Company) {
    this.companyService.save(company).then((data) => {
      //this.router.navigate(['administration/clients']);
    });
  }

}
