import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { TestType } from '../models/test-type';
import { UUID } from 'angular2-uuid';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';
import { TestTypeQuestion } from '../models/test-type-question';

@Injectable({
  providedIn: 'root'
})
export class TestTypeService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public get(id: string): Promise<TestType> {
    return new Promise((resolve, reject) => {
      this.http.get<TestType>('/api/testtype/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAll(): Promise<TestType[]> {
    return new Promise((resolve, reject) => {
      this.http.get<TestType[]>('/api/testtype/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getQuestions(testTypeId: UUID): Promise<TestTypeQuestion[]> {
    return new Promise((resolve, reject) => {
      this.http.get<TestTypeQuestion[]>('/api/testtype/getquestions?testtypeid=' + testTypeId).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getQuestion(id: UUID): Promise<TestTypeQuestion> {
    return new Promise((resolve, reject) => {
      this.http.get<TestTypeQuestion>('/api/testtype/getquestion?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(type: TestType): Promise<TestType> {
    return new Promise((resolve, reject) => {
      if (type.isEdit) {
        this.http.put<TestType>('/api/testtype/put', type).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated test type');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        type.id = UUID.UUID();

        this.http.post<TestType>('/api/testtype/post', type).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added test type');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public saveQuestion(question: TestTypeQuestion) {
    console.log(question);

    return new Promise((resolve, reject) => {
      if (question.isEdit) {
        this.http.put<TestType>('/api/testtype/putquestion', question).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated test type question');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        this.http.post<TestTypeQuestion>('/api/testtype/postquestion', question).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added test type question');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public saveQuestions(testTypeId: UUID, questions: TestTypeQuestion[]): Promise<TestTypeQuestion[]> {
    for (const question of questions) {
      if (question.id == '' || question.id == null) {
        question.id = UUID.UUID();
      }

      question.testTypeId = testTypeId;

      if (question.responses.length > 0) {
        for (const response of question.responses) {
          if (response.id == '' || response.id == null) {
            response.id = UUID.UUID();
          }

          response.testTypeQuestionId = question.id;
        }
      }
    }

    return new Promise((resolve, reject) => {
      this.http.post<TestTypeQuestion[]>('/api/testtype/postquestions', questions).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully updated test type questions');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getResults(page: Page, name: string): Promise<PagedData<TestType>> {
    const pagedData = new PagedData<TestType>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Type'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&type=' + name;

      this.http.get<ServerData>('/api/testtype/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });


  }
}
