import { Component, OnInit } from '@angular/core';
import { CurrentLocation } from 'src/app/models/current-location';
import { Page } from 'src/app/models/paging/page';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})

export class LocationsComponent implements OnInit {
  filter: any = {
    name: ""
  }

  page = new Page();
  rows = Array<CurrentLocation>();

  columns = [
    { name: 'Name', prop: 'name' },
    { name: 'Active', prop: 'expired' },
    { name: 'Quarantine', prop: 'isQuarantine' },
    { name: 'Option', prop: '' },
  ]

  constructor(public currentLocationService: CurrentLocationService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
  }

  edit(row) {
    this.router.navigate(['administration/locations/manage'], { queryParams: { id: row.id } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == "")
      return false;

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.currentLocationService.getResults(this.page, this.filter.name).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

}
