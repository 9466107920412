import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { Manufacturer } from '../models/manufacturer';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ServerData } from '../models/paging/server-data';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Manufacturer[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Manufacturer[]>("/api/manufacturer/getall").subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: Number): Promise<Manufacturer> {
    return new Promise((resolve, reject) => {
      this.http.get<Manufacturer>("/api/manufacturer/get?id=" + id).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(manufacturer: Manufacturer): Promise<Manufacturer> {
    return new Promise((resolve, reject) => {
      if (manufacturer.isEdit) {
        this.http.put<Manufacturer>("/api/manufacturer/put", manufacturer).subscribe(data => {
          resolve(data);
          this.snotifyService.success("Successfully updated manufacturer");
        }, error => {
          console.log(error);
          reject();
        });
      }
      else {
        manufacturer.id = UUID.UUID();

        this.http.post<Manufacturer>("/api/manufacturer/post", manufacturer).subscribe(data => {
          resolve(data);
          this.snotifyService.success("Successfully added manufacturer");
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }


  public getResults(page: Page, name: string): Promise<PagedData<Manufacturer>> {
    const pagedData = new PagedData<Manufacturer>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == "") { page.sortBy = "Name"; }
      if (page.sortOrder == undefined || page.sortOrder == "") { page.sortOrder = "asc"; }

      var params = "&name=" + name;

      this.http.get<ServerData>("/api/manufacturer/gettable?size=" + page.size + "&pageNumber=" + page.pageNumber + "&sortBy=" + page.sortBy + "&sortOrder=" + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          let manufacturer = new Manufacturer();
          manufacturer.init(jsonObj.id, jsonObj.name, jsonObj.expired);
          pagedData.data.push(manufacturer);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
