import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { Accessory } from '../models/accessory';

import { UUID } from 'angular2-uuid';
import { ServerData } from '../models/paging/server-data';

import { SnotifyService } from 'ng-snotify';
import { SubCategory } from '../models/sub-category';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<SubCategory[]> {
    return new Promise((resolve, reject) => {
      this.http.get<SubCategory[]>('/api/subcategory/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: String): Promise<SubCategory> {
    return new Promise((resolve, reject) => {
      this.http.get<SubCategory>('/api/subcategory/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public save(category: SubCategory): Promise<SubCategory> {
    return new Promise((resolve, reject) => {
      if (category.isEdit) {
        this.http.put<SubCategory>('/api/subcategory/put', category).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated sub category');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        category.id = UUID.UUID();

        this.http.post<SubCategory>('/api/subcategory/post', category).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added sub category');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }

  public getResults(page: Page, categoryId: string, name: string): Promise<PagedData<SubCategory>> {
    const pagedData = new PagedData<SubCategory>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&categoryId=' + categoryId;
      params += '&name=' + name;

      this.http.get<ServerData>('/api/subcategory/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
