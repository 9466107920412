import { Component, OnInit } from '@angular/core';
import { CarrierService } from 'src/app/services/carrier.service';
import { Router } from '@angular/router';
import { Carrier } from 'src/app/models/carrier';

@Component({
  selector: 'app-carrier-manage',
  templateUrl: './carrier-manage.component.html',
  styleUrls: ['./carrier-manage.component.css']
})
export class CarrierManageComponent implements OnInit {

  constructor(public carrierService: CarrierService, public router: Router) { }

  ngOnInit() {
  }

  carrierAdded(carrier: Carrier) {
    this.carrierService.save(carrier).then((data) => {
      //this.router.navigate(['administration/clients']);
    });
  }

}
