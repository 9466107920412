import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Supplier } from '../models/supplier';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { ServerData } from '../models/paging/server-data';
import { SnotifyService } from 'ng-snotify';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Supplier[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Supplier[]>('/api/supplier/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: Number): Promise<Supplier> {
    return new Promise((resolve, reject) => {
      this.http.get<Supplier>("/api/supplier/get?id=" + id).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getResults(page: Page, name: string): Promise<PagedData<Supplier>> {
    const pagedData = new PagedData<Supplier>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == "") { page.sortBy = "Name"; }
      if (page.sortOrder == undefined || page.sortOrder == "") { page.sortOrder = "asc"; }

      var params = "&name=" + name;

      this.http.get<ServerData>("/api/supplier/gettable?size=" + page.size + "&pageNumber=" + page.pageNumber + "&sortBy=" + page.sortBy + "&sortOrder=" + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          let supplier = new Supplier();
          supplier.init(jsonObj.id, jsonObj.name, jsonObj.expired);
          pagedData.data.push(supplier);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public save(supplier: Supplier): Promise<Supplier> {
    return new Promise((resolve, reject) => {
      if (supplier.isEdit) {
        this.http.put<Supplier>("/api/supplier/put", supplier).subscribe(data => {
          resolve(data);
          this.snotifyService.success("Successfully updated supplier");
        }, error => {
          console.log(error);
          reject();
        });
      }
      else {
        supplier.id = UUID.UUID();

        this.http.post<Supplier>("/api/supplier/post", supplier).subscribe(data => {
          resolve(data);
          this.snotifyService.success("Successfully added supplier");
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }
}
