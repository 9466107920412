import { Component, OnInit } from '@angular/core';
import { DivisionService } from 'src/app/services/division.service';
import { Router } from '@angular/router';
import { Division } from 'src/app/models/division';

@Component({
  selector: 'app-division-manage',
  templateUrl: './division-manage.component.html',
  styleUrls: ['./division-manage.component.css']
})
export class DivisionManageComponent implements OnInit {

  constructor(public divisionService: DivisionService, public router: Router) { }

  ngOnInit() {
  }

  divisionAdded(division: Division) {
    this.divisionService.save(division).then((data) => {
      this.router.navigate(['administration/divisions']);
    });
  }
}
