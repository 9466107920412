export class CurrentLocation {
  id: string;
  name: string;
  expired: boolean = false;
  isQuarantine: boolean;
  isEdit: boolean = false;

  init(id: string, name: string, expired: boolean) {
    this.id = id;
    this.name = name;
    this.expired = expired;
    this.isQuarantine = this.isQuarantine ? true : false;
  }

}
