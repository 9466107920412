import { Component, OnInit, EventEmitter, Output, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { CurrentColourService } from '../../../../../services/current-colour.service';
import { CurrentColour } from '../../../../../models/current-colour';
import * as moment from 'moment';
import { InspectionService } from '../../../../../services/inspection.service';
import { CurrentLocation } from '../../../../../models/current-location';
import { CurrentLocationService } from '../../../../../services/current-location.service';
import { Asset } from '../../../../../models/asset';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-inspection-step4',
  templateUrl: './inspection-step4.component.html',
  styleUrls: ['./inspection-step4.component.css']
})
export class InspectionStep4Component implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() immediateToPersons: boolean;

  @ViewChild('ngSelectQuarantine') ngSelectQuarantine: NgSelectComponent;
  @ViewChild('ngSelectStock') ngSelectStock: NgSelectComponent;
  @ViewChild('selectStockWrapper') selectStockWrapper: ElementRef;
  @ViewChild('selectQuarantineWrapper') selectQuarantineWrapper: ElementRef;
  

  projectId: string = null;

  colours: CurrentColour[];
  quarantineLocations: CurrentLocation[];
  stockLocations: CurrentLocation[];

  failedAssets: Asset[] = [];
  passedAssets: Asset[] = [];


  selectedColourId: string;
  inspectionDate = moment().toDate();
  failedInspectionDate = moment().toDate();
  passedInspectionDate;

  quarantineLocationId: string;
  stockLocationId: string;
  

  constructor(private currentColourService: CurrentColourService, private inspectionService: InspectionService, private currentLocationService: CurrentLocationService) {
    this.projectId = this.inspectionService.projectId;

    this.currentColourService.getAll().then((colours) => {
      this.colours = colours;
    });

    this.currentLocationService.getAll().then((locations) => {
      this.quarantineLocations = locations.filter(a => a.isQuarantine);
      this.stockLocations = locations.filter(a => !a.isQuarantine);
    });

    this.passedInspectionDate = moment().add(this.inspectionService.testType.interval, 'months').toDate();

    var assets = this.inspectionService.assetsToInspect;
    for (let asset of assets) {
      console.log(asset);
      if (asset.questions.filter(a => a.selectedResponse != null && a.selectedResponse.responseText == "Fail").length > 0) {
        console.log("is fail");
        this.failedAssets.push(asset);
      } else {
        console.log("is pass");
        this.passedAssets.push(asset);
      }
    }
  }

  ngOnInit() {
    
  }



  showSave() {
    
    if (this.selectedColourId == null)
      return false;

    if (this.projectId == null || this.projectId == "") {
      //if (this.failedAssets.length > 0 && this.quarantineLocationId == null)
      //  return false;

      //if (this.passedAssets.length > 0 && this.stockLocationId == null)
      //  return false;
      if (this.passedAssets.length > 0 && (this.stockLocationId == null && this.quarantineLocationId == null))
        return false;
      if (this.failedAssets.length > 0 && (this.stockLocationId == null && this.quarantineLocationId == null))
        return false;
    }

    return true;
  }

  save() {
    var assets = [];
    assets = assets.concat(this.passedAssets);
    assets = assets.concat(this.failedAssets);

    //if (this.stockLocationId == null || this.stockLocationId == undefined) {
    //  this.failedAssets = this.passedAssets;
    //  this.passedAssets = [];
    //}

    var dto = {
      currentColourId: this.selectedColourId,
      inspectionDate: this.inspectionDate,
      failedInspectionDate: this.failedInspectionDate,
      passedInspectionDate: this.passedInspectionDate,

      quarantineLocationId: this.quarantineLocationId,
      stockLocationId: this.stockLocationId,


      failedAssets: (this.stockLocationId == null || this.stockLocationId == undefined) ? assets : [],
      passedAssets: (this.stockLocationId != null) ? assets : []
      //failedAssets: this.failedAssets,
      //passedAssets: this.passedAssets
    }

    this.completed.emit(dto);
  }

  onStockLocationChange() {
    if (this.stockLocationId == null) return;
    this.ngSelectQuarantine.handleClearClick();
    this.selectQuarantineWrapper.nativeElement.className = 'location-changed';
    setTimeout(() => {
      this.selectQuarantineWrapper.nativeElement.className = null;
    }, 2000);
  }

  onQuarantineLocationChange() {
    if (this.quarantineLocationId == null) return;
    this.ngSelectStock.handleClearClick();
    this.selectStockWrapper.nativeElement.className = 'location-changed';
    setTimeout(() => {
      this.selectStockWrapper.nativeElement.className = null;
    }, 2000);
  }
}
