import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-inspection-step3',
  templateUrl: './inspection-step3.component.html',
  styleUrls: ['./inspection-step3.component.css']
})
export class InspectionStep3Component implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();

  rbImmediate = '0';
  rbDanger = '0';
  repairDate;
  repair;
  particulars;

  constructor() { }

  ngOnInit() {
  }

  showNext() {
    if (this.rbImmediate == '0') {
      if (this.rbDanger == null) {
        return false;
      }

      if (this.rbDanger == '1' && (this.repair == null || this.repair == '')) {
        return false;
      }

      if (this.rbDanger == '1' && (this.repair == null || this.repair == '')) {
        return false;
      }
    }
    return true;
  }

  next() {
    var dto = {
      immediateToPersons: this.rbImmediate,
      becomeDangerToPersons: this.rbDanger,
      repairDate: this.repairDate,
      repair: this.repair,
      particulars: this.particulars
    }


    this.completed.emit(dto);
  }
}
