import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProjectService } from '../../../../services/project.service';
import { Picklist } from '../../../../models/picklist';
import { ActivatedRoute, Router } from '@angular/router';
import { PicklistService } from '../../../../services/picklist.service';
import { ItemType } from '../../../../models/item-type';
import _ from "lodash";
import { NonNumberedAsset } from '../../../../models/non-numbered-asset';
import { AssetService } from '../../../../services/asset.service';
import { Asset } from '../../../../models/asset';
import { PickListAssetViewModel } from '../../../../models/picklist-asset-view-model';
import { AuthService } from '../../../../services/auth.service';
import { AssetSelectorService } from '../../../../services/asset-selector.service';
import * as moment from 'moment';
import { ItemTypeSelectorService } from '../../../../services/item-type-selector.service';
import { SubCategory } from '../../../../models/sub-category';
import { detectChanges } from '@angular/core/src/render3';
import { PickListAsset } from '../../../../models/picklist-asset';
import { UUID } from 'angular2-uuid';
import { Category } from '../../../../models/category';
import { SubCategoryService } from '../../../../services/sub-category.service';
import { NonNumberedAssetService } from '../../../../services/non-numbered-asset.service';
import { SnotifyService } from 'ng-snotify';
import { User } from '../../../../models/user';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { CurrentLocation } from 'src/app/models/current-location';
import { CategoryService } from 'src/app/services/category.service';
import { isNumber } from 'util';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-select-picklist',
  templateUrl: './select-picklist.component.html',
  styleUrls: ['./select-picklist.component.css']
})
export class SelectPicklistComponent implements OnInit {
  @ViewChild("searchInput") searchInput: ElementRef;
  picklist: Picklist;

  allItemTypes = [];
  itemTypes = [];
  categories = [];
  allCategories = [];
  menuCategories = [];
  chosenLocationId = undefined;
  currentLocations: CurrentLocation[];

  locationLocked = false;
  searchCategories: "";
  selectedItemType: ItemType;
  itemStatus = "-1";
  sortStatus = "0";
  //assetList: Asset[] = [];
  assets: Asset[] = [];

  searchQuery: string = "";
  userRoleId: number;

  groupedPicklistAssets = [];

  constructor(private picklistService: PicklistService, public route: ActivatedRoute, private assetService: AssetService, private router: Router, private authService: AuthService,
    private assetSelectorService: AssetSelectorService, private itemTypeSelectorService: ItemTypeSelectorService, private nonNumberedAssetService: NonNumberedAssetService,
    private snotifyService: SnotifyService, private currentLocationService: CurrentLocationService, private subCategoryService: SubCategoryService, private categoryService: CategoryService,
    private confirmationService: ConfirmationService) {
    this.userRoleId = authService.currentUser.value.userType;

    this.currentLocationService.getAll().then((data) => {
      this.currentLocations = data;
    });
  }


  async ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get("id");
      this.picklistService.get(id).then((picklist) => {
        this.picklist = picklist;

        //add categories for NAs
        for (let asset of picklist.picklistAssets) {
          this.addCategoriesData(asset, true);
        }

        //add categories for NNAs
        for (let asset of picklist.picklistNonNumberedAssets) {
          this.addCategoriesData(asset, false);
        }

        //If this picklist has a non-numbered location, we need to set that and lock it.
        if (picklist.nonNumberedLocation != null) {
          this.locationLocked = true;
          this.chosenLocationId = picklist.nonNumberedLocation;
        }

        this.populateMenuCategories();

        this.groupedPicklistAssets = [];

        // Group the picklist assets by itemtype
        this.groupPicklistAssets(picklist.picklistAssets, true);
        this.groupPicklistAssets(picklist.picklistNonNumberedAssets, false);

        // Sort the grouped picklists
        this.groupedPicklistAssets.sort((a, b) => a.name.localeCompare(b.name));
      });
    })
  }

  setCAN() {
    this.searchQuery = "CAN";
    this.searchInput.nativeElement.focus();
  }

  addCategoriesData(asset, isNumbered) {
    //If somehow the subCategory hasn't been set, we need to set it so the category won't fail
    if (asset.itemType.subCategory == null) {
      this.subCategoryService.get(asset.itemType.subCategoryId).then(sub => {
        this.categoryService.get(sub.categoryId).then(cat => {
          asset.itemType.subCategory = sub;
          asset.itemType.subCategory.category = cat;

          this.loadCategories(asset, isNumbered);
        });
      });
    }
    else {
      this.loadCategories(asset, isNumbered)
    }
  }

  loadCategories(asset, isNumbered) {
    let category = this.categories.find(a => a.id == asset.itemType.subCategory.category.id);
    if (category == null || category == "undefined") {
      category = asset.itemType.subCategory.category;
      category.expanded = false;
      this.categories.push(category);
    }

    if (category.subCategories == null) {
      category.subCategories = [];
    }

    let subCategory = null;

    var exists = false;
    for (var i = 0; i < category.subCategories.length; i++) {
      var subcat = category.subCategories[i];

      if (subcat.id == asset.itemType.subCategory.id && isNumbered == subcat.isNumbered) {
        exists = true;
        subCategory = subcat;
      }
    }

    if (!exists) {
      subCategory = asset.itemType.subCategory;
      subCategory.isNumbered = isNumbered;

      if (isNumbered) {
        var assetcount = 0;

        var assets = this.picklist.picklistAssets.filter(a => a.itemType.subCategory.id == asset.itemType.subCategory.id);
        if (assets.length > 0) {

          var nonReplacedAssets = assets.filter(a => a.replacedByItemTypeId == null);
          if (nonReplacedAssets.length > 0) {
            assetcount += nonReplacedAssets.map(value => value.quantity).reduce((sum, x) => sum + x);
          }

          subCategory.type = "Numbered";
          subCategory.assetCount = assetcount;
          subCategory.currentAssetCount = 0;
          category.subCategories.push(subCategory);
        }

      }
      else {
        var nnacount = 0;

        var nonNumberedAssets = this.picklist.picklistNonNumberedAssets.filter(a => a.itemType.subCategoryId == asset.itemType.subCategory.id);
        if (nonNumberedAssets.length > 0) {
          nnacount += nonNumberedAssets.map(value => value.quantity).reduce((sum, x) => sum + x);

          subCategory.type = "Non-Numbered";
          subCategory.isNumbered = isNumbered;
          subCategory.assetCount = nnacount;
          subCategory.currentAssetCount = 0;
          category.subCategories.push(subCategory)
        }
      }

      if (isNumbered)
        subCategory.itemTypes = this.addItemTypes(subCategory, true);

      else
        subCategory.itemTypes = this.addItemTypes(subCategory, false);
    }
  }

  addItemTypes(subCategory, isNumbered) {
    if (isNumbered) {
      var newAssets = [];
      var assets = this.picklist.picklistAssets.filter(a => a.itemType.subCategoryId == subCategory.id);
      for (let asset of assets) {
        for (let i = 0; i < asset.quantity; i++) {
          // Needed for sorting
          if (asset.itemType.warehouseLocation == null) asset.itemType.warehouseLocation = "";

          var itemType = {
            numbered: true,

            picklistAssetId: asset.id,
            id: asset.itemType.id,
            subCategory: asset.itemType.subCategory,
            name: asset.itemType.name,
            additionalName: asset.itemType.additionalName,
            warehouseLocation: asset.itemType.warehouseLocation,
            selectedAsset: null,
          }


          this.allItemTypes.push(itemType);
          newAssets.push(itemType);
        }
      }
      return newAssets;
    }
    else {
      var newAssets = [];
      var nonNumberedAssets = this.picklist.picklistNonNumberedAssets.filter(a => a.itemType.subCategoryId == subCategory.id);
      for (let asset of nonNumberedAssets) {
        // Needed for sorting
        if (asset.itemType.warehouseLocation == null) asset.itemType.warehouseLocation = "";

        var nonNumbereditemType = {
          numbered: false,

          id: asset.itemType.id,
          subCategory: asset.itemType.subCategory,
          name: asset.itemType.name,
          additionalName: asset.itemType.additionalName,
          warehouseLocation: asset.itemType.warehouseLocation,
          quantity: asset.quantity,
          selectedQuantity: 0,
        }

        newAssets.push(nonNumbereditemType);
      }
      return newAssets;
    }
  }

  populateMenuCategories() {
    var allCat = new Category();
    allCat.init("-1", "ALL ITEMS", false);
    for (let category of this.categories) {
      category.subCategories = category.subCategories.sort((a, b) => a.name.localeCompare(b.name));
      for (let subCategory of category.subCategories) {
        subCategory.itemTypes = subCategory.itemTypes.sort((a, b) => a.name.localeCompare(b.name));
      }
    }

    this.menuCategories = this.categories.sort((a, b) => a.name.localeCompare(b.name));
    this.menuCategories.unshift(allCat);
    this.allCategories = this.menuCategories;
  }

  groupPicklistAssets(assets, isNumbered) {

    for (var picklistAsset of assets.sort((a, b) => a.itemTypeId.localeCompare(b.itemTypeId))) {
      const category = picklistAsset.itemType.subCategory.category;
      const subCategory = picklistAsset.itemType.subCategory;
      const itemType = picklistAsset.itemType;

      var groupedCategory = this.groupedPicklistAssets.find(g => g.id == category.id);
      if (groupedCategory == null) {
        groupedCategory = {
          id: category.id,
          name: category.name,
          subCategories: []
        };

        this.groupedPicklistAssets.push(groupedCategory);
      }

      var groupedSubCategory = groupedCategory.subCategories.find(g => g.id == subCategory.id && g.isNumbered == isNumbered);
      if (groupedSubCategory == null) {
        groupedSubCategory = {
          id: subCategory.id,
          name: subCategory.name,
          isNumbered: isNumbered,
          type: isNumbered ? 'Numbered' : 'Non-Numbered',
          itemTypes: []
        }

        groupedCategory.subCategories.push(groupedSubCategory);
      }

      var groupedItemType = groupedSubCategory.itemTypes.find(g => g.id == itemType.id);
      if (groupedItemType == null) {
        groupedItemType = {
          id: itemType.id,
          name: itemType.name,
          warehouseLocation: itemType.warehouseLocation,
          numbered: itemType.numbered,
          picklistAssets: [],
          quantity: picklistAsset.quantity,
          selectedQuantity: 0,
          replacedByItemTypeId: picklistAsset.replacedByItemTypeId,
          replacedByItemType: picklistAsset.replacedByItemType,
          notes: picklistAsset.notes,

          statusNotes: picklistAsset.statusNotes,
          notRequired: picklistAsset.notRequired
        }

        if (picklistAsset.picklistPickedAsset != null) {
          picklistAsset.statusNotes = picklistAsset.picklistPickedAsset.statusNotes;
          picklistAsset.notRequired = picklistAsset.picklistPickedAsset.notRequired;
        }


        if (!itemType.numbered) {
          itemType.notes = picklistAsset.notes;

  
          // Update this item type from picked assets if its been set already
          var pickedItemType = this.picklist.picklistPickedAssets.find(i => i.itemTypeId == itemType.id);
          if (pickedItemType != null) {
            groupedItemType.selectedQuantity = pickedItemType.nonNumberedQuantity;
          }
        }

        groupedSubCategory.itemTypes.push(groupedItemType);
      }

      groupedItemType.picklistAssets.push(picklistAsset);
    }
  }

  private assetSubscription: any;
  searchAsset(query) {
    this.assetService.search(query.trim(), null, null, null, null, null).then((assets) => {
      if (assets.length == 0) {
        this.searchQuery = "";
        this.snotifyService.error("No asset found with: " + query);
        return;
      }

      // We may want to do something differently if this returns more than 1 result.
      // We are assuming both RFID and CAN Number will be unique.
      if (assets.length > 1) {
        this.assetSubscription = this.assetSelectorService.selectedAssets.subscribe((assets) => {
          if (assets == null || assets.length == 0)
            return;

          
          this.processAsset(assets[0]);
          this.assetSelectorService.nullifySelectedAssets();
          this.assetSubscription.unsubscribe();
        });

        this.assetSelectorService.showAssets(assets);
        this.searchQuery = "";
        return;
      }
      this.searchQuery = "";
      var asset = assets[0];
      this.processAsset(asset);
    });
  }

  exchange(picklistAsset) {
    this.itemTypeSelectorService.getNewItemType(picklistAsset.itemType).then((newItemType) => {
      picklistAsset.replacedByItemType = newItemType;
      picklistAsset.replacedByItemTypeId = newItemType.id;
      newItemType.quantity = 0;
    })
  }

  exchangeNonNumbered(subCategory, itemType) {
    itemType.subCategory = subCategory;
    itemType.subCategoryId = subCategory.id;
    this.itemTypeSelectorService.getNewItemType(itemType).then((newItemType) => {
      itemType.replacedByItemType = newItemType;
      itemType.replacedByItemTypeId = newItemType.id;
    })
  }

  validateAsset(asset) {


    ///check if type is in list?
    var exists: boolean = false;
    var dupe: boolean = false;
    for (var category of this.groupedPicklistAssets) {
      for (var subCategory of category.subCategories) {
        for (var itemType of subCategory.itemTypes) {
          for (var picklistAsset of itemType.picklistAssets) {
            if (picklistAsset.picklistPickedAsset != null && picklistAsset.picklistPickedAsset.assetId == asset.id)
              dupe = true;

            if ((picklistAsset.picklistPickedAsset == null || picklistAsset.picklistPickedAsset == undefined) &&
              (picklistAsset.replacedByItemTypeId == undefined && picklistAsset.itemTypeId == asset.itemTypeId) || (picklistAsset.replacedByItemTypeId != undefined && picklistAsset.replacedByItemTypeId == asset.itemTypeId))
              exists = true;
          }
        }
      }
    }
    if (dupe) {
      this.snotifyService.error(asset.canNumber + " has already been picked");
      return false;
    }
    if (!exists) {
      this.snotifyService.error("This picklist does not require a " + asset.itemTypeName);
      return false;
    }

    if (asset.nexInspectionDate != undefined && !asset.nextInspectionDate) {
      this.snotifyService.error("This asset has not been inspected");
      return false;
    }

    if (asset.currentLocation.isQuarantine) {
      this.snotifyService.error("This asset can not be booked out from a qurantine location.");
      return false;
    }

    var certDates = asset.certDates;

    for (let certDate of certDates) {
      if (certDate.inspectionExists == false) {
        this.snotifyService.error("This asset has no valid inspection for regime: " + certDate.testType);
        return false;
      }
    }

    if (certDates.length == 0) {
      this.snotifyService.error("This asset has no valid inspections under any regime");
      return false;
    } else {
      var collectionDate = moment(this.picklist.collectionDate);

      var projectEnd = moment(this.picklist.collectionDate);
      var projectDuration = parseInt(this.picklist.jobDurationNumber);
      var days = 0;

      if (this.picklist.jobDurationInterval.indexOf("day") > -1) {
        projectEnd = projectEnd.add(projectDuration, 'days');
        days = projectDuration;
      }
      else if (this.picklist.jobDurationInterval.indexOf("week") > -1) {
        projectEnd = projectEnd.add(projectDuration, 'weeks');
        days = projectDuration * 7;
      }
      else {
        projectEnd = projectEnd.add(projectDuration, 'months');
        days = projectDuration * 30;
      }

      for (let certDate of certDates) {

        if (certDate.nextDate != null) {

          var nextDate = moment(certDate.nextDate);
          var isBeforeCollection = nextDate.isBefore(collectionDate);

          if (isBeforeCollection) {
            this.snotifyService.error("This asset's '" + certDate.testType + "' cert expires before the collection date.");
            return false;
          }
          else {
            var projEndDate = moment(projectEnd);
            if (nextDate.isBefore(projEndDate)) {
              if (!confirm("This asset's '" + certDate.testType + "' cert expires before the project end date.")) {
                return false;
              }
            }
          }
        }
        else {
          this.snotifyService.error("This asset has not been expected under regime '" + certDate.testType + "'");
          return false;
        }

      }
    }

    if (asset.currentProjectId != null) {
      this.snotifyService.error("This asset is not in stock. It is currently being used in Project:  " + asset.projectName);
      return false;
    }

    if (asset.safeForUse != null && !asset.safeForUse) {
      this.snotifyService.error("This asset is not safe for use. It is currently in: " + asset.locationName);
      return false;
    }

    var nextInspectionDate = moment(asset.nextInspectionDate);
    var collectionDate = moment(this.picklist.collectionDate);

    if (nextInspectionDate.isBefore(collectionDate)) {
      this.snotifyService.error("This asset expires before collection date.");
      return false;
    }

    return true;
  }

  processAsset(asset) {
    if (!this.validateAsset(asset)) {
      return;
    }

    // Find an picklist asset that has no picked asset
    for (var category of this.groupedPicklistAssets) {
      for (var subCategory of category.subCategories) {
        for (var itemType of subCategory.itemTypes) {
          for (var picklistAsset of itemType.picklistAssets) {
            var itemTypeIdToCompare = picklistAsset.itemTypeId;
            if (picklistAsset.replacedByItemType != null) {
              itemTypeIdToCompare = picklistAsset.replacedByItemType.id;
            }

            if (asset.itemTypeId == itemTypeIdToCompare && picklistAsset.picklistPickedAsset == null) {
              picklistAsset.picklistPickedAsset = {
                id: null,
                itemTypeId: itemType.id,
                quantity: 1,
                notes: "",
                numbered: itemType.numbered,
                assetId: asset.id,
                asset: asset,
                picklistAssetId: picklistAsset.id
              }

              if (picklistAsset.replacedByItemType != null)
                picklistAsset.picklistPickedAsset.replacedByItemTypeId = picklistAsset.replacedByItemType.id;

              return;
            }
          }
        }
      }
    }
  }

  async isValid() {
    var msg = undefined;
    for (var groupedPicklist of this.groupedPicklistAssets) {
      for (var subCategory of groupedPicklist.subCategories) {
        if (subCategory.isNumbered) continue;

        for (var itemType of subCategory.itemTypes) {
          if (itemType.selectedQuantity < 0) {
            msg = subCategory.name + ' - ' + subCategory.type + " (" + itemType.name + ") cannot be negative.";
            break;
          }
        }
      }
    }

    return msg;
  }

  async cancel() {
    this.confirmationService.confirm({
      message: "Are you sure you want to cancel this picklist?",
      accept: () => {
        var picklistToDelete = new Picklist();
        picklistToDelete.id = this.picklist.id;
        this.picklistService.cancel(picklistToDelete).then(() => {
          this.router.navigate(['stores/active-picklists']);
        });
      }
    })
  }

  async save(userCompletion: boolean, ignorePickedCount: boolean) {
    console.log("SAVE");
    if (userCompletion) {
      this.confirmationService.confirm({
        message: "Are you sure you want to complete this picklist?",
        accept: async () => {
          await this.savePicklist(userCompletion, ignorePickedCount);
        }
      })
    } else {
      await this.savePicklist(userCompletion, ignorePickedCount);
    }
  }

  async savePicklist(userCompletion: boolean, ignorePickedCount: boolean) {
    var msg = await this.isValid();
    if (msg != undefined) {
      this.snotifyService.error(msg);
      return;

    }

    if (this.chosenLocationId == undefined || this.chosenLocationId == 0) {
      if (this.picklist.picklistNonNumberedAssets.length != 0) {
        this.snotifyService.error("Please Select A Storage Location");
        return;
      }
    }


    var allPicklistAssets = [];
    var picklistStatusAssets = [];
    for (var category of this.groupedPicklistAssets) {
      for (var subCategory of category.subCategories) {
        for (var itemType of subCategory.itemTypes) {

          if (itemType.numbered) {
            for (var picklistAsset of itemType.picklistAssets) {
              if (picklistAsset.picklistPickedAsset != null) {
                picklistAsset.picklistPickedAsset.asset = null;
                picklistAsset.picklistPickedAsset.numbered = itemType.numbered;
                picklistAsset.picklistPickedAsset.picklistAssetId = picklistAsset.id;
                if (!picklistAsset.notRequired) {
                  picklistAsset.picklistPickedAsset.notRequired = picklistAsset.notRequired;
                  picklistAsset.picklistPickedAsset.statusNotes = picklistAsset.statusNotes;
                }

                allPicklistAssets.push(picklistAsset.picklistPickedAsset);
              } else if ((picklistAsset.statusNotes != null && picklistAsset.statusNotes != "") || picklistAsset.notRequired) {
                picklistStatusAssets.push({
                  id: picklistAsset.id,
                  notRequired: picklistAsset.notRequired,
                  statusNotes: picklistAsset.statusNotes
                })
              }
              //else {
              //  picklistAsset.statusNotes = picklistAsset.statusNotes;
              //  picklistAsset.notRequired = picklistAsset.notRequired;
              //  allPicklistAssets.push(picklistAsset.picklistPickedAsset);
              //}
            }
          } else {
            for (var picklistAsset of itemType.picklistAssets) {
              allPicklistAssets.push({
                numbered: false,
                quantity: itemType.selectedQuantity,
                maxQuantity: itemType.quantity,
                itemTypeId: picklistAsset.itemTypeId,
                replacedByItemTypeId: itemType.replacedByItemTypeId,
                statusNotes: itemType.statusNotes,
                notRequired: itemType.notRequired
              })
            }
          }
        }
      }
    }

    var picklist = {
      id: this.picklist.id,
      userId: this.authService.currentUser.value.id,
      userCompletion: userCompletion,
      chosenLocationId: this.chosenLocationId,
      allPicklistAssets: allPicklistAssets,
      picklistStatusAssets: picklistStatusAssets
    }

    //var numbered = 0;
    //var non_numbered = 0;
    //numbered = picklist.allPicklistAssets.filter(a => a.numbered).length;
    //non_numbered = picklist.allPicklistAssets.filter(a => !a.numbered).length;

    //var picked_non_numbered = (picklist.allPicklistAssets.filter(a => !a.numbered).map((asset) => asset.quantity).reduce((x, y) => x + y, 0));

    //if (picklist.allPicklistAssets.length == 0 && !ignorePickedCount) {
    //  this.snotifyService.error("Nothing has been picked");
    //  return;
    //} else if (numbered == 0 && picked_non_numbered == 0) {
    //  this.snotifyService.error("Nothing has been picked");
    //  return;

    //}

    //else {
    //  for (let asset of picklist.allPicklistAssets.filter(a => !a.numbered)) {
    //    var itemTypeId = asset.itemTypeId;
    //    if (asset.replacedByItemTypeId != null) {
    //      itemTypeId = asset.replacedByItemTypeId;
    //    }

    //    if (asset.quantity > 0) {
    //      var nonNumberedAsset = null;
    //      try {
    //        nonNumberedAsset = await this.nonNumberedAssetService.getByItemTypeV2(itemTypeId, this.chosenLocationId);
    //        console.log(nonNumberedAsset);
    //      } catch (e) {
    //        this.snotifyService.error(e);
    //        return;
    //      }

    //      asset.id = nonNumberedAsset.id;


    //      if (asset.quantity > nonNumberedAsset.quantity || asset.quantity > asset.maxQuantity) {
    //        this.snotifyService.error("Quantity exceeds requested, or exceeds stock: " + nonNumberedAsset.itemType.name);
    //        return;
    //      }
    //    }
    //  }
    //}

    console.log(userCompletion);
    this.picklistService.savePicklistAssetsV2(picklist).then((data) => {
      console.log("SUCCESFULLY SAVED")
      if (userCompletion) {
        console.log("COMPLETING");
        this.picklistService.completePicklistAssetsV2(picklist).then((data) => {
          this.router.navigate(['stores/active-picklists']);
        });
      } else {
        this.router.navigate(['stores/active-picklists']);
      }
    }, (error) => {
      this.snotifyService.error(error.error);
    });
  }

  //getFilteredCategories() {
  //  const filteredCategories = this.menuCategories.filter(m => m.isSelected);
  //  if (filteredCategories.length > 0) return filteredCategories;

  //  return this.menuCategories;
  //}

  resetCategories() {
    for (let category of this.allCategories) {
      if (category != null && category.subCategories != null) {
        for (let subCategory of category.subCategories) {
          subCategory.isSelected = false;
        }
      }
      category.isSelected = false;
    }
    this.categories = this.allCategories;
  }

  filter(query) {
    if (this.allCategories == null)
      this.allCategories = this.categories;

    if (query == null || query == "") {
      return this.menuCategories = this.allCategories;
    }

    this.menuCategories = this.allCategories.filter(a => a.id != "-1").filter(a =>
      a.name.toLowerCase().includes(query.toLowerCase()) ||
      a.subCategories.filter(b => b.name.toLowerCase().includes(query.toLowerCase())).length > 0);
  }

  selectCategory(category) {
    this.resetCategories();
    category.isSelected = true;

    //this.categories = [];
    //if (category.id == "-1")
    //  this.categories = this.allCategories;
    //else
    //  this.categories.push(category);
  }

  selectSubCategory(subCategory) {
    this.resetCategories();
    subCategory.isSelected = true;

    var category = this.allCategories.find(a => a.id == subCategory.categoryId);
    category.isSelected = true;

    this.categories = [];
    this.categories.push(category);
  }

  isCategorySelected(category) {
    // If no categories are filtered, then always return true.
    var anySelected = this.allCategories.filter(c => c.isSelected == true && c.id != "-1").length > 0;
    if (!anySelected) return true;

    var cat = this.allCategories.find(c => c.id == category.id);
    if (cat != null) {
      return cat.isSelected;
    }

    return false;
  }
  isSubCategorySelected(subCategory) {
    // If no sub categories are filtered, then always return true.
    var anySelected = false;
    for (var category of this.allCategories) {
      if (category.subCategories != null && category.subCategories.filter(c => c.isSelected).length > 0)
        anySelected = true;
    }

    if (!anySelected) return true;

    for (var category of this.allCategories) {
      if (category.subCategories == null) continue;
      var subcat = category.subCategories.find(c => c.id == subCategory.id && c.isNumbered == subCategory.isNumbered);
      if (subcat != null) {
        return subcat.isSelected;
      }
    }

    return false;
  }

  hideRow(picklistAsset) {
    if (this.itemStatus == "1" && (picklistAsset.picklistPickedAsset == null || picklistAsset.picklistPickedAsset.asset == null)) {
      return true;
    }

    if (this.itemStatus == "2" && (picklistAsset.picklistPickedAsset != null && picklistAsset.picklistPickedAsset.asset != null)) {
      return true;
    }
    //itemStatus == 1 && picklistAsset.picklistPickedAsset == null && picklistAsset.picklistPickedAsset.asset == null

    return false;
  }

  hideNumberedRow(itemType) {
    if (this.itemStatus == "1" && itemType.selectedQuantity < itemType.quantity) {
      return true;
    }

    if (this.itemStatus == "2" && itemType.selectedQuantity == itemType.quantity) {
      return true;
    }

    return false;
  }


  getGroupedAssetsByWarehouse() {
    var itemTypes = [];
    for (let category of this.groupedPicklistAssets) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes)
          itemTypes.push(itemType);
      }
    }

    return itemTypes.sort((a, b) => a.warehouseLocation.localeCompare(b.warehouseLocation));
  }
}

/*
 * {
 *  categoryId,
 *  categoryName,
 *  subCategories [
 *    {
 *      subCategoryId,
 *      subCategoryName,
 *    }
 *  ]
 * }
 *
 *
 */
