import { Component, OnInit } from '@angular/core';
import { Category } from '../../../models/category';
import { SubCategory } from '../../../models/sub-category';
import { ItemType } from '../../../models/item-type';
import { Page } from '../../../models/paging/page';
import { CategoryService } from '../../../services/category.service';
import { SubCategoryService } from '../../../services/sub-category.service';
import { ItemTypesService } from '../../../services/item-types.service';
import { NonNumberedAssetService } from '../../../services/non-numbered-asset.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterService } from '../../../services/filter.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-non-numbered-assets-stores',
  templateUrl: './non-numbered-assets.component.html',
  styleUrls: ['./non-numbered-assets.component.css']
})
export class NonNumberedAssetsStoresComponent implements OnInit {

  filter: any = {
    categoryId: undefined,
    subCategoryId: undefined,
    itemTypeId: undefined,
    search: "",
  };

  default_filters = JSON.parse(JSON.stringify(this.filter));

  categories: Category[];
  subCategories: SubCategory[];
  subCategoriesList: SubCategory[];
  itemTypes: ItemType[];
  itemTypesList: ItemType[];

  page = new Page();
  rows = Array<any>();

  constructor(private categoryService: CategoryService, private subCategoryService: SubCategoryService,
    private itemTypeService: ItemTypesService, private nonNumberedAssetService: NonNumberedAssetService,
    public router: Router, public route: ActivatedRoute, private filterService: FilterService, private authService: AuthService) {

    this.page.pageNumber = 0;
    this.page.size = 10;

  }

  ngOnInit() {

    this.categoryService.getAll().then((data) => {
      this.categories = data;
      this.subCategoryService.getAll().then((data) => {
        this.subCategoriesList = data;
        this.itemTypeService.getAll().then((data) => {
          this.itemTypesList = data;

          this.route.queryParams.subscribe(params => {

            console.log("Query Params");
            console.log(params);

            //Check if we've come from itemTypes
            const itemTypeId = params['itemTypeId'];
            if (itemTypeId != undefined) {

              console.log("Getting parameter filters");

              const categoryId = params['categoryid'];
              if (categoryId != undefined) {
                this.filter.categoryId = categoryId;
                this.subCategories = this.subCategoriesList.filter(c => {
                  return c.categoryId == categoryId
                });
              }

              const subCategoryId = params['subcategoryid'];
              if (subCategoryId != undefined) {
                this.filter.subCategoryId = subCategoryId;
                this.itemTypes = this.itemTypesList.filter(i => {
                  return i.subCategoryId == subCategoryId
                });
              }

              const itemTypeId = params['itemTypeId'];
              if (itemTypeId != undefined) {
                this.filter.itemTypeId = itemTypeId;
              }

              this.loadTable(this.page);
            }

            //Otherwise use defaults/cached
            else {

              console.log("Getting cached filters");
              var filters = this.filterService.getFilters(this.authService.currentUser.value.id, this.router.url);
              if (filters != null) {
                this.filter = filters;

                if (this.filter.categoryId != undefined) {
                  this.subCategories = this.subCategoriesList.filter(c => {
                    return c.categoryId == this.filter.categoryId
                  });
                }

                if (this.filter.subCategoryId != undefined) {
                  this.itemTypes = this.itemTypesList.filter(i => {
                    return i.subCategoryId == this.filter.subCategoryId
                  });
                }

              }

              this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
              this.loadTable(this.page);
            }
          });
        })
      });
    });

    //    this.setPage({ offset: 0 });
  }


  onCategoryChange(value) {
    this.filter.subCategoryId = undefined;
    if (value == '' || value == null) { value = null; }

    this.subCategories = this.subCategoriesList.filter(c => {
      return value == null || c.categoryId == value;
    });
  }

  onSubCategoryChange(value) {
    this.filter.itemTypeId = undefined;
    console.log(value)
    if (value == '' || value == null) { value = null; }

    this.itemTypes = this.itemTypesList.filter(c => {
      return (value == null || c.subCategoryId == value) && !c.numbered;
    });
  }

  edit(row) {
    this.router.navigate(['administration/nonnumberedassets/manage'], { queryParams: { id: row.id, itemTypeId: row.itemTypeId  } });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
    this.nonNumberedAssetService.getResults(this.page, this.filter.categoryId, this.filter.subCategoryId, this.filter.itemTypeId, this.filter.search, null).then((pagedData) => {
      console.log(pagedData);
      this.page = pagedData.page;
      this.rows = pagedData.data;
    });
  }
}
