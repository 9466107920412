import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { NonNumberedAsset } from '../models/non-numbered-asset';
import { UUID } from 'angular2-uuid';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

@Injectable({
  providedIn: 'root'
})
export class NonNumberedAssetService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public get(id: String): Promise<NonNumberedAsset> {
    return new Promise((resolve, reject) => {
      this.http.get<NonNumberedAsset>('/api/nonnumberedasset/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getByItemType(id: String): Promise<NonNumberedAsset> {
    return new Promise((resolve, reject) => {
      this.http.get<NonNumberedAsset>('/api/nonnumberedasset/getbyitemtype?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getByItemTypeV2(id: String, location_id: String): Promise<NonNumberedAsset> {
    return new Promise((resolve, reject) => {
      this.http.get<NonNumberedAsset>('/api/nonnumberedasset/getbyitemtypev2?id=' + id + '&location_id=' + location_id).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error.error);
      });
    });
  }

  public getResults(page: Page, categoryId: string, subCategoryId: string, itemTypeId: string, search: string, locationId: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'ItemTypeId'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&categoryId=' + categoryId;
      params += '&subCategoryId=' + subCategoryId;
      params += '&itemTypeId=' + itemTypeId;
      params += '&search=' + search;
      params += '&locationId=' + locationId;

      this.http.get<ServerData>('/api/asset/getnonassettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public save(asset: NonNumberedAsset): Promise<any> {
    return new Promise((resolve, reject) => {
      if (asset.isEdit) {
        this.http.put('/api/nonnumberedasset/put', asset).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated non numbered asset');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        asset.id = UUID.UUID();

        this.http.post('/api/nonnumberedasset/post', asset).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added non numbered asset');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }
}
