import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category';

@Component({
  selector: 'app-category-manage',
  templateUrl: './category-manage.component.html',
  styleUrls: ['./category-manage.component.css']
})
export class CategoryManageComponent implements OnInit {
  category: Category;

  constructor(public categoryService: CategoryService, public router: Router, public route: ActivatedRoute) {
    this.category = new Category();
  }

  ngOnInit() {
    // Load our type if passed in
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.categoryService.get(id).then((data) => {
          this.category = data;
          this.category.is_edit = true;
        });
      }
    });
  }

  submit() {
    console.log(this.category);
    this.categoryService.save(this.category).then((data) => {
      this.router.navigate(['administration/categories']);
    });
  }

}
