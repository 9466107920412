import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { Owner } from '../models/owner';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Owner[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Owner[]>('/api/owner/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
