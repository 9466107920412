import { Component, OnInit } from '@angular/core';
import { RegimeGroup } from '../../../../models/regione-group';
import { RegimeGroupService } from '../../../../services/regime-group.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-regime-group-manage',
  templateUrl: './regime-group-manage.component.html',
  styleUrls: ['./regime-group-manage.component.css']
})
export class RegimeGroupManageComponent implements OnInit {
  regimeGroup: RegimeGroup;

  constructor(public regimeGroupService: RegimeGroupService, public router: Router, public route: ActivatedRoute) {
    this.regimeGroup = new RegimeGroup();
  }

  ngOnInit() {
    // Load our type if passed in
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.regimeGroupService.get(id).then((data) => {
          this.regimeGroup = data;
          this.regimeGroup.is_edit = true;
        });
      }
    });
  }

  submit() {
    console.log(this.regimeGroup);
    this.regimeGroupService.save(this.regimeGroup).then((data) => {
      this.router.navigate(['administration/testgroups']);
    });
  }

}
