import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../../services/project.service';
import { DivisionService } from '../../../../services/division.service';
import { Project } from '../../../../models/project';
import { Division } from '../../../../models/division';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-manage',
  templateUrl: './project-manage.component.html',
  styleUrls: ['./project-manage.component.css']
})
export class ProjectManageComponent implements OnInit {
  project: Project;

  divisions: Division[];

  constructor(private projectService: ProjectService, private divisionService: DivisionService, public router: Router, public route: ActivatedRoute) {
    this.project = new Project();
    this.project.isEdit = false;

    this.divisionService.getAll().then((data) => {
      this.divisions = data;
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.projectService.get(id).then((data) => {
          this.project = data;
          this.project.isEdit = true;
          console.log(this.project);
        });
      }
    });
  }

  submit() {
    this.projectService.save(this.project).then((data) => {
      this.router.navigate(['administration/projects']);
    });
  }

}
