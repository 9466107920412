import { Component, OnInit } from '@angular/core';
import { AssetSelectorService } from 'src/app/services/asset-selector.service';

@Component({
  selector: 'app-rote',
  templateUrl: './rote.component.html',
  styleUrls: ['./rote.component.css']
})
export class RoteComponent implements OnInit {

  roteType: number = 0;

  constructor(private assetSelectorService: AssetSelectorService) {
  }

  ngOnInit() {

    this.assetSelectorService.nullifySelectedAssets();

  }

}
