import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Carrier } from 'src/app/models/carrier';
import { CarrierService } from 'src/app/services/carrier.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-carrier-manage-detail',
  templateUrl: './carrier-manage-detail.component.html',
  styleUrls: ['./carrier-manage-detail.component.css']
})
export class CarrierManageDetailComponent implements OnInit {
  @Output() added: EventEmitter<Carrier> = new EventEmitter<Carrier>();
  @Input() hideExpired: boolean = false;
  carrier: Carrier;

  constructor(public carrierService: CarrierService, public router: Router, public route: ActivatedRoute) {
    this.carrier = new Carrier();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let id = params['id'];
      if (id != undefined) {
        this.carrierService.get(id).then((data) => {
          this.carrier = data;
          this.carrier.isEdit = true;
        })
      }
    });
  }

  submit() {
    this.added.emit(this.carrier);
  }

}
