// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    reportableUrl: 'https://reportable.corerfid.net/api/can/'
    //reportableUrl: 'http://reportable.dev7.corerfid.com/api/can/'
};

