import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Picklist } from 'src/app/models/picklist';
import { Category } from 'src/app/models/category';
import { Page } from 'src/app/models/paging/page';
import { CategoryService } from 'src/app/services/category.service';
import { PicklistService } from 'src/app/services/picklist.service';
import { SnotifyService } from 'ng-snotify';
import { PickListAssetViewModel } from '../../../../../models/picklist-asset-view-model';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() picklist: Picklist;
  searchFilterName: string;
  searchFilterDesc: string;

  //@ViewChild(PicklistNewComponent) new: PicklistNewComponent;
  //@ViewChild(PicklistExistingComponent) existing: PicklistExistingComponent;
  //@ViewChild(PicklistTemplateComponent) template: PicklistTemplateComponent;

  type: number = 0;

  constructor() {
    
  }

  ngOnInit() {
    //console.log("Picklist: ")
    //console.log(this.picklist);
  }

  onNewCompletion(assets: PickListAssetViewModel[]) {
    this.completed.emit(assets);
  }

  onExistingCompletion(assets: PickListAssetViewModel[]) {
    this.completed.emit(assets);
  }

  onTemplateCompletion(assets: PickListAssetViewModel) {
    this.completed.emit(assets);
  }
}
