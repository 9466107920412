import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { Template } from '../models/template';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Template[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Template[]>("/api/template/getall").subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public expire(template: Template): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put<Template>('/api/template/putexpire', template).subscribe(data => {
        resolve(true);
        this.snotifyService.success('Successfully removed template');
      }, error => {
        console.log(error);
        reject(false);
      });
    });
  }

  public save(template: Template): Promise<Template> {
    return new Promise((resolve, reject) => {
      if (template.isEdit) {
        this.http.put<Template>('/api/template/put', template).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully updated template');
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        template.id = UUID.UUID();

        console.log(template);

        this.http.post<Template>('/api/template/post', template).subscribe(data => {
          resolve(data);
          this.snotifyService.success('Successfully added template');
        }, error => {
          console.log(error);
          reject();
        });
      }
    });
  }
}
