import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, SimpleChange } from '@angular/core';
import { ItemType } from '../../../../../../../models/item-type';
import { Category } from '../../../../../../../models/category';
import { PicklistService } from '../../../../../../../services/picklist.service';

@Component({
  selector: 'app-picklist-item-types',
  templateUrl: './picklist-item-types.component.html',
  styleUrls: ['./picklist-item-types.component.css']
})
export class PicklistItemTypesComponent implements OnInit, OnChanges {
  @ViewChild('myTable') table: any;
  @Input() categories: Category[];
  @Input() itemTypes: ItemType[];
  @Input() searchFilterName: string;
  @Input() searchFilterDesc: string;
  expanded: any = {};

  rows = [];
  //showSelectedOnly: boolean = false;


  constructor(private picklistService: PicklistService) {
    this.picklistService.showSelectedOnly.subscribe((selected) => {
      this.toggleSelectedOnly(selected);
    });
  }


  ngOnInit() {
  
  }

  toggleSelectedOnly(selected) {
    var selectedCategory = null;
    var selectedSubCategory = null;

    if (selected == null || selected == undefined) {
      return;
    }

    if (this.categories == null || this.categories  == undefined) {
      return;
    }

    if (this.itemTypes == null || this.itemTypes == undefined) {
      return;
    }
    console.log(" toggled - selectedPicklist");
    console.log(this.picklistService.selectedPicklist);
    

    var itemTypes = [];
    
    selectedCategory = this.categories.find(a => a.isSelected == true);
    if (selectedCategory == null) {
      for (let category of this.categories) {
        if (category.id == "-1")
          continue;

        if (selectedSubCategory != null)
          continue;

        selectedSubCategory = category.subCategories.find(a => a.isSelected == true);
      }
    }
    
    console.log("categories");
    console.log(selectedCategory);
    if (selectedCategory != null) {
      for (let subCategory of selectedCategory.subCategories) {
        for (let itemType of subCategory.itemTypes) {


          if (this.picklistService.selectedPicklist.picklistAssets != null && this.picklistService.selectedPicklist.picklistAssets.length > 0) {
            for (let asset of this.picklistService.selectedPicklist.picklistAssets) {
            
              if (itemType.id == asset.itemTypeId) {
                console.log(asset);
                itemType.quantity += asset.quantity;
                itemType.notes = asset.notes
              }
            }
          }
          
          if (itemType.quantity > 0 || !selected)
            if (itemTypes.filter(a => a.id == itemType.id).length == 0) {
              itemTypes.push(itemType);
            }
            else {
              console.log("ELSE");
              console.log(itemType);
              //for (let asset of this.picklistService.selectedPicklist.picklistAssets) {
              //  if (asset.itemTypeId == itemType.itemTypeId && asset.id != itemType.id && asset.replacedByItemTypeId != null) {

              //  }
              //}

              //for (let i = 0; i <= (itemTypes.length); i++) {
              //  var type = itemTypes[i];
              //  if (type.id == itemType.id) {
              //    type.quantity = (type.quantity + itemType.quantity);
              //    break;
              //  }
              //}
            }
            //else itemTypes.filter(a => a.id == itemType.id).quantity += itemType.quantity;
            //else if (itemTypes.filter(a => a.id == itemType.id).length > 0) {

            //  for (let i = 0; i <= (itemTypes.length); i++) {
            //    if (itemTypes[i].id == itemType.id) {
            //      itemTypes[i].quantity = itemTypes[i].quantity + itemType.quantity;
            //      break;
            //    }
            //  }
            //}
              
          
        }
      }
    }
    else if (selectedSubCategory) {
      for (let itemType of selectedSubCategory.itemTypes) {

        if (this.picklistService.selectedPicklist.picklistAssets != null && this.picklistService.selectedPicklist.picklistAssets.length > 0) {
          for (let asset of this.picklistService.selectedPicklist.picklistAssets) {
            //if (asset.replacedByItemTypeId != null && asset.replacedByItemTypeId != "") {
            //  for (let a of this.itemTypes) {
            //    if (a.id == asset.itemTypeId)
            //      itemType.quantity = itemType.quantity +asset.quantity;
            //  }

            //}else if (itemType.id == asset.itemTypeId) {
            //  //this is selected mofo
            //  itemType.quantity = asset.quantity;
            //  /*              itemTypes.push(itemType);*/
            //}
            if (itemType.id == asset.itemTypeId) {
              //this is selected mofo
              itemType.quantity = itemType.quantity +asset.quantity;


            }
          }
        }
        console.log(itemType.quantity);

        if ((itemType.quantity > 0) || !selected)

          if (itemTypes.filter(a => a.id == itemType.id).length == 0) {
            console.log(itemType);
            itemTypes.push(itemType);
            console.log(itemTypes);
          } else {
            console.log("ELSE");
            console.log(itemType);

            for (let i = 0; i <= (itemTypes.length); i++) {
              var type = itemTypes[i];
              if (type.id == itemType.id) {
                type.quantity = (type.quantity + itemType.quantity);
                break;
              }
            }
          }
          //else {
          //  var x = itemTypes.filter(a => a.id == itemType.id).posi;
          //  x.quantity += itemType.quantity
          //} 

          //  for (let i = 0; i <= (itemTypes.length); i++) {
          //    if (itemTypes[i].id == itemType.id) {
          //      itemTypes[i].quantity = itemTypes[i].quantity + itemType.quantity;
          //      break;
          //    }
          //  }
          //}

            
      }
    }

    //if (selectedCategory != null) {
    //  for (let subCategory of selectedCategory.subCategories) {
    //    for (let itemType of subCategory.itemTypes) {

    //      if (this.picklistService.selectedPicklist.picklistAssets != null && this.picklistService.selectedPicklist.picklistAssets.length > 0) {
    //        for (let asset of this.picklistService.selectedPicklist.picklistAssets) {
    //          if (itemType.id == asset.itemTypeId) {
    //            itemType.quantity = asset.quantity;
    //          }
    //        }
    //      }

    //    }
    //  }
    //}
    ///

    this.rows = itemTypes.sort((a, b) => a.name.localeCompare(b.name));
    this.picklistService.selectedPicklist.picklistAssets = null;
  }

  checkIfItemIsAlreadyInList(asset) {
    for (let a of this.itemTypes) {
      if (a.id == asset.itemTypeId)
        return true;
    }
    return false;
  }

  toggleExpandRow(row, expanded) {
    this.table.rowDetail.toggleExpandRow(row);
    if (expanded == true) {
      this.rows = [...this.rows];

    }
  }

  itemChanged(row, qty, notes) {
    if (qty < 0) {
      qty = 0;
      row.quantity = '';
    }


    // Check if any linked items share this ID and change
    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes) {
          for (let linkedItemType of itemType.linkedItemTypes) {
            if (linkedItemType.itemTypeId == row.id) {

              linkedItemType.itemType.quantity = qty;
              linkedItemType.itemType.notes = notes;
            }
          }
        }
      }
    }

    if (row.linkedItemTypes == null || row.linkedItemTypes.length == 0)
      return;

    if (row.expanded == undefined)
      row.expanded = false;


    if (qty > 0 && !row.expanded) {
      row.expanded = true;
      this.table.rowDetail.toggleExpandRow(row);
    }
    else if ((qty == 0 || qty == null) && row.expanded) {
      row.expanded = false;

      this.rows = this.itemTypes.sort((a, b) => a.name.localeCompare(b.name));
      

      this.table.rowDetail.toggleExpandRow(row);
    }

    console.log(row);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemTypes) {
      this.rows = this.itemTypes != undefined ? this.itemTypes.sort((a, b) => a.name.trim().localeCompare(b.name.trim())) : undefined;
    }

    if (changes.searchFilterName != null && changes.searchFilterName.currentValue != null) {
      const val = changes.searchFilterName.currentValue.toLowerCase();
      const temp = this.itemTypes.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });

      this.rows = temp.sort((a, b) => a.name.localeCompare(b.name));
      this.table.offset = 0;
    }

    if (changes.searchFilterDesc != null && changes.searchFilterDesc.currentValue != null) {
      const val = changes.searchFilterDesc.currentValue.toLowerCase();
      const temp = this.itemTypes.filter(function (d) {
        if (d.additionalName != null)
          return d.additionalName.toLowerCase().indexOf(val) !== -1 || !val;
      });

      this.rows = temp.sort((a, b) => a.name.localeCompare(b.name));
      this.table.offset = 0;
    }

  }

  linkedItemChanged(row, qty, notes) {
    if (qty < 0) {
      qty = 0;
      row.quantity = '';
    }


    for (let category of this.categories) {
      for (let subCategory of category.subCategories) {
        for (let itemType of subCategory.itemTypes) {
          if (itemType.id == row.itemTypeId) {
            itemType.quantity = qty;
            itemType.notes = notes;
          }

          for (let linkedItemType of itemType.linkedItemTypes) {
            if (linkedItemType.itemTypeId == row.itemTypeId) {

              linkedItemType.itemType.quantity = qty;
              linkedItemType.itemType.notes = notes;
            }
          }
        }
      }
    }
  }
}
