import { TemplateAddAssetViewModel } from "./template-add-asset-view-model";
import { PickListAssetViewModel } from "./picklist-asset-view-model";

export class Template {
  id: string;
  name: string;
  templateAssets: TemplateAddAssetViewModel[];
  templateNonNumberedAssets: TemplateAddAssetViewModel[];
  isEdit: boolean = false;
  expired: boolean;

  allPicklistAssets: PickListAssetViewModel[];
}
