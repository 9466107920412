import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { UUID } from 'angular2-uuid';
import { SnotifyService } from 'ng-snotify';
import { RegimeGroup } from '../models/regione-group';
import { TestType } from '../models/test-type';

@Injectable({
  providedIn: 'root'
})
export class RegimeGroupService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }


  public get(id: String): Promise<RegimeGroup> {
    return new Promise((resolve, reject) => {
      this.http.get<RegimeGroup>('/api/regimegroup/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAll(): Promise<RegimeGroup[]> {
    return new Promise((resolve, reject) => {
      this.http.get<RegimeGroup[]>('/api/regimegroup/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(group: RegimeGroup): Promise<RegimeGroup> {
    return new Promise((resolve, reject) => {
      if (group.is_edit) {
        this.http.put<RegimeGroup>('/api/regimegroup/put', group).subscribe(data => {
          this.snotifyService.success('Successfully updated regime group');
          resolve(data);
        }, error => {
          console.log(error);
          reject();
        });
      } else {
        group.id = UUID.UUID();
        this.http.post<RegimeGroup>('/api/regimegroup/post', group).subscribe(data => {
          this.snotifyService.success('Successfully added regime group');
          resolve(data);
        }, error => {
          reject(error);
        });
      }
    });
  }

  public getResults(page: Page, name: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&name=' + name;

      this.http.get<ServerData>('/api/regimegroup/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          const group = new RegimeGroup();
          group.init(jsonObj.id, jsonObj.name, jsonObj.expired);
          pagedData.data.push(group);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
