import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import { Asset } from '../models/asset';
import { TestType } from '../models/test-type';
import { Router } from '@angular/router';
import { Inspection } from '../models/inspection';
import { SnotifyService } from 'ng-snotify';
import { Project } from '../models/project';

@Injectable()
export class InspectionService {
  assetsToInspect: Asset[];
  testType: TestType;
  projectId: string;
  isPopup: boolean;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private snotifyService: SnotifyService) {
    this.assetsToInspect = JSON.parse(localStorage.getItem("assetsToInspect"));
    this.testType = JSON.parse(localStorage.getItem("testType"));
    this.projectId = localStorage.getItem("projectId");
  }



  public get(id: number): Promise<Inspection> {
    return new Promise((resolve, reject) => {
      this.http.get<Inspection>('/api/inspection/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public startInspection(assets: Asset[], testType: TestType, projectId: string, isPopup: boolean) {
    this.assetsToInspect = assets;
    this.testType = testType;
    this.projectId = projectId;
    this.isPopup = isPopup;

    localStorage.setItem("assetsToInspect", JSON.stringify(this.assetsToInspect));
    localStorage.setItem("testType", JSON.stringify(this.testType));
    localStorage.setItem("projectId", projectId);

    if (!isPopup) {
      this.router.navigate(['inspections/rote/inspect']);
    }
  }

  public delete(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('/api/inspection/delete?id=' + id).subscribe(data => {
        this.snotifyService.success('Successfully deleted inspection');
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getResults(page: Page, startDate: string, endDate: string, projectId: string, canNumber: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'InspectedAt'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&projectId=' + projectId;
      params += '&canNumber=' + canNumber;
      params += '&startDate=' + moment(startDate).format('YYYY-MM-DD');
      params += '&endDate=' + moment(endDate).format('YYYY-MM-DD');;

      this.http.get<ServerData>('/api/inspection/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.totalElements;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }


  public getExcelResults(page: Page, startDate: string, endDate: string, projectId: string, canNumber: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'InspectedAt'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&projectId=' + projectId;
      params += '&canNumber=' + canNumber;
      params += '&startDate=' + moment(startDate).format('YYYY-MM-DD');
      params += '&endDate=' + moment(endDate).format('YYYY-MM-DD');;

      this.http.get<any>('/api/inspection/getExceltable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);

      }, error => {
        reject();
      });
    });
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }


  public post(inspection: Inspection): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post('/api/inspection/post', inspection).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public putDates(inspection: Inspection): Promise<any> {
    console.log(inspection);
    return new Promise((resolve, reject) => {
      this.http.put('/api/inspection/putdates', inspection).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


}
